import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";

import * as yup from "yup";
import MDTypography from "components/MDTypography";
import Avatar from "components/UI/Table/avatar";
import MDSnackbar from "components/MDSnackbar";
import UpdateRecord from "helpers/compare";
import Page from "components/UI/Page";
import { Grid, Icon } from "@mui/material";
import DataModal from "components/UI/DataModal";
import PagesCount from "helpers/pages-count";

import {
  addComplexDataAction,
  fetchDataAction,
  deleteDataAction,
  reset,
} from "../../store/features/coreSlice";
import dataVal from "./dataVal.json";
import CelebrityFavourities from "./CelebrityFavorites";
import CelebrityProducts from "./CelebrityProducts";

const Celebrities = () => {
  const dispatch = useDispatch();

  const { celebritiesMeta, celebrities, resStatus, limit } = useSelector((state) => state.core);

  const [newArr, setNewArr] = useState([]);
  const [data, setData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [pageOptions, setPageOption] = useState([]);
  const [pageNum, setPageNum] = useState(1);

  const [id, setId] = useState(0);
  const [open, setOpen] = useState(false);
  const [initialValues, setInitialValues] = useState({
    ...dataVal.initialValues,
    status: "active",
    role: 3,
  });
  const [successSB, setSuccessSB] = useState(false);
  const [celebrityId, setCelebrityId] = useState(null);

  const closeSuccessSB = () => setSuccessSB(false);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "first_name",
        id: "first_name",
        label: "first_name",
        size: 6,
        title: "Arabic Name",
      },
      {
        type: "TextField",
        name: "last_name",
        id: "last_name",
        label: "last_name",
        size: 6,
        title: "English Name",
      },
      {
        type: "TextField",
        name: "sequence",
        id: "sequence",
        label: "sequence",
        size: 4,
        title: "Sequence",
      },
      {
        type: "TextField",
        name: "email",
        id: "email",
        label: "email",
        size: 4,
        title: "Email",
      },
      {
        type: "Img",
        name: "avatar",
        id: "avatar",
        label: "avatar",
        size: 4,
        title: "Image",
      },
    ]);
  };

  const onUpdateHandler = (name, values) => {
    setCelebrityId(values.id);
    setInitialValues({
      id: values.id,
      last_name: values.last_name,
      first_name: values.first_name,
      email: values.email,
      avatar: values.avatar?.data.full_url,
      sequence: values.sequence,
    });
  };

  const resetForm = () => {
    setInitialValues({
      ...dataVal.initialValues,
      status: "active",
      role: 3,
    });
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const getData = () => {
    // get celebrity data
    const url = `users?fields=status,id,first_name,last_name,sequence,avatar.data.full_url,avatar.data.full_url,email&filter[role]=3&meta=*&limit=${limit}&page=${pageNum}`;
    dispatch(
      fetchDataAction({
        url,
        varName: "celebrities",
      })
    );
  };

  const setOpenModal = (num) => {
    setOpen(!open);
    setId(num);
  };

  const changeStatus = (status, category) => {
    const req = {
      id: category.id,
      origin: {
        data: { status },
        table: "directus_users",
      },
      getInfo: {
        url: `users?fields=status,id,first_name,last_name,sequence,avatar.data.full_url,avatar.data.full_url,email&filter[role]=3&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "celebrities",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+celebritiesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    celebrities.forEach((celebrity) => {
      item = {};
      keys = Object.keys(celebrity);

      item.title = (
        <Avatar
          dir="ltr"
          image={celebrity.avatar?.data.full_url}
          name={celebrity.first_name}
          email={celebrity.email}
        />
      );

      keys.forEach((element) => {
        item[element] = celebrity[element];
      });

      item.action = (
        <>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onUpdateHandler("celebrities", celebrity)}
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#e9423f" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onDeleteHandler("celebrities", celebrity)}
          >
            <Icon fontSize="small">delete</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "red" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => {
              setCelebrityId(celebrity.id);
              setOpenModal(1);
            }}
          >
            <Icon fontSize="small">favorite</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "green" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => {
              setCelebrityId(celebrity.id);
              setOpenModal(2);
            }}
          >
            <Icon fontSize="small">book</Icon>
          </MDTypography>
          {celebrity.status === "draft" ? (
            <MDTypography
              className="button-group"
              style={{ padding: 20, color: "#e9423f" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => changeStatus("active", celebrity)}
            >
              <Icon fontSize="small">public_off</Icon>
            </MDTypography>
          ) : (
            <MDTypography
              style={{ padding: 20, color: "green" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => changeStatus("draft", celebrity)}
            >
              <Icon fontSize="small">public</Icon>
            </MDTypography>
          )}
        </>
      );
      Arr.push(item);
    });
    setNewArr(Arr);
  };

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      const url = `users?fields=status,id,first_name,last_name,sequence,avatar.data.full_url,avatar.data.full_url,email&filter[role]=3&meta=*&limit=${limit}&page=${num}`;

      dispatch(
        fetchDataAction({
          url,
          varName: "celebrities",
        })
      );
    }
  };

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      imgFieldName: "avatar",
      id: values.id,
      file: typeof values.avatar === "object" ? values.avatar : null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "directus_users",
      },
      getInfo: {
        url: `users?fields=status,id,first_name,last_name,sequence,avatar.data.full_url,avatar.data.full_url,email&filter[role]=3&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "celebrities",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "users",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `users?fields=status,id,first_name,last_name,sequence,avatar.data.full_url,avatar.data.full_url,email&filter[role]=3&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "celebrities",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    getData();
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    if (celebrities && celebrities.length !== 0) {
      prepareTableRows();
    }
  }, [celebrities]);

  useEffect(() => {
    orgData();
  }, []);

  const doer = () => {
    let i = null;
    if (id === 1) {
      i = <CelebrityFavourities celebrityId={celebrityId} />;
    } else if (id === 2) {
      i = <CelebrityProducts celebrityId={celebrityId} />;
    }
    return i;
  };

  if (data.length === 0 || newArr === [] || !celebritiesMeta) {
    return null;
  }

  return (
    <Page>
      <Grid item xs={12}>
        <DataModal
          handleClose={() => {
            dispatch(reset(null));
            setOpen(!open);
            setId(0);
          }}
          open={open}
        >
          {doer()}
        </DataModal>
        <Main
          layout="simple"
          allRowsCount={+celebritiesMeta.filter_count}
          pageIndex={pageNum - 1}
          count={pageOptions}
          setPageNum={setPageNumber}
          setOpenDeleteModal={setOpenDeleteModal}
          openDeleteModal={openDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
          name="celebrities"
          setPayload={setPayload}
          data={data}
          initialValues={initialValues}
          title="Add Celebrities"
          columns={dataVal.columns}
          rows={newArr}
          onUpdateHandler={onUpdateHandler}
          onDeleteHandler={onDeleteHandler}
          entriesPerPage
          yupObj={{
            first_name: yup.string("Enter your Title").required("Title is required").nullable(),
            email: yup.string("required").email("wrong email").required("required").nullable(),
            avatar: yup.string("Enter your avatar").required("avatar is required").nullable(),
          }}
        />
        {renderSuccessSB}
      </Grid>
    </Page>
  );
};

export default Celebrities;
