import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import MDTypography from "components/MDTypography";
import Avatar from "components/UI/Table/avatar";
import UpdateRecord from "helpers/compare";
import { Icon } from "@mui/material";
import PropTypes from "prop-types";
import PagesCount from "helpers/pages-count";

import {
  addComplexDataAction,
  deleteDataAction,
  fetchDataAction,
  reset,
  // addDataAction,
  // deleteDataAction,
} from "../../../store/features/coreSlice";
import dataVal from "./ProductInfo/dataVal.json";

const ProductAttribute = ({ productId }) => {
  const dispatch = useDispatch();
  const {
    currencies,
    attributeValue,
    productAttributeValue,
    productAttributeValueMeta,
    resStatus,
    limit,
  } = useSelector((state) => state.core);
  const [newArr, setNewArr] = useState([]);
  const [arrData, setData] = useState([]);
  const [arrOpenDeleteModal, setArrOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [attributesValuesData, setAttributesValuesData] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [arrInitialValues, setInitialValues] = useState({
    ...dataVal.productAttribute.initialValues,
    parent_id: productId,
  });

  const setPayload = (values) => {
    const payloadData = {};
    let attributeArr = [];
    const lastData = values.id ? UpdateRecord(values, arrInitialValues) : values;
    if (arrInitialValues.weight_meg?.value !== values.weight_meg?.value)
      payloadData.weight_meg = values.weight_meg?.value;
    if (arrInitialValues.height_mes?.value !== values.height_mes?.value)
      payloadData.height_mes = values.height_mes?.value;

    if (values.id === null) {
      attributeArr = values.attributeValue.length > 0 ? { add: values.attributeValue } : null;
    } else {
      attributeArr = lastData?.attributeValue || null;
    }

    delete lastData.attributeValue;
    delete lastData.weight_meg;
    delete lastData.height_mes;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      id: values.id,
      file: typeof values.image1 === "object" ? values.image1 : null,
      origin: {
        data:
          Object.keys(payloadData).length === 0 ? null : { ...payloadData, parent_id: productId },
        table: "products",
      },
      related: [
        {
          table: "product_attribute_value",
          data: attributeArr,
          column: "attribute_value_id",
          origin_column: "product_id",
        },
      ],
      getInfo: {
        url: `items/products?fields=id,image,image.data.full_url,
        discounted_price,
        actual_price,
        quantity,
        stock_quantity,
        dimensions,
        shipping_weight,
        parent_id,
        weight_meg,
        currency,attributes.id,attributes.attribute_value_id.english_title,attributes.attribute_value_id.id,attributes.attribute_value_id.attribute_id.english_title,height,height_mes&filter[parent_id]=${productId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "productAttributeValue",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const setArrFieldSelectedValue = (name, values, isUpdate = false, updateCode) => {
    console.log(name, values, isUpdate, updateCode);
  };

  const onArrUpdateHandler = (name, values) => {
    const attributesValueData = [];
    if (values.attributes) {
      values.attributes.forEach((element) => {
        attributesValuesData
          .filter((v) => v.id === element.attribute_value_id.id)
          .forEach((item) => {
            attributesValueData.push(item);
          });
      });
    }

    setInitialValues({
      id: values.id,
      discounted_price: values.discounted_price,
      actual_price: values.actual_price,
      quantity: values.quantity,
      stock_quantity: values.stock_quantity,
      image1: values.image?.data.full_url,
      dimensions: values.dimensions,
      height: values.height,
      shipping_weight: values.shipping_weight,
      weight_meg: dataVal.weightMeg.filter((meg) => meg.value === values.weight_meg)[0],
      height_mes: dataVal.heightMes.filter((meg) => meg.value === values.height_mes)[0],
      currency: currencies.filter((currency) => currency.id === values.currency?.id)[0],
      attributeValue: attributesValueData,
    });
  };

  const onArrDeleteHandler = (name, values) => {
    setArrOpenDeleteModal(true);
    setDeleteItemId(values);
  };

  useEffect(() => {
    if (attributeValue !== undefined) {
      const attributesValuesArr = [];
      attributeValue.forEach((element) => {
        const attributeValueObj = {};
        attributeValueObj.english_title = `${element.english_title} -> ${element.attribute_id.english_title}`;
        attributeValueObj.id = element.id;
        attributesValuesArr.push(attributeValueObj);
      });
      setAttributesValuesData(attributesValuesArr);
    }
  }, [attributeValue]);

  const orgData = () => {
    setData([
      // type === simple ?actual_price   discounted_price   currency    quantity    stock_quantity      dimensions   shipping_weight      weight_meg     height      height_mes   :
      {
        type: "MSelect",
        optionTitle: "english_title",
        name: "attributeValue",
        id: "attributeValue",
        label: "attributeValue",
        data: attributesValuesData,
        size: 6,
        title: "Attribute Value",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "currency",
        id: "currency",
        label: "currency",
        data: currencies,
        size: 6,
        title: "Currency",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "weight_meg",
        id: "weight_meg",
        label: "weight_meg",
        data: dataVal.weightMeg,
        size: 6,
        title: "Weight Meg",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "height_mes",
        id: "height_mes",
        label: "height_mes",
        data: dataVal.heightMes,
        size: 6,
        title: "Height Mes",
      },
      {
        type: "TextField",
        name: "actual_price",
        id: "actual_price",
        label: "actual_price",
        size: 4,
        title: "Actual Price",
      },
      {
        type: "TextField",
        name: "discounted_price",
        id: "discounted_price",
        label: "discounted_price",
        size: 4,
        title: "Discounted Price",
      },
      {
        type: "TextField",
        name: "quantity",
        id: "quantity",
        label: "quantity",
        size: 4,
        title: "Quantity",
      },
      {
        type: "TextField",
        name: "stock_quantity",
        id: "stock_quantity",
        label: "stock_quantity",
        size: 4,
        title: "Stock Quantity",
      },
      {
        type: "TextField",
        name: "dimensions",
        id: "dimensions",
        label: "dimensions",
        size: 4,
        title: "Dimensions",
      },
      {
        type: "TextField",
        name: "shipping_weight",
        id: "shipping_weight",
        label: "shipping_weight",
        size: 4,
        title: "Shipping Weight",
      },
      {
        type: "TextField",
        name: "height",
        id: "height",
        label: "height",
        size: 4,
        title: "Shipping Height",
      },

      {
        type: "Img",
        name: "image1",
        id: "image1",
        label: "image1",
        size: 4,
        title: "Image",
      },
    ]);
  };

  useEffect(() => {
    if (attributesValuesData.length > 0 && currencies !== undefined) {
      orgData();
    }
  }, [currencies, attributesValuesData]);

  useEffect(() => {
    if (productAttributeValue) {
      let item = {};
      const Arr = [];
      let keys = [];
      const d = [];
      for (let i = 0; i < PagesCount(+productAttributeValueMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      if (productAttributeValue && productAttributeValue.length !== 0) {
        productAttributeValue.forEach((product) => {
          item = {};
          keys = Object.keys(product);

          keys.forEach((element) => {
            item[element] = product[element];
          });
          item.title = <Avatar dir="ltr" image={product.image?.data.full_url} name="" email="" />;

          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onArrUpdateHandler("products", product)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onArrDeleteHandler("products", product)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            </>
          );
          Arr.push(item);
        });
        setNewArr(Arr);
      } else {
        setNewArr([]);
      }
    }
  }, [productAttributeValue]);

  useEffect(() => {
    dispatch(
      fetchDataAction({
        url: `items/products?fields=id,image.*,image.data.full_url,
        discounted_price,
        actual_price,
        quantity,
        stock_quantity,
        dimensions,
        shipping_weight,
        parent_id,
        weight_meg,
        currency,attributes.id,attributes.attribute_value_id.english_title,attributes.attribute_value_id.id,attributes.attribute_value_id.attribute_id.english_title,height,height_mes&filter[parent_id]=${productId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "productAttributeValue",
      })
    );
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      const url = `items/products?fields=id,image,image.data.full_url,
        discounted_price,
        actual_price,
        quantity,
        stock_quantity,
        dimensions,
        shipping_weight,
        parent_id,
        weight_meg,
        currency,attributes.id,attributes.attribute_value_id.english_title,attributes.attribute_value_id.id,attributes.attribute_value_id.attribute_id.english_title,height,height_mes&filter[parent_id]=${productId}&meta=*&limit=${limit}&page=${num}`;

      dispatch(
        fetchDataAction({
          url,
          varName: "productAttributeValue",
        })
      );
    }
  };

  const handleArrConfirmDelete = async () => {
    const arra = [];
    deleteItemId.attributes.forEach((element) => {
      arra.push({
        url: "items/product_attribute_value",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });

    const payload = {
      url: "items/products",
      data: {
        id: deleteItemId.id,
        status: "deleted",
      },
      related: arra,
      getInfo: {
        url: `items/products?fields=id,image,image.data.full_url,
        discounted_price,
        actual_price,
        quantity,
        stock_quantity,
        dimensions,
        shipping_weight,
        parent_id,
        weight_meg,
        currency,attributes.id,attributes.attribute_value_id.english_title,attributes.attribute_value_id.id,attributes.attribute_value_id.attribute_id.english_title,height,height_mes&filter[parent_id]=${productId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "productAttributeValue",
      },
    };
    dispatch(deleteDataAction(payload));
    setArrOpenDeleteModal(false);
  };

  const resetForm = () => {
    setInitialValues(dataVal.productAttribute.initialValues);
    orgData();
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      resetForm();
    }
  }, [resStatus]);

  if (arrData.length === 0 || newArr === [] || !productAttributeValueMeta) {
    return null;
  }

  return (
    <Main
      setPageNum={setPageNumber}
      setOpenDeleteModal={setArrOpenDeleteModal}
      openDeleteModal={arrOpenDeleteModal}
      handleConfirmDelete={handleArrConfirmDelete}
      name="productAttributeValue"
      setPayload={setPayload}
      data={arrData}
      initialValues={arrInitialValues}
      title="Add attributeValue"
      columns={dataVal.arrColumns}
      rows={newArr}
      onUpdateHandler={onArrUpdateHandler}
      onDeleteHandler={onArrDeleteHandler}
      setFieldSelectedValue={setArrFieldSelectedValue}
      yupObj={null}
      entriesPerPage={false}
      pageIndex={pageNum - 1}
      count={pageOptions}
    />
  );
};
ProductAttribute.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default ProductAttribute;
