import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Icon, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  fetchDataAction,
  deleteDataAction,
  setAlert,
  addComplexDataAction,
  importActionPostMethod,
} from "store/features/coreSlice";
// import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
// import MDProgress from "components/MDProgress";
import moment from "moment";
import * as xlsx from "xlsx/xlsx.mjs";

import PageForm from "./components/PageForm";
import FilterForm from "./components/FilterForm";

const Buying = () => {
  const baseUrl = `items/buying?fields=*,book_id.id,book_id.arabic_title,book_id.publisher.id,book_id.publisher.arabic_title,stores.gifts.count,stores.distributions.count,stores.distributions.returns.count,stores.distributions.inventories.id,stores.count,stores.place_id.title,accounts.id`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { buyingsMeta, buyings, limit, simbleLayout } = useSelector((state) => state.core);
  const [url, setUrl] = useState(baseUrl);
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);
  const [columns, setColumns] = useState([]);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const setOpenModal = (container) => {
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(<PageForm updateHandlerVal={null} pageNum={number} url={url} />);

  const onDeleteHandler = (name, values) => {
    if (values.stores.length === 0 && values.accounts.length === 0) {
      setOpenDeleteModal(true);
      setDeleteItemId(values.id);
    } else if (values.stores.length > 0) {
      dispatch(
        setAlert({
          status: "error",
          content: t("haveStores"),
        })
      );
    } else {
      dispatch(
        setAlert({
          status: "error",
          content: t("hasAccounts"),
        })
      );
    }
  };

  // import xlsx file
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (f) => {
        const dataI = f.target.result;
        const workbook = xlsx.read(dataI, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        console.log(json);
        dispatch(
          importActionPostMethod({
            url: `custom/importBuyings`,
            varName: "importBuyings",
            data: json,
          })
        );
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const onGoToAccountsHandler = (name, values) => {
    const req = {
      id: values.id,
      file: null,
      origin: {
        data: {
          to_account: true,
          settlement_date: moment().format("YYYY-MM-DD"),
        },
        table: "buying",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "buyings",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    let count;
    const d = [];
    for (let i = 0; i < PagesCount(+buyingsMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    let columnsArr = [
      {
        Header: "id",
        accessor: "id",
        width: "10%",
        align: "center",
      },
      {
        Header: "bookTitle",
        accessor: "title",
        width: "10%",
        align: "center",
      },
      {
        Header: "buyingDate",
        accessor: "buying_date",
        width: "10%",
        align: "center",
        sort: true,
      },
      {
        Header: "count",
        accessor: "count",
        width: "8%",
        align: "center",
      },
      {
        Header: "price",
        accessor: "price",
        width: "8%",
        align: "center",
      },
      {
        Header: "settlementDate",
        accessor: "settlement_date",
        width: "10%",
        align: "center",
        sort: true,
      },
    ];

    let AccountCount;
    if (buyings && buyings.length !== 0) {
      buyings.forEach((buying) => {
        AccountCount = 0;
        item = {};
        keys = Object.keys(buying);
        keys.forEach((element) => {
          item[element] = buying[element];
        });

        buying.accounts.forEach((element) => {
          AccountCount += element.count;
        });

        if (AccountCount > item.count) {
          item.color = "#eea4bd";
        }
        item.price = `${buying.cost} ${buying.is_dolar ? "$" : ""}`;
        let allCountOut = 0;
        let allCountIn = 0;
        buying.stores.forEach((element) => {
          count = 0;
          element.distributions.forEach((distribution) => {
            count += distribution.count;
            distribution.returns.forEach((returnItem) => {
              count -= returnItem.count;
            });
          });
          element.gifts?.forEach((gift) => {
            count += gift.count;
          });
          // item[element.place_id.title] = element.count;
          item[`out${element.place_id.title}`] = count;
          item[`in${element.place_id.title}`] = element.count;

          const filterArr1 = columnsArr.filter(
            (columnArr) => columnArr.accessor === `in${element.place_id.title}`
          );

          if (filterArr1.length === 0)
            columnsArr.push({
              Header: `\u2193${element.place_id.title}`,
              accessor: `in${element.place_id.title}`,
              width: "8%",
              align: "center",
            });

          const filterArr = columnsArr.filter(
            (columnArr) => columnArr.accessor === `out${element.place_id.title}`
          );

          if (filterArr.length === 0)
            columnsArr.push({
              Header: `\u2191${element.place_id.title}`,
              accessor: `out${element.place_id.title}`,
              width: "8%",
              align: "center",
            });
          allCountOut += count;
          allCountIn += element.count;
        });
        if (allCountIn !== item.count) item.color = "#adbbd2";
        else if (allCountOut === allCountIn) item.color = "#f8ddb7";

        item.title = buying.book_id.arabic_title;
        item.action = (
          <>
            {route.update && (
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{ name: "buyings", value: buying }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
            )}
            {route.delete && (
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("buyings", buying)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            )}
            {route.update && (
              <MDTypography
                style={{ padding: 20, color: "#e29f3f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onGoToAccountsHandler("buyings", buying)}
              >
                <Icon fontSize="small">credit_card</Icon>
              </MDTypography>
            )}
            {route.create && (
              <MDTypography
                style={{ padding: 20, color: "#3b00ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        updateHandlerVal={{ name: "buyings", value: buying, isCopy: true }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">content_copy</Icon>
              </MDTypography>
            )}
          </>
        );
        Arr.push(item);
      });

      columnsArr = [
        ...columnsArr,
        { Header: "action", accessor: "action", width: "10%", align: "center" },
      ];
      setColumns(columnsArr);
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "buyings",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/buying",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "buyings",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "buyings",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (buyings && route) {
      prepareTableRows();
    }
  }, [buyings, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        />
        <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} />

        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={buyingsMeta ? +buyingsMeta.filter_count : 0}
        />
        <input type="file" name="upload" id="upload" onChange={readUploadFile} />
      </Card>
    </Page>
  );
};

export default Buying;
