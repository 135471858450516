import * as React from "react";
import MUIAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { LoginOutlined } from "@mui/icons-material";

import trakibLogo from "assets/images/logos/trakib-logo.png";
import MDBox from "components/MDBox";
import { signInUrl } from "constants";
// import { Avatar } from "@mui/material";

export default function AppBar() {
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MUIAppBar position="static">
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <MDBox
              component="img"
              src={trakibLogo}
              alt="Trakib"
              width={80}
              height={80}
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
          </div>

          <Button href={signInUrl} color="inherit" startIcon={<LoginOutlined />}>
            تسجيل دخول
          </Button>
        </Toolbar>
      </MUIAppBar>
    </Box>
  );
}
