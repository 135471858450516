import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";
import { addComplexDataAction, fetchDataAction, reset, setAlert } from "store/features/coreSlice";
import PropTypes from "prop-types";
import UpdateRecord from "helpers/compare";
import { useTranslation } from "react-i18next";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { formproducts, formpublisher, limit } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [publisherId, setPublisherId] = useState(null);
  const [stores, setStores] = useState([]);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "publisher",
        id: "publisher",
        label: "publisher",
        data: formpublisher,
        size: 6,
        title: "publisher",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: formproducts,
        size: 6,
        title: "book",
      },
      {
        type: "TextField",
        name: "cost",
        id: "cost",
        label: "cost",
        size: 4,
        title: "cost",
      },
      {
        type: "chk",
        name: "is_dolar",
        id: "is_dolar",
        label: "is_dolar",
        size: 4,
        title: "$",
      },
      {
        type: "TextField",
        name: "count",
        id: "count",
        label: "count",
        size: 4,
        title: "count",
      },
      {
        type: "TextField",
        name: "discount_percentage",
        id: "discount_percentage",
        label: "discount_percentage",
        size: 6,
        title: "discountPercentage",
      },
      {
        type: "TextField",
        name: "distribution_cost",
        id: "distribution_cost",
        label: "distribution_cost",
        size: 6,
        title: "distributionCost",
      },
      {
        type: "TextField",
        name: "year",
        id: "year",
        label: "year",
        size: 3,
        title: "year",
      },
      {
        type: "TextField",
        name: "edition",
        id: "edition",
        label: "edition",
        size: 3,
        title: "edition",
      },
      {
        type: "TextField",
        name: "parts",
        id: "parts",
        label: "parts",
        size: 3,
        title: "parts",
      },
      {
        type: "TextField",
        name: "pages",
        id: "pages",
        label: "pages",
        size: 3,
        title: "pages",
      },
      {
        type: "Date",
        name: "finishing_date",
        id: "finishing_date",
        label: "finishing_date",
        size: 4,
        title: "finishingDate",
      },
      {
        type: "Date",
        name: "buying_date",
        id: "buying_date",
        label: "buying_date",
        size: 4,
        title: "buyingDate",
      },
      {
        type: "TextField",
        disable: true,
        name: "settlement_date",
        id: "settlement_date",
        label: "settlement_date",
        size: 4,
        title: "settlementDate",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 12,
        title: "notes",
      },
    ]);
  };

  const resetForm = () => {
    setPublisherId(null);
    orgData();
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "publisher") {
      setPublisherId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/products?fields=id,arabic_title,publisher&filter[publisher]=${values.id}`,
          varName: "formproducts",
        })
      );
    }
  };

  const onChangeSelectInputs = (name, text) => {
    if (name === "publisher") {
      dispatch(
        fetchDataAction({
          url: `items/publisher?fields=id,arabic_title&filter[arabic_title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "formpublisher",
        })
      );
    }

    if (name === "book_id") {
      let urlVal = `items/products?fields=id,arabic_title&filter[arabic_title][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (publisherId) urlVal += `&filter[publisher]=${publisherId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formproducts",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      dispatch(
        fetchDataAction({
          url: `items/${element}?limit=${limit}`,
          varName: `form${element}`,
        })
      );
    });
  };

  const onUpdateHandler = (name, values, copy) => {
    if (values.book_id?.publisher) {
      onChangeSelectInputs("publisher", values.book_id.publisher.arabic_title);
      setFieldSelectedValue("publisher", values.book_id.publisher);
    }
    setStores(values.stores);

    setInitialValues({
      id: copy ? null : values.id,
      book_id: values.book_id,
      publisher: values.book_id.publisher,
      // cost_other_currency: values.cost_other_currency,
      is_dolar: values.is_dolar,
      discount_percentage: values.discount_percentage,
      cost: values.cost,
      count: values.count,
      distribution_cost: values.distribution_cost,
      edition: values.edition,
      year: values.year,
      parts: values.parts,
      pages: values.pages,
      finishing_date: values.finishing_date,
      buying_date: values.buying_date,
      settlement_date: values.settlement_date,
      notes: values.notes,
    });
  };

  const setPayload = ({ files, ...values }) => {
    let count = 0;
    if (values.id) {
      stores.forEach((element) => {
        count += element.count;
      });
    }

    if (
      values.id === null ||
      values.count === initialValues.count ||
      (values.id && count <= values.count)
    ) {
      const payloadData = {};
      const lastData = values.id ? UpdateRecord(values, initialValues) : values;
      Object.keys(lastData).forEach((key) => {
        if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
        else if (lastData[key] !== undefined) payloadData[key] = lastData[key];
      });

      delete payloadData.publisher;

      const req = {
        id: values.id,
        file: null,
        origin: {
          data: Object.keys(payloadData).length === 0 ? null : payloadData,
          table: "buying",
        },
        getInfo: {
          url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
          varName: "buyings",
        },
      };

      setInitialValues(values);
      dispatch(addComplexDataAction(req));
      resetForm();
    } else
      dispatch(
        setAlert({
          status: "error",
          content: t("countError"),
        })
      );
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: dataVal.oterData }));
    };
  }, []);

  useEffect(() => {
    console.log(formproducts, formpublisher);
    if (formproducts && formpublisher) orgData();
  }, [formproducts, formpublisher]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        publisher: yup.mixed("required").required("required").nullable(),
        book_id: yup.mixed("required").required("required").nullable(),
        buying_date: yup.string("required").required("required").nullable(),
        pages: yup
          .number("must be number")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
        count: yup
          .number("must be number")
          .required("required")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
        cost: yup
          .number("must be number")
          .required("required")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
        distribution_cost: yup
          .number("must be number")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
        discount_percentage: yup
          .number("must be number")
          .typeError("must be a number")
          .required("required")
          // .positive("must be greater than zero")
          .nullable(),
        edition: yup
          .number("must be number")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
        parts: yup
          .number("must be number")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
        year: yup
          .number("must be number")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
      }}
      resetData={resetForm}
      name="research"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
