import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import ReactToPrint from "react-to-print";
import { Grid } from "@mui/material";
import MultipleSelectChip from "components/UI/MutlipleSelect";
import DataModal from "components/UI/DataModal";
import i18next from "i18n";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";

const styles = createUseStyles({
  title: {
    textAlign: "center",
    paddingBottom: "20px",
  },
  subTitle: {
    color: "#4a89a8",
    fontSize: "22px",
    fontWeight: "bold",
  },
  pdfTable: {
    fontFamily: "arial sansSerif",
    borderCollapse: "collapse",
    width: "100%",
    marginBottom: "10px",
    // -webkit-print-color-adjust: exact,
    // backgroundColor: "aqua",
  },

  pdfTd: {
    border: "1px solid #4a89a8",
    /* padding: 4px, */
    fontSize: "14px",
    /* font-weight: bold, */
    textAlign: "center",
    // -webkit-print-color-adjust: exact,
  },

  pdfTd2: {
    border: "1px solid #4a89a8",
    /* padding: 4px, */
    fontSize: "12px",
    /* font-weight: bold, */
    textAlign: "center",
    // -webkit-print-color-adjust: exact,
  },

  pdfTrTilte: {
    backgroundColor: "#4a89a8",
    color: "#fff",
    fontSize: "22px",
    fontWeight: "bold",
    // -webkit-print-color-adjust: exact,
  },

  pdfTdTilte: {
    backgroundColor: "#c8eef1",
    color: "#4a89a8",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
    padding: "5px",
    border: "1px solid #4a89a8",
    // -webkit-print-color-adjust: exact,
  },

  pdfTdTilteRight: {
    backgroundColor: "#c8eef1",
    color: "#4a89a8",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "right",
    padding: "5px",
    border: "1px solid #4a89a8",
    // -webkit-print-color-adjust: exact,
  },

  pdfFooterTilte: {
    /* background-color: #c8eef1, */
    color: "#4a89a8",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "5px",
    // -webkit-print-color-adjust: exact,
    /* border: 1px solid #4a89a8, */
  },
  pdfRolesTilte: {
    /* background-color: #c8eef1, */
    color: "#4a89a8",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "right",
    padding: "5px",
    // -webkit-print-color-adjust: exact,
    /* border: 1px solid #4a89a8; */
  },
});

const Report = ({ rows, repFields, total }) => {
  const classes = styles();
  const componentRef = useRef();
  const { t } = useTranslation();

  const [tableFields, setTableFields] = useState(repFields);
  const [open, setOpen] = useState(false);

  const createBtnHandller = () => <button type="button">Print this out!</button>;

  return (
    <div style={{ backgroundColor: "#fff", padding: 10 }}>
      <Grid container xs={12} item>
        <Grid item xm={8} xs={12}>
          <MultipleSelectChip
            data={repFields}
            optionTitle="Header"
            setTableFields={setTableFields}
            tableFields={tableFields}
          />
        </Grid>
        <Grid item xm={4} xs={12}>
          <MDButton
            color="info"
            style={{ margin: 5 }}
            variant="outlined"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {t("prepareReport")}
          </MDButton>
        </Grid>
      </Grid>
      <DataModal handleClose={() => setOpen(!open)} open={open}>
        <ReactToPrint
          content={() => componentRef.current}
          documentTitle="books"
          bodyClass="" // i dont exactly know what put here
          trigger={createBtnHandller}
          pageStyle={`
              @media print {
                .button-group {
                  display: none !important;
                }
              }`}
          copyStyles
          // onAfterPrint={() => }
        />
        <div ref={componentRef} id="app2" dir={i18next.language === "ar" ? "rtl" : "ltr"}>
          <div className={classes.title}>
            <div className={classes.subTitle}>{t("report")}</div>
          </div>
          <table className={classes.pdfTable}>
            <tbody>
              <tr className={classes.pdfTrTilte}>
                <td className={classes.pdfTd}>{t("tableId")}</td>
                {tableFields.map(
                  (field) =>
                    // index !== tableFields.length - 1 && (
                    field.Header !== "action" && (
                      <td className={classes.pdfTd} key={field.accessor}>
                        {t(field.Header)}
                      </td>
                    )
                )}
                {/* {i18next.language != "ar" && (
                  <td className={classes.pdfTd}>{t("id")}</td>
                )} */}
              </tr>
              {rows.map((item, index) => (
                <tr key={item.id}>
                  <td className={classes.pdfTd2}>{index + 1}</td>
                  {tableFields.map(
                    (field) =>
                      // index1 !== tableFields.length - 1 && (
                      field.Header !== "action" && (
                        <td className={classes.pdfTd2} key={field.accessor}>
                          {Number.isNaN(Number(item[field.accessor]))
                            ? item[field.accessor]
                            : +item[field.accessor]}
                        </td>
                      )
                  )}
                </tr>
              ))}
              {total && (
                <tr>
                  <td className={classes.pdfTd2}>{t("total")}</td>
                  {tableFields.map((field) => (
                    <td className={classes.pdfTd2} key={field.accessor}>
                      {Number.isNaN(Number(total[field.accessor]))
                        ? total[field.accessor]
                        : +total[field.accessor]}
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </DataModal>
    </div>
  );
};

Report.defaultProps = {
  total: null,
};

Report.propTypes = {
  rows: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  repFields: PropTypes.PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  total: PropTypes.objectOf(PropTypes.any),
};

export default Report;
