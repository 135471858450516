import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import MDTypography from "components/MDTypography";
import { Icon, Grid } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import Page from "components/UI/Page";
import { useLocation } from "react-router-dom";
import UpdateRecord from "helpers/compare";
import { fetchDataAction, addComplexDataAction, reset } from "store/features/coreSlice";
import * as yup from "yup";
import MDBox from "components/MDBox";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import dataVal from "./dataVal.json";

const Settings = () => {
  const { settings, resStatus, settingsMeta, limit } = useSelector((state) => state.core);
  const { t } = useTranslation();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const [route, setRoute] = useState(null);
  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [pageOptions, setPageOption] = useState([]);
  const [number, setNumber] = useState(1);

  const [successSB, setSuccessSB] = useState(false);

  const closeSuccessSB = () => setSuccessSB(false);

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      dolar: values.dolar,
      youtube: values.youtube,
      instagram: values.instagram,
      twitter: values.twitter,
      logo: values.logo && `${values.logo.data.full_url}`,
      title: values.title,
      facebook: values.facebook,
      linkedin: values.linkedin,
      telegram: values.telegram,
      website: values.website,
    });
    setOpen(true);
  };

  const onDeleteHandler = () => {
    setOpenDeleteModal(true);
  };

  useEffect(() => {
    const payload = {
      url: "items/admins?fields=*,logo.data.full_url&meta=*",
      varName: "settings",
    };
    dispatch(fetchDataAction(payload));
  }, []);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `items/admins?fields=*,logo.data.full_url&meta=*`,
          varName: "settings",
        })
      );
    }
  };

  useEffect(() => {
    let item = {};
    const Arr = [];
    let keys = [];
    if (settings && settings.length > 0 && route) {
      const d = [];
      for (let i = 0; i < PagesCount(+settingsMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      settings.forEach((setting) => {
        item = {};
        keys = Object.keys(setting);
        keys.forEach((element) => {
          item[element] = setting[element];
        });
        item.facebookIcon = (
          <MDBox width="8rem" textAlign="center">
            {item.facebook && <i className="fa fa-check-square-o" aria-hidden="true" />}
          </MDBox>
        );
        item.youtubeIcon = (
          <MDBox width="8rem" textAlign="center">
            {item.youtube && <i className="fa fa-check-square-o" aria-hidden="true" />}
          </MDBox>
        );
        item.telegramIcon = (
          <MDBox width="8rem" textAlign="center">
            {item.telegram && <i className="fa fa-check-square-o" aria-hidden="true" />}
          </MDBox>
        );
        item.linkedinIcon = (
          <MDBox width="8rem" textAlign="center">
            {item.linkedin && <i className="fa fa-check-square-o" aria-hidden="true" />}
          </MDBox>
        );
        item.websiteIcon = (
          <MDBox width="8rem" textAlign="center">
            {item.website && <i className="fa fa-check-square-o" aria-hidden="true" />}
          </MDBox>
        );
        item.instagramIcon = (
          <MDBox width="8rem" textAlign="center">
            {item.instagram && <i className="fa fa-check-square-o" aria-hidden="true" />}
          </MDBox>
        );
        item.twitterIcon = (
          <MDBox width="8rem" textAlign="center">
            {item.twitter && <i className="fa fa-check-square-o" aria-hidden="true" />}
          </MDBox>
        );
        item.action = route.update && (
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onUpdateHandler("settings", setting)}
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
        );

        Arr.push(item);
      });
      setNewArr(Arr);
    }
  }, [settings]);

  useEffect(() => {
    setData([
      {
        type: "TextField",
        name: "title",
        id: "title",
        label: "title",
        size: 4,
        title: "Title",
      },
      {
        type: "TextField",
        name: "dolar",
        id: "dolar",
        label: "dolar",
        size: 4,
        title: "Dolar",
      },
      {
        type: "TextField",
        name: "facebook",
        id: "facebook",
        label: "facebook",
        size: 4,
        title: "facebook",
      },
      {
        type: "TextField",
        name: "linkedin",
        id: "linkedin",
        label: "linkedin",
        size: 4,
        title: "linkedin",
      },
      {
        type: "TextField",
        name: "telegram",
        id: "telegram",
        label: "telegram",
        size: 4,
        title: "telegram",
      },
      {
        type: "TextField",
        name: "website",
        id: "website",
        label: "website",
        size: 4,
        title: "website",
      },
      {
        type: "TextField",
        name: "youtube",
        id: "youtube",
        label: "youtube",
        size: 4,
        title: "youtube",
      },
      {
        type: "TextField",
        name: "instagram",
        id: "instagram",
        label: "instagram",
        size: 4,
        title: "instagram",
      },
      {
        type: "TextField",
        name: "twitter",
        id: "twitter",
        label: "twitter",
        size: 4,
        title: "twitter",
      },
      {
        type: "Img",
        name: "logo",
        id: "logo",
        label: "logo",
        size: 4,
        title: "Logo",
      },
    ]);
  }, []);

  const resetForm = () => {
    setInitialValues({
      id: null,
      dolar: null,
      youtube: null,
      instagram: null,
      twitter: null,
      logo: null,
      title: null,
      facebook: null,
      linkedin: null,
      telegram: null,
      website: null,
    });
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      imgFieldName: "logo",
      id: values.id,
      file: typeof values.logo === "object" ? values.logo : null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "admins",
      },
      getInfo: {
        url: `items/admins?fields=*,logo.data.full_url&meta=*`,
        varName: "settings",
      },
    };
    setInitialValues(values);
    dispatch(addComplexDataAction(req));
  };

  const setOpenModal = () => {
    if (open)
      setInitialValues({
        id: null,
        dolar: null,
        youtube: null,
        instagram: null,
        twitter: null,
        logo: null,
        title: null,
        facebook: null,
        linkedin: null,
        telegram: null,
        website: null,
      });
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    // const payload = {
    //   url: `items/settings`,
    //   data: {
    //     id: deleteItemId,
    //     status: "deleted",
    //   },
    //   varName: "settings",
    //   getInfo: {
    //     url: `items/admins?fields=*,logo.data.full_url&meta=*`,
    //     varName: "settings",
    //   },
    // };
    // dispatch(deleteDataAction(payload));
    // setOpenDeleteModal(false);
  };

  if (data.length === 0 || newArr === [] || !route || !settingsMeta) {
    return null;
  }

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("systemDashboard")}
      content={t("successfulProcess")}
      dateTime={t("recently")}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <Page>
      <Grid item xs={12}>
        <Main
          enableCreate={false || !!initialValues.id}
          count={pageOptions}
          pageIndex={number - 1}
          allRowsCount={+settingsMeta.filter_count}
          setPageNum={setPageNumber}
          openDeleteModal={openDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
          setOpen={setOpenModal}
          setOpenDeleteModal={setOpenDeleteModal}
          open={open}
          name="settings"
          setPayload={setPayload}
          data={data}
          initialValues={initialValues}
          title="Add City"
          columns={dataVal.columns}
          rows={newArr}
          onUpdateHandler={onUpdateHandler}
          onDeleteHandler={onDeleteHandler}
          report={false}
          defaultInitialValues={dataVal.initialValues}
          yupObj={{
            dolar: yup
              .number("must be number")
              .required("required")
              .typeError("must be a number")
              .positive("must be greater than zero")
              .nullable(),
          }}
        />
        {renderSuccessSB}
      </Grid>
    </Page>
  );
};

export default Settings;
