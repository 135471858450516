import React, { useMemo } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Alert, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useSelector } from "react-redux";
import FormikControl from "./FormikControl";
import ImgList from "../ListImages";

const DataForm = ({
  yupObj,
  initialValues,
  setPayload,
  data,
  setFieldSelectedValue,
  name,
  onChange,
  enableCreate,
  view,
  debt,
  message,
  imgList,
  filter,
  formTitle,
  defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const { loadingFiles, loading } = useSelector((state) => state.core);

  const validationSchema = yup.object(yupObj);
  const formik = {};

  formik[name] = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setPayload(values, name);
      if (!filter) resetForm({ values: defaultInitialValues });
    },
  });

  return (
    <Card id="data-form" sx={{ overflow: "visible", p: 2, m: 2 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || loadingFiles}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox p={3}>
        <MDTypography variant="h5">{formTitle || ""}</MDTypography>
      </MDBox>
      <form onSubmit={formik[name].handleSubmit} style={{ padding: 10 }}>
        {message && (
          <Alert severity="warning" style={{ margin: 20 }}>
            {message}
          </Alert>
        )}
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {data.map(
            (item) =>
              item.condition !== false && (
                <Grid item xl={item.size} xs={12} key={item.name}>
                  {item.type === "MImg" ? (
                    useMemo(
                      () => (
                        <FormikControl
                          control={item.type}
                          id={item.id}
                          name={item.name}
                          minRows={item.minRows}
                          maxRows={item.maxRows}
                          data={item.data}
                          value={
                            debt && item.name === debt.name && debt.val
                              ? debt.val
                              : formik[name].values[item.name]
                          }
                          setFieldValue={formik[name].setFieldValue}
                          setFieldTouched={formik[name].setFieldTouched}
                          setFieldSelectedValue={setFieldSelectedValue}
                          optionTitle={item.optionTitle}
                          optionTitle1={item.optionTitle1}
                          oterOptionTitle={item.oterOptionTitle}
                          oterOptionTitle1={item.oterOptionTitle1}
                          optionTitlecond={item.optionTitlecond}
                          secondOptionTitle={item.secondOptionTitle}
                          secondOtherOptionTitle={item.secondOtherOptionTitle}
                          secondOtherOptionTitle1={item.secondOtherOptionTitle1}
                          optionTitleText={item.optionTitleText}
                          optionTitle1Text={item.optionTitle1Text}
                          onChange={onChange}
                          formName={name}
                          disable={item.disable}
                          title={t(item.title)}
                        />
                      ),
                      []
                    )
                  ) : (
                    <FormikControl
                      control={item.type}
                      id={item.id}
                      name={item.name}
                      minRows={item.minRows}
                      maxRows={item.maxRows}
                      data={item.data}
                      value={
                        debt && item.name === debt.name && debt.val
                          ? debt.val
                          : formik[name].values[item.name]
                      }
                      debt={debt && item.name === debt.name && debt.val}
                      setFieldValue={formik[name].setFieldValue}
                      setFieldTouched={formik[name].setFieldTouched}
                      setFieldSelectedValue={setFieldSelectedValue}
                      optionTitle={item.optionTitle}
                      optionTitle1={item.optionTitle1}
                      oterOptionTitle={item.oterOptionTitle}
                      oterOptionTitle1={item.oterOptionTitle1}
                      optionTitlecond={item.optionTitlecond}
                      optionTitleText={item.optionTitleText}
                      optionTitle1Text={item.optionTitle1Text}
                      secondOptionTitle={item.secondOptionTitle}
                      secondOtherOptionTitle={item.secondOtherOptionTitle}
                      secondOtherOptionTitle1={item.secondOtherOptionTitle1}
                      onChange={onChange}
                      formName={name}
                      disable={item.disable}
                      title={t(item.title)}
                    />
                  )}
                  <div
                    style={{
                      color: "#d32f2f",
                      fontFamily: "Roboto",
                      fontWeight: "400",
                      fontSize: "0.75rem",
                      lineHeight: "1.66",
                      letterSpacing: "0.03333em",
                      textAlign: "left",
                      marginTop: "3px",
                      marginRight: "14px",
                      marginBottom: "0",
                      marginLeft: "14px",
                    }}
                  >
                    {formik[name].touched[item.name] && formik[name].errors[item.name]}
                  </div>
                </Grid>
              )
          )}
          {enableCreate && view && (
            <Grid item xs={filter ? 2 : 12}>
              <MDButton color="info" variant="contained" fullWidth type="submit">
                {filter ? t("search") : t("submit")}
              </MDButton>
            </Grid>
          )}
        </Grid>
      </form>
      {imgList?.length > 0 && <ImgList files={imgList} />}
    </Card>
  );
};

DataForm.propTypes = {
  yupObj: PropTypes.objectOf(PropTypes.any), //
  initialValues: PropTypes.objectOf(PropTypes.any), //
  defaultInitialValues: PropTypes.objectOf(PropTypes.any), //
  setPayload: PropTypes.func, //
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])), //
  setFieldSelectedValue: PropTypes.func,
  name: PropTypes.string,
  onChange: PropTypes.func,
  enableCreate: PropTypes.bool,
  view: PropTypes.bool,
  debt: PropTypes.objectOf(PropTypes.any),
  message: PropTypes.string,
  imgList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.any])),
  filter: PropTypes.bool,
  formTitle: PropTypes.string,
};

DataForm.defaultProps = {
  yupObj: null,
  initialValues: null,
  defaultInitialValues: null,
  setPayload: () => null,
  data: [],
  setFieldSelectedValue: () => null,
  onChange: () => null,
  enableCreate: true,
  view: true,
  filter: false,
  name: null,
  debt: null,
  message: null,
  imgList: [],
  formTitle: null,
};

export default DataForm;
