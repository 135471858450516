import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import UpdateRecord from "helpers/compare";
import { Icon } from "@mui/material";
import PropTypes from "prop-types";
import PagesCount from "helpers/pages-count";
import dataVal from "./ProductInfo/dataVal.json";

import {
  addComplexDataAction,
  deleteDataAction,
  fetchDataAction,
  reset,
} from "../../../store/features/coreSlice";

const ProductMedias = ({ productId }) => {
  const dispatch = useDispatch();

  const { productMediaValue, productMediaValueMeta, resStatus, limit } = useSelector(
    (state) => state.core
  );
  const [newArr, setNewArr] = useState([]);
  const [arrData, setData] = useState([]);
  const [mediaOpenDeleteModal, setMediaOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [mediaInitialValues, setInitialValues] = useState({
    ...dataVal.productMedia.initialValues,
    product_id: productId,
  });

  const setMediaFieldSelectedValue = (name, values, isUpdate = false, updateCode) => {
    console.log(name, values, isUpdate, updateCode);
  };

  const onMediaUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      video_link: values.video_link,
      type: dataVal.medias.filter((meg) => meg.value === values.type)[0],
      image1: values.image ? values.image.data.full_url : null,
    });
  };

  const onMediaDeleteHandler = (name, values) => {
    setMediaOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const orgData = useCallback(() => {
    setData([
      // type === simple ?actual_price   discounted_price   currency    quantity    stock_quantity      dimensions   shipping_weight      type     height      height_mes   :
      {
        type: "Select",
        optionTitle: "title",
        name: "type",
        id: "type",
        label: "type",
        data: dataVal.medias,
        size: 4,
        title: "Media Type",
      },
      {
        type: "TextField",
        name: "video_link",
        id: "video_link",
        label: "video_link",
        size: 4,
        title: "Video Link",
      },
      {
        type: "Img",
        name: "image1",
        id: "image1",
        label: "image1",
        size: 4,
        title: "Image",
      },
    ]);
  }, []);

  useEffect(() => {
    orgData();
    return () => {
      dispatch(reset(null));
    };
  }, []);

  useEffect(() => {
    if (productMediaValue) {
      let item = {};
      const Arr = [];
      let keys = [];
      const d = [];
      for (let i = 0; i < PagesCount(+productMediaValueMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      if (productMediaValue && productMediaValue.length !== 0) {
        productMediaValue.forEach((product) => {
          item = {};
          keys = Object.keys(product);

          keys.forEach((element) => {
            item[element] = product[element];
          });

          item.img = <MDAvatar src={product.image?.data.full_url} size="xxl" />;

          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onMediaUpdateHandler("products", product)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onMediaDeleteHandler("products", product)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            </>
          );
          Arr.push(item);
        });
        setNewArr(Arr);
      } else {
        setNewArr([]);
      }
    }
  }, [productMediaValue]);

  const setArrPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, mediaInitialValues) : values;
    if (mediaInitialValues.type?.value !== values.type?.value)
      payloadData.type = values.type?.value;
    delete lastData.type;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      id: values.id,
      file: typeof values.image1 === "object" ? values.image1 : null,
      origin: {
        data:
          Object.keys(payloadData).length === 0 ? null : { ...payloadData, product_id: productId },
        table: "product_media",
      },
      getInfo: {
        url: `items/product_media?fields=id,image.data.full_url,video_link,type&filter[product_id]=${productId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "productMediaValue",
      },
    };
    console.log(req);
    dispatch(addComplexDataAction(req));
  };

  useEffect(() => {
    dispatch(
      fetchDataAction({
        url: `items/product_media?fields=id,image.data.full_url,video_link,type&filter[product_id]=${productId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "productMediaValue",
      })
    );
  }, [limit]);

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);
      dispatch(
        fetchDataAction({
          url: `items/product_media?fields=id,image.data.full_url,video_link,type&filter[product_id]=${productId}&meta=*&limit=${limit}&page=${num}`,
          varName: "productMediaValue",
        })
      );
    }
  };

  const handleMediaConfirmDelete = async () => {
    const payload = {
      url: "items/product_media",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `items/product_media?fields=id,image.data.full_url,video_link,type&filter[product_id]=${productId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "productMediaValue",
      },
    };
    dispatch(deleteDataAction(payload));
    setMediaOpenDeleteModal(false);
  };

  const resetForm = () => {
    setInitialValues(dataVal.productMedia.initialValues);
    orgData();
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      console.log("iam here");
      resetForm();
    }
  }, [resStatus]);

  if (arrData.length === 0 || newArr === [] || !productMediaValueMeta) {
    return null;
  }
  return (
    <Main
      // layout="simple"
      setPageNum={setPageNumber}
      // count={productMediaValueMeta.filter_count}
      setOpenDeleteModal={setMediaOpenDeleteModal}
      openDeleteModal={mediaOpenDeleteModal}
      handleConfirmDelete={handleMediaConfirmDelete}
      name="productMediaValue"
      setPayload={setArrPayload}
      data={arrData}
      initialValues={mediaInitialValues}
      title="Add attributeValue"
      columns={dataVal.mediaColumns}
      rows={newArr}
      onUpdateHandler={onMediaUpdateHandler}
      onDeleteHandler={onMediaDeleteHandler}
      setFieldSelectedValue={setMediaFieldSelectedValue}
      yupObj={null}
      entriesPerPage={false}
      pageIndex={pageNum - 1}
      count={pageOptions}
    />
  );
};
ProductMedias.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default ProductMedias;
