import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";

import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();

  const { formjobtitles, limit, pagesArr } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "route_name",
        id: "route_name",
        label: "route_name",
        data: pagesArr,
        size: 6,
        title: "route name",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "job_title_id",
        id: "job_title_id",
        label: "job_title_id",
        data: formjobtitles,
        size: 6,
        title: "job title",
      },
      // {
      //   type: "chk",
      //   name: "create",
      //   id: "create",
      //   label: "create",
      //   size: 4,
      //   title: "create",
      // },
      // {
      //   type: "chk",
      //   name: "update",
      //   id: "update",
      //   label: "update",
      //   size: 4,
      //   title: "update",
      // },
      // {
      //   type: "chk",
      //   name: "delete",
      //   id: "delete",
      //   label: "delete",
      //   size: 4,
      //   title: "delete",
      // },
    ]);
  };

  const resetForm = () => {
    orgData();
  };

  const getData = () => {
    dispatch(
      fetchDataAction({
        url: `items/job_title?fields=id,title`,
        varName: `formjobtitles`,
      })
    );
  };

  const onUpdateHandler = (name, values) => {
    getData();
    setInitialValues({
      id: values.id,
      job_title_id: values.job_title_id,
      route_name: pagesArr.filter((item) => item.id === values.route_name)[0],
      create: values.create,
      update: values.update,
      delete: values.delete,
    });
  };

  const setPayload = ({ files, ...values }) => {
    values.job_title_id = values.job_title_id.id;
    values.route_name = values.route_name.id;
    const req = {
      id: values.id,
      origin: {
        data: values,
        table: "job_title_routes",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "jobTitleRoutes",
      },
    };
    setInitialValues(values);
    dispatch(addComplexDataAction(req));
    resetForm();
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: ["jobtitles"] }));
    };
  }, []);

  useEffect(() => {
    if (formjobtitles) orgData();
  }, [formjobtitles]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        route_name: yup.mixed("required").required("required").nullable(),
        job_title_id: yup.mixed("required").required("required").nullable(),
      }}
      resetData={resetForm}
      name="permisions"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
