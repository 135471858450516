import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../dataVal.json";

const FilterForm = ({ resetData, baseUrl, setUrl }) => {
  const dispatch = useDispatch();
  const { limit, orderItemsProducts, orderItemsPublishers, orderItemscelebrities } = useSelector(
    (state) => state.core
  );

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.filterInitialValues);

  const orgData = () => {
    setData([
      {
        type: "MSelect",
        optionTitle: "arabic_title",
        name: "product_id",
        id: "product_id",
        label: "product_id",
        data: orderItemsProducts,
        size: 4,
        title: "products",
      },
      {
        type: "MSelect",
        optionTitle: "arabic_title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: orderItemsPublishers,
        size: 4,
        title: "publishers",
      },
      {
        type: "MSelect",
        optionTitle: "first_name",
        name: "celeb_id",
        id: "celeb_id",
        label: "celeb_id",
        data: orderItemscelebrities,
        size: 4,
        title: "celebrity",
      },
      {
        type: "MSelect",
        optionTitle: "title",
        name: "order_status",
        id: "order_status",
        label: "order_status",
        data: dataVal.orderStatus,
        size: 4,
        title: "order Status",
      },
      {
        type: "MSelect",
        optionTitle: "title",
        name: "payment_status",
        id: "payment_status",
        label: "payment_status",
        data: dataVal.paymentStatus,
        size: 4,
        title: "shipping Status",
      },
      {
        type: "Date",
        name: "date_from",
        id: "date_from",
        label: "date_from",
        size: 6,
        title: "from",
      },
      {
        type: "Date",
        name: "date_to",
        id: "date_to",
        label: "date_to",
        size: 6,
        title: "to",
      },
    ]);
  };

  useEffect(() => {
    if (
      orderItemsProducts !== undefined &&
      orderItemsPublishers !== undefined &&
      orderItemscelebrities !== undefined
    ) {
      orgData();
    }
  }, [orderItemsProducts, orderItemsPublishers, orderItemscelebrities]);

  const resetForm = () => {
    setInitialValues(dataVal.filterInitialValues);
    orgData();
    resetData();
  };

  const getData = () => {
    // get orderItems data
    dispatch(
      fetchDataAction({
        url: `items/products?fields=id,arabic_title`,
        varName: "orderItemsProducts",
      })
    );
    dispatch(
      fetchDataAction({
        url: `items/publisher?fields=id,arabic_title`,
        varName: "orderItemsPublishers",
      })
    );
    dispatch(
      fetchDataAction({
        url: `users?fields=first_name,id`,
        varName: "orderItemscelebrities",
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const setPayload = (values) => {
    let str = `${baseUrl}`;
    let otherFilterVal;
    const keys = Object.keys(values);
    console.log(values);
    keys.forEach((element) => {
      if (values[element]?.length > 0) {
        if (element === "celeb_id") {
          otherFilterVal = null;
          values[element].forEach((oVal, i) => {
            if (values[element].length === 1) otherFilterVal = `${oVal.id}`;
            else if (i === 0) {
              otherFilterVal = `${oVal.id},`;
            } else if (i === values[element].length - 1) {
              otherFilterVal += `${oVal.id}`;
            } else {
              otherFilterVal += `${oVal.id},`;
            }
          });
          str += `&filter[celebrity_id.id][in]=${otherFilterVal}`;
        } else if (element === "product_id") {
          otherFilterVal = null;
          values[element].forEach((oVal, i) => {
            if (values[element].length === 1) otherFilterVal = `${oVal.id}`;
            else if (i === 0) {
              otherFilterVal = `${oVal.id},`;
            } else if (i === values[element].length - 1) {
              otherFilterVal += `${oVal.id}`;
            } else {
              otherFilterVal += `${oVal.id},`;
            }
          });
          str += `&filter[product_id.id][in]=${otherFilterVal}`;
        } else if (element === "publisher_id") {
          otherFilterVal = null;
          values[element].forEach((oVal, i) => {
            if (values[element].length === 1) otherFilterVal = `${oVal.id}`;
            else if (i === 0) {
              otherFilterVal = `${oVal.id},`;
            } else if (i === values[element].length - 1) {
              otherFilterVal += `${oVal.id}`;
            } else {
              otherFilterVal += `${oVal.id},`;
            }
          });
          str += `&filter[product_id.publisher.id][in]=${otherFilterVal}`;
        } else {
          otherFilterVal = null;
          values[element].forEach((oVal, i) => {
            if (values[element].length === 1) otherFilterVal = `${oVal.value}`;
            else if (i === 0) {
              otherFilterVal = `${oVal.value},`;
            } else if (i === values[element].length - 1) {
              otherFilterVal += `${oVal.value}`;
            } else {
              otherFilterVal += `${oVal.value},`;
            }
          });

          str += `&filter[order_id.${element}][in]=${otherFilterVal}`;
        }
      }
    });
    if (values.date_from && values.date_to) {
      str += `&filter[order_id.date][between]=${values.date_from},${values.date_to}`;
    }
    setUrl(`${str}`);
    dispatch(
      fetchDataAction({
        url: `${str}&meta=*&limit=${limit}&page=1`,
        varName: "orderItems",
      })
    );
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      resetData={resetForm}
      name="orderItemsFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={() => {}}
      onChange={() => {}}
    />
  );
};
FilterForm.propTypes = {
  resetData: PropTypes.func.isRequired,
  setUrl: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default FilterForm;
