import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import * as yup from "yup";
import UpdateRecord from "helpers/compare";
import DataForm from "components/UI/form/DataForm";
import PropTypes from "prop-types";

import { addComplexDataAction } from "store/features/coreSlice";
import dataVal from "../dataVal.json";

const PageForm = ({ resetData, updateHandlerVal, pageNum, baseUrl }) => {
  const dispatch = useDispatch();

  const { limit } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "width",
        id: "width",
        label: "width",
        size: 3,
        title: "Width",
      },
      {
        type: "TextField",
        name: "height",
        id: "height",
        label: "height",
        size: 3,
        title: "height",
      },
      {
        type: "TextField",
        name: "length",
        id: "length",
        label: "length",
        size: 3,
        title: "length",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "height_mes",
        id: "height_mes",
        label: "height_mes",
        data: dataVal.heightMes,
        size: 3,
        title: "Height Mes",
      },
      {
        type: "TextField",
        name: "from",
        id: "from",
        label: "from",
        size: 3,
        title: "from",
      },
      {
        type: "TextField",
        name: "to",
        id: "to",
        label: "to",
        size: 3,
        title: "to",
      },
      {
        type: "TextField",
        name: "weight",
        id: "weight",
        label: "weight",
        size: 3,
        title: "weight",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "weight_mes",
        id: "weight_mes",
        label: "weight_mes",
        data: dataVal.weightMeg,
        size: 3,
        title: "Weight Meg",
      },
    ]);
  };

  const resetForm = () => {
    setInitialValues(dataVal.initialValues);
    orgData();
    resetData();
  };

  const setFieldSelectedValue = (name, values, isUpdate = false, updateCode) => {
    console.log(name, values, isUpdate, updateCode);
  };

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      width: values.width,
      height: values.height,
      length: values.length,
      from: values.from,
      to: values.to,
      weight_mes: dataVal.weightMeg.filter((meg) => meg.value === values.weight_mes)[0],
      height_mes: dataVal.heightMes.filter((meg) => meg.value === values.height_mes)[0],
      weight: values.weight,
    });
  };

  const setPayload = (values) => {
    console.log(values);
    const payloadData = {};

    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    if (initialValues.weight_mes?.value !== values.weight_mes?.value)
      payloadData.weight_mes = values.weight_mes?.value;
    if (initialValues.height_mes?.value !== values.height_mes?.value)
      payloadData.height_mes = values.height_mes?.value;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    delete lastData.weight_mes;
    delete lastData.height_mes;

    const req = {
      id: values.id,
      file: typeof values.image === "object" ? values.image : null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "shipping_package",
      },
      related: null,
      getInfo: {
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}&filter[status][neq]=deleted`,
        varName: "packages",
      },
    };
    dispatch(addComplexDataAction(req));

    resetForm();
  };

  const onChangeHandler = (name, val) => {
    console.log("data", name, val);
  };

  useEffect(() => {
    orgData();
  }, []);

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value);
    }
  }, [updateHandlerVal]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      // yupObj={{
      //   code: yup.string("Enter your Title").required("Title is required").nullable(),
      //   status: yup.mixed("Enter your status").required("Status is required").nullable(),
      // }}
      yupObj={{}}
      resetData={resetForm}
      name="packages"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeHandler}
    />
  );
};
PageForm.propTypes = {
  resetData: PropTypes.func.isRequired,
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
