import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setUrl, baseUrl, setNumber }) => {
  const dispatch = useDispatch();
  const { limit } = useSelector((state) => state.core);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);

  const [initialValues, setInitialValues] = useState(dataVal.initialValuesFilter);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "first_name",
        id: "first_name",
        label: "first_name",
        size: 3,
        title: "fullName",
      },
    ]);
  };

  useEffect(() => {
    orgData();
  }, []);

  const generateUrl = (values) => {
    let str = baseUrl;

    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (Array.isArray(values[element])) {
          if (element === "job_titles") {
            values[element].forEach((element1) => {
              str += `&filter[${element}.job_title_id][in]=${element1.id}`;
            });
          }
        } else if (typeof values[element] === "object") {
          str += `&filter[${element}.id]=${values[element].id}`;
        } else {
          str += `&filter[${element}][contains]=${values[element]}`;
        }
      }
    });

    return str;
  };

  const setPayload = (values) => {
    console.log("dfcvdfvd");
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    console.log(`${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`);
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "users",
      })
    );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "jobtitles") {
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title&limit=${limit}`,
            varName: `filterjobtitle`,
          })
        );
      } else if (element === "stores") {
        dispatch(
          fetchDataAction({
            url: "items/stores_places?fields=id,title",
            varName: `filter${element}`,
          })
        );
      } else {
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      }
    });
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  const onChangeSelectInputs = (name, text) => {
    if (name === "job_title_id") {
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title&filter[title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filterjobtitle",
        })
      );
    } else if (name === "libraries") {
      dispatch(
        fetchDataAction({
          url: `items/libraries?fields=id,title&filter[title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filterlibraries",
        })
      );
    } else if (name === "stores") {
      dispatch(
        fetchDataAction({
          url: `items/stores_places?fields=id,title&filter[title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filterstores",
        })
      );
    }
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="worksFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      onChange={onChangeSelectInputs}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
