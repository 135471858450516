import { Grid } from "@mui/material";
import Page from "components/UI/Page";
import Authors from "./SubContent/Authors";
import Brands from "./SubContent/Brands";
import Publishers from "./SubContent/Publishers";

const ProductsSettings = () => (
  <Page>
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6}>
        <Authors />
      </Grid>
      <Grid item xs={6}>
        <Publishers />
      </Grid>
      <Grid item xs={6}>
        <Brands />
      </Grid>
    </Grid>
  </Page>
);

export default ProductsSettings;
