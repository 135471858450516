import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Avatar from "components/UI/Table/avatar";
import DataModal from "components/UI/DataModal";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";
import DataTable from "components/UI/Table/DataTable";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Api from "services/api";
import PagesCount from "helpers/pages-count";

import { fetchDataAction, reset, setAlert } from "store/features/coreSlice";
import Page from "components/UI/Page";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import dataVal from "./dataVal.json";
import PageForm from "./PageForm";
import OrdersNotes from "./OrdersNotes";
import FilterForm from "./FilterForm";

const Orders = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orders, ordersMeta, resStatus, limit } = useSelector((state) => state.core);
  const baseUrl = `items/orders?fields=id,shipment_id,currency_code,amount,package_data,payment_method.method,customer_id.email,customer_id.country_code,order_items.product_id.discounted_price,customer_id.phone,ownerpushtoken,address_id.full_name,address_id.id,address_id.post_code,address_id.phone,address_id.country_name,address_id.city,address_id.detail,address_id.address_line_1,address_id.address_line_2,address_id.country_code,id,payment_status,order_status,customer_id.web_push_notification_token,customer_id.first_name,customer_id.last_name,amount,subtotal,shipping_methods.arabic_title,order_items.product_id.id,order_items.product_id.arabic_title,order_items.product_id.english_title,order_items.product_id.sku,order_items.quantity,order_items.price,order_items.other_price,order_items.id,date,order_items.currency_code,order_items.product_id.actual_price,order_items.product_id.publisher.id,price,shipping_value,subtotal,coupon_id.code,is_persentage,coupon_value,other_shipping_value,other_subtotal,other_price,shipment_label&sort=-id`;
  const [newArr, setNewArr] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const [url, setUrl] = useState(baseUrl);
  const [pageNum, setPageNum] = useState(1);
  const [trackingResults, setTrackingResults] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [open, setOpen] = useState(false);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);

  // console.log("trackingResults :", trackingResults);
  const closeSuccessSB = () => setSuccessSB(false);

  const resetForm = () => {
    dispatch(reset(null));
  };

  const getData = () => {
    dispatch(
      fetchDataAction({
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "orders",
      })
    );
  };

  const replaceAt = (str, replacement, index) =>
    str.substr(0, index) + replacement + str.substr(index + 1);

  const createShipping = async (item) => {
    const { data } = await Api.get(`custom/CreateShipments?orderId=${item.id}`);
    console.log(data);
    if (!data.HasErrors) {
      await Api.patch(`items/orders/${item.id}`, {
        shipment_id: data.Shipments[0].ID,
        order_status: "shipped",
        shipment_label: data.Shipments[0].ShipmentLabel
          ? data.Shipments[0].ShipmentLabel.LabelURL
          : null,
      });
      getData();
    }
  };

  const doCompletion = async () => {
    const { data } = await Api.post(`custom/doCompletion`);
    console.log(!data);
    if (!data)
      dispatch(
        setAlert({
          status: "error",
          content: `there is no new items`,
        })
      );
    else
      dispatch(
        setAlert({
          status: "success",
          content: `item has been inserted to account debt : ${data.debt} from order ID : ${data.firstOrderId} to order ID : ${data.last_order_id}`,
        })
      );
  };

  const getLastTrackingUpdate = async (item) => {
    const { data } = await Api.get(`custom/getLastTrackingUpdate?orderId=${item.id}`);
    if (!data.HasErrors) {
      setTrackingResults(
        data.TrackingResults.length > 0
          ? JSON.stringify(data.TrackingResults[0].Value[0])
          : "no result"
      );
    }
  };

  const createFreeOrderShipping = async (item) => {
    const { data } = await Api.get(`custom/CreateShipments?orderId=${item.id}`);
    console.log(data);
    if (!data.HasErrors) {
      await Api.patch(`items/orders/${item.id}`, {
        shipment_id: data.Shipments[0].ID,
        order_status: "shipped",
        payment_status: "free",
        shipment_label: data.Shipments[0].ShipmentLabel
          ? data.Shipments[0].ShipmentLabel.LabelURL
          : null,
      });

      await Api.get(`custom/success?order_no=${item.id}`);
      getData();
    } else {
      // console.log();
    }
  };

  const editItem = async (item) => {
    let status;
    if (item.order_status === "complete") status = "processing";
    else if (item.order_status === "processing") status = "shipped";
    else if (item.order_status === "shipped") status = "complete";

    await Api.patch(`items/orders/${item.id}`, {
      order_status: status,
    });
    getData();
  };

  const cancelItem = async (item) => {
    await Api.patch(`items/orders/${item.id}`, {
      order_status: "canceled",
    });
    getData();
  };

  const setOpenModal = (order, i) => {
    setFormContainer(i === 1 ? <PageForm order={order} /> : <OrdersNotes ordetId={order.id} />);
    setOpen(true);
  };

  const sendMailForAllCustomers = () => {
    setOpenModal({ customer_id: { email: "all" } }, 1);
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+ordersMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);

    orders.forEach((order) => {
      item = {};
      keys = Object.keys(order);

      item.address = `${order.address_id.full_name} ,
        ${order.address_id.id}
        ,
        ${order.address_id.post_code}
        ,
        ${order.address_id.phone}
        ,
        ${order.address_id.country_name}
        ,
        ${order.address_id.city}
        ,
        ${order.address_id.detail}`;
      // item.name = `${order.customer_id.first_name} ${order.customer_id.last_name}`;
      item.name = (
        <Avatar
          dir="ltr"
          name={`${order.customer_id.first_name} ${order.customer_id.last_name}`}
          email={order.customer_id.email}
        />
      );
      item.country = (
        <Avatar dir="ltr" name={order.address_id.country_name} email={order.customer_id.phone} />
      );
      item.total = <Avatar dir="ltr" name={order.price} email={order.currency_code} />;
      item.shipment = <Avatar dir="ltr" name={order.shipment_id} email={order.order_status} />;
      const index = order.date.indexOf(" ") + 1;
      const index1 = order.date.indexOf(" ") + 2;
      const h = Number(order.date.charAt(index));
      const h1 = Number(order.date.charAt(index1));
      let { date } = order;
      if (h < 2) {
        if (h1 < 7) {
          item.date = replaceAt(date, h1 + 3, index1);
        } else {
          item.date = replaceAt(date, h1 - 7, index1);
          date = order.date;
          item.date = replaceAt(date, h + 1, index);
        }
      } else if (h1 < 2) {
        item.date = replaceAt(date, h1 + 3, index1);
      } else {
        item.date = replaceAt(date, h1 - 1, index1);
        date = order.date;
        item.date = replaceAt(date, 0, index);
      }

      keys.forEach((element) => {
        item[element] = order[element];
      });

      item.action = (
        <>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => setOpenModal(order, 2)}
            title="add notes"
          >
            <Icon fontSize="small">notes</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => setOpenModal(order, 1)}
            title="send push notification"
          >
            <Icon fontSize="small">campaign</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => createShipping(order)}
            title="create shipping"
          >
            <Icon fontSize="small">local_shipping</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#e9423f" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => getLastTrackingUpdate(order)}
            title="get last tracking update"
          >
            <Icon fontSize="small">location_on</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => createFreeOrderShipping(order)}
            title="create free order shipping"
          >
            <Icon fontSize="small">card_giftcard</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => editItem(order)}
            title="change order status"
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => cancelItem(order)}
            title="cancel item"
          >
            <Icon fontSize="small">cancel</Icon>
          </MDTypography>
        </>
      );
      Arr.push(item);
    });
    setNewArr(Arr);
  };

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}&filter[status][neq]=deleted`,
          varName: "orders",
        })
      );
    }
  };

  // const sendNotificationModal = (record) => {
  //   ownerpushtoken = record.ownerpushtoken;
  //   dialogNotification = true;
  // };

  // const sendNotification = async () => {
  //   if (ownerpushtoken) {
  //     await Api.get(
  //       `custom/sendNotification?InvoiceAmount=${ownerpushtoken}&body=${body}`
  //     );
  //     dialogNotification = false;
  //   }
  // };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    getData();
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    if (orders) {
      prepareTableRows();
    }
  }, [orders]);

  if (newArr === [] || !ordersMeta) {
    return null;
  }

  return (
    <Page>
      <Grid item xs={12}>
        <MDBox bgColor="#fff" p={5}>
          <MDTypography style={{ direction: "ltr" }}>{trackingResults}</MDTypography>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xl={12} xs={12}>
              <DataModal
                handleClose={() => {
                  setOpen(false);
                }}
                open={open}
              >
                {formContainer}
              </DataModal>
              <FilterForm
                resetData={resetForm}
                pageNum={pageNum}
                baseUrl={baseUrl}
                setUrl={setUrl}
              />
              <DataTable
                table={{ columns: dataVal.columns, rows: newArr }}
                item={false}
                isSorted
                isPagination
                setSortByHandeller={() => {}}
                entriesPerPage
                showTotalEntries
                noEndBorder
                setPageNum={setPageNumber}
                pageIndex={pageNum - 1}
                pageOptions={pageOptions}
              />
              <Grid item xs={12}>
                <MDButton color="info" variant="contained" fullWidth onClick={doCompletion}>
                  {t("completion")}
                </MDButton>
              </Grid>
              <Grid item xs={12}>
                <MDButton
                  sx={{ mt: 3 }}
                  color="info"
                  variant="contained"
                  fullWidth
                  onClick={sendMailForAllCustomers}
                >
                  {t("Send Mail For All Customers")}
                </MDButton>
              </Grid>
            </Grid>
          </Grid>

          {renderSuccessSB}
        </MDBox>
      </Grid>
    </Page>
  );
};

export default Orders;
