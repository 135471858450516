import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
// import Avatar from "components/UI/Table/avatar";
// import DataModal from "components/UI/DataModal";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import PagesCount from "helpers/pages-count";

import { fetchDataAction, deleteDataAction, reset } from "store/features/coreSlice";
import Page from "components/UI/Page";
import dataVal from "./dataVal.json";
import PageForm from "./PageForm";
// import FilterForm from "./FilterForm";

const Coupons = () => {
  const dispatch = useDispatch();
  const { coupons, couponsMeta, resStatus, limit } = useSelector((state) => state.core);
  const baseUrl = `items/coupons?fields=id,free_shipping,one_user_useage,code,status,category_id.id,tag_id.id,category_id.arabic_title,tag_id.arabic_title,product_id.id,product_id.arabic_title,user_max_num,start_date,exp_date,persentage,value`;
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [updateHandlerVal, setUpdateHandlerVal] = useState(null);
  const [successSB, setSuccessSB] = useState(false);
  const [pageOptions, setPageOption] = useState([]);

  // const [url, setUrl] = useState(baseUrl);
  const [pageNum, setPageNum] = useState(1);

  const closeSuccessSB = () => setSuccessSB(false);
  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const resetForm = () => {
    dispatch(reset(null));
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const getData = () => {
    // get coupon data
    dispatch(
      fetchDataAction({
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "coupons",
      })
    );
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+couponsMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    coupons.forEach((coupon) => {
      let forWho = "";

      item = {};
      keys = Object.keys(coupon);

      if (coupon.product_id) {
        forWho = coupon.product_id.arabic_title;
      } else {
        if (coupon.category_id) {
          forWho = coupon.category_id.arabic_title;

          if (coupon.tag_id) {
            forWho += " - ";
          }
        }
        if (coupon.tag_id) {
          forWho += coupon.tag_id.arabic_title;
        }
      }

      item.for = forWho;

      let color = "";
      if (coupon.status === "published") {
        color = "green";
      } else if (coupon.status === "draft") {
        color = "gray";
      } else if (coupon.status === "rejected") {
        color = "red";
      }
      item.colory = (
        <div
          style={{
            borderRadius: "25px",
            width: "15px",
            height: "15px",
            background: color,
          }}
        />
      );

      keys.forEach((element) => {
        item[element] = coupon[element];
      });

      item.action = (
        <>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => setUpdateHandlerVal({ name: "coupons", value: coupon })}
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#e9423f" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onDeleteHandler("coupons", coupon)}
          >
            <Icon fontSize="small">delete</Icon>
          </MDTypography>
        </>
      );
      Arr.push(item);
    });
    setNewArr(Arr);
  };

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);
      dispatch(
        fetchDataAction({
          url: `${baseUrl}&meta=*&limit=${limit}&page=${num}&filter[status][neq]=deleted`,
          varName: "coupons",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/coupons",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}&filter[status][neq]=deleted`,
        varName: "coupons",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    getData();
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    if (coupons) {
      prepareTableRows();
    }
  }, [coupons]);

  if (newArr === [] || !couponsMeta) {
    return null;
  }

  return (
    <Page>
      <Grid item xs={12}>
        <MDBox bgColor="#fff" p={5}>
          <DeleteModal
            handleClose={handleDeleteModalClose}
            handleConfirmDelete={handleConfirmDelete}
            openDeleteModal={openDeleteModal}
          />
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xl={7} xs={12}>
              <DataTable
                table={{ columns: dataVal.columns, rows: newArr }}
                item={false}
                isSorted
                isPagination
                entriesPerPage
                showTotalEntries
                noEndBorder
                search
                setPageNum={setPageNumber}
                pageOptions={pageOptions}
                setSortByHandeller={() => {}}
                pageIndex={pageNum - 1}
                // count={couponsMeta.filter_count}
                allRowsCount={couponsMeta ? +couponsMeta.filter_count : 0}
              />
            </Grid>
            <Grid item xl={5} xs={12}>
              <PageForm
                resetData={resetForm}
                updateHandlerVal={updateHandlerVal}
                pageNum={pageNum}
                baseUrl={baseUrl}
              />
            </Grid>
          </Grid>

          {renderSuccessSB}
        </MDBox>
      </Grid>
    </Page>
  );
};

export default Coupons;
