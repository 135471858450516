import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";
import Avatar from "components/UI/Table/avatar";
import MDSnackbar from "components/MDSnackbar";
import UpdateRecord from "helpers/compare";
import PagesCount from "helpers/pages-count";

import {
  fetchDataAction,
  deleteDataAction,
  reset,
  addComplexDataAction,
} from "../../../store/features/coreSlice";

const Brands = () => {
  const { brandsMain, brandsMainMeta, resStatus, limit } = useSelector((state) => state.core);

  const dispatch = useDispatch();

  const columns = [
    { Header: "Title", accessor: "title", width: "45%", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [initialValues, setInitialValues] = useState({
    id: null,
    arabic_title: null,
    english_title: null,
    sequence: 0,
    image: null,
    logo: null,
  });
  const brandInitialValues = {
    id: null,
    arabic_title: null,
    english_title: null,
    sequence: 0,
    image: null,
    logo: null,
  };
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      arabic_title: values.arabic_title,
      english_title: values.english_title,
      sequence: values.sequence,
      image: values.image?.data?.full_url,
      logo: values.logo?.data?.full_url,
    });
    setOpen(true);
  };
  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  useEffect(() => {
    const payload = {
      url: `items/brands?fields=*,image.data.full_url,logo.data.full_url&meta=*&limit=${limit}&page=${pageNum}`,
      varName: "brandsMain",
    };
    dispatch(fetchDataAction(payload));
    return () => {
      dispatch(reset(null));
    };
  }, []);

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      dispatch(
        fetchDataAction({
          url: `items/brands?fields=*,image.data.full_url,logo.data.full_url&meta=*&limit=${limit}&page=${num}`,
          varName: "brandsMain",
        })
      );
    }
  };

  useEffect(() => {
    if (brandsMain) {
      let item = {};
      const Arr = [];
      const d = [];
      for (let i = 0; i < PagesCount(+brandsMainMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      let keys;
      if (brandsMain && brandsMain.length !== 0) {
        brandsMain.forEach((brand) => {
          item = {};
          keys = Object.keys(brand);

          item.title = (
            <Avatar
              dir="ltr"
              image={brand.logo?.data?.full_url}
              name={brand.arabic_title}
              email={brand.english_title}
            />
          );

          keys.forEach((element) => {
            item[element] = brand[element];
          });
          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onUpdateHandler("brandsMain", brand)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("brandsMain", brand)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            </>
          );
          Arr.push(item);
        });
        setNewArr(Arr);
      }
    }
  }, [brandsMain]);

  const data = [
    {
      type: "TextField",
      name: "arabic_title",
      id: "arabic_title",
      label: "arabic_title",
      size: 5,
      title: "Arabic Title",
    },
    {
      type: "TextField",
      name: "english_title",
      id: "english_title",
      label: "english_title",
      size: 5,
      title: "English Title",
    },
    {
      type: "TextField",
      name: "sequence",
      id: "sequence",
      label: "sequence",
      size: 2,
      title: "Sequence",
    },
    {
      type: "Img",
      name: "image",
      id: "image",
      label: "image",
      size: 6,
      title: "Image",
    },
    {
      type: "Img",
      name: "logo",
      id: "logo",
      label: "logo",
      size: 6,
      title: "Logo",
    },
  ];

  const resetForm = () => {
    setInitialValues({
      id: null,
      arabic_title: null,
      english_title: null,
      sequence: 0,
      image: null,
      logo: null,
    });
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      id: values.id,
      haveMulti: true,
      file: [
        {
          file: typeof values.image === "object" ? values.image : null,
          imgFieldName: "image",
        },
        {
          file: typeof values.logo === "object" ? values.logo : null,
          imgFieldName: "logo",
        },
      ],
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "brands",
      },
      getInfo: {
        url: `items/brands?fields=*,image.data.full_url,logo.data.full_url&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "brandsMain",
      },
    };
    console.log(req);
    dispatch(addComplexDataAction(req));
  };
  const setOpenModal = () => {
    if (open) resetForm();
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: `items/brands`,
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      varName: "brandsMain",
      getInfo: {
        url: `items/brands?fields=*,image.data.full_url,logo.data.full_url&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "brandsMain",
      },
    };

    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };
  if (data.length === 0 || newArr === [] || !brandsMainMeta) {
    return null;
  }
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <>
      <Main
        layout="simple"
        allRowsCount={+brandsMainMeta.filter_count}
        pageIndex={pageNum - 1}
        count={pageOptions}
        setPageNum={setPageNumber}
        report={false}
        openDeleteModal={openDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        setOpen={setOpenModal}
        setOpenDeleteModal={setOpenDeleteModal}
        open={open}
        name="brandsMain"
        setPayload={setPayload}
        data={data}
        initialValues={initialValues}
        title="Add Brand"
        columns={columns}
        rows={newArr}
        onUpdateHandler={onUpdateHandler}
        onDeleteHandler={onDeleteHandler}
        defIniVal={brandInitialValues}
        yupObj={{
          arabic_title: yup.string("Enter your Title").required("Title is required"),
        }}
      />
      {renderSuccessSB}
    </>
  );
};

export default Brands;
