import { Grid } from "@mui/material";
import Page from "components/UI/Page";
import Currencies from "./SubContent/Currencies";
import Countries from "./SubContent/Countries";
import Languages from "./SubContent/Languages";
import Banners from "./SubContent/Banners";

const SystemSettings = () => (
  <Page>
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6}>
        <Currencies />
      </Grid>
      <Grid item xs={6}>
        <Countries />
      </Grid>
      <Grid item xs={6}>
        <Languages />
      </Grid>
      <Grid item xs={6}>
        <Banners />
      </Grid>
    </Grid>
  </Page>
);

export default SystemSettings;
