import { Grid } from "@mui/material";
import Page from "components/UI/Page";
import ProductInfo from "./screens/ProductInfo";

const Products = () => (
  <Page>
    <Grid item xs={12}>
      <ProductInfo />
    </Grid>
  </Page>
);

export default Products;
