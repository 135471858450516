import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction, fetchDataActionPostMethod } from "store/features/coreSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as yup from "yup";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { filterusers, filterproducts, filteraccountsItems, limit, accounts } = useSelector(
    (state) => state.core
  );
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);

  const dateTypes = [
    { id: "date", title: t("accountDate") },
    { id: "due_date", title: t("dueDate") },
  ];
  const initFilter = {
    item_id: null,
    type: null,
    user_id: null,
    date_type: { id: "due_date", title: t("dueDate") },
    date_from: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"),
    date_to: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
  };

  const [initialValues, setInitialValues] = useState(initFilter);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "type",
        id: "type",
        label: "type",
        data: dataVal.types,
        size: 3,
        title: "accountType",
      },
      {
        type: "Select",
        optionTitle: "first_name",
        name: "user_id",
        id: "user_id",
        label: "user_id",
        data: filterusers,
        size: 3,
        title: "users",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "item_id",
        id: "item_id",
        label: "item_id",
        data: filteraccountsItems,
        size: 3,
        title: "item",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: filterproducts,
        size: 3,
        title: "book",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "date_type",
        id: "date_type",
        label: "date_type",
        data: dateTypes,
        size: 3,
        title: "dateType",
      },
      {
        type: "Date",
        name: "date_from",
        id: "date_from",
        label: "date_from",
        size: 3,
        title: "from",
      },
      {
        type: "Date",
        name: "date_to",
        id: "date_to",
        label: "date_to",
        size: 3,
        title: "to",
      },
    ]);
  };

  useEffect(() => {
    if (filterusers && filterproducts && filteraccountsItems) {
      orgData();
    }
  }, [filterusers, filterproducts, filteraccountsItems]);

  const generateUrl = (values) => {
    let str = `${baseUrl}`;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (typeof values[element] === "object" && element !== "date_type") {
          if (element === "type") {
            str += `&filter[${element}]=${values[element].value}`;
          } else {
            str += `&filter[${element}.id]=${values[element].id}`;
          }
        } else if (element !== "date_from" && element !== "date_to" && element !== "date_type") {
          str += `&filter[${element}][contains]=${values[element]}`;
        }
      }
    });
    if (values.date_from && values.date_to && values.date_type) {
      str += `&filter[${values.date_type.id}][between]=${values.date_from},${values.date_to}`;
    }

    return str;
  };

  const onChangeSelectInputs = (name, text) => {
    // setInitialValuesFilter(initFilter);
    if (name === "item_id") {
      dispatch(
        fetchDataAction({
          url: `items/accounts_items?fields=id,title&filter[title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: `filteraccountsItems`,
        })
      );
    } else if (name === "book_id") {
      dispatch(
        fetchDataAction({
          url: `items/products?fields=id,arabic_title&filter[arabic_title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filterproducts",
        })
      );
    } else if (name === "user_id") {
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name&filter[first_name][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filterusers",
        })
      );
    }
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);

    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "accounts",
      })
    );
    if (!accounts)
      dispatch(
        fetchDataActionPostMethod({
          url: `custom/getAccountsTotal`,
          varName: "accountsTotal",
          data: values,
        })
      );
  };

  useEffect(() => {
    if (accounts) {
      console.log(accounts.length);
      dispatch(
        fetchDataActionPostMethod({
          url: `custom/getAccountsTotal`,
          varName: "accountsTotal",
          data: filteredValues,
        })
      );
    }
  }, [accounts]);

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "users") {
        dispatch(
          fetchDataAction({
            url: `${element}?fields=id,first_name&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      } else if (element === "accountsItems")
        dispatch(
          fetchDataAction({
            url: `items/accounts_items?fields=id,title&limit=${limit}`,
            varName: `filteraccountsItems`,
          })
        );
      else
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,arabic_title&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
    });
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{
        date_type: yup.mixed("required").required("required").nullable(),
        date_from: yup.string("required").required("required").nullable(),
        date_to: yup.string("required").required("required").nullable(),
      }}
      name="accountsFilter"
      data={data}
      initialValues={initialValues}
      onChange={onChangeSelectInputs}
      setPayload={setPayload}
      setFieldSelectedValue={() => {}}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default FilterForm;
