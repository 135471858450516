import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "components/UI/Table/avatar";
import MDSnackbar from "components/MDSnackbar";
import DataTable from "components/UI/Table/DataTable";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import PagesCount from "helpers/pages-count";

import { fetchDataAction, reset } from "store/features/coreSlice";
import Page from "components/UI/Page";
import dataVal from "./dataVal.json";
import FilterForm from "./FilterForm";

const OrderItems = () => {
  const dispatch = useDispatch();
  const { orderItems, orderItemsMeta, resStatus, limit } = useSelector((state) => state.core);
  const baseUrl = `items/order_item?fields=id,order_id.date,order_id.id,product_id.discounted_price,product_id.id,product_id.arabic_title,product_id.english_title,product_id.sku,quantity,price,other_price,id,currency_code,product_id.actual_price,product_id.publisher.id,product_id.image.data.full_url,product_id.publisher.arabic_title,celebrity_id.first_name,celebrity_id.email,celebrity_id.id,celebrity_id.avatar.data.full_url&sort=-id`;
  const [newArr, setNewArr] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const [url, setUrl] = useState(baseUrl);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);
  // const r = 2;
  // if (r + 1 !== 3) setUrl("dd");
  const closeSuccessSB = () => setSuccessSB(false);

  const resetForm = () => {
    dispatch(reset(null));
  };

  const getData = () => {
    dispatch(
      fetchDataAction({
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "orderItems",
      })
    );
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+orderItemsMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);

    orderItems.forEach((order) => {
      item = {};
      keys = Object.keys(order);

      item.name = (
        <Avatar
          dir="ltr"
          image={order.product_id.image?.data.full_url}
          name={order.product_id?.arabic_title}
          email={order.product_id?.publisher?.arabic_title}
        />
      );

      item.celebName = (
        <Avatar
          dir="ltr"
          image={order.celebrity_id?.avatar?.data.full_url}
          name={order.celebrity_id?.first_name}
          email={order.celebrity_id?.email}
        />
      );
      item.orderId = order.order_id?.id;
      item.tPrice = `${order.price} ${order.currency_code}`;
      item.oPrice = `${order.other_price} KWD`;
      keys.forEach((element) => {
        item[element] = order[element];
      });

      Arr.push(item);
    });
    setNewArr(Arr);
  };

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}&filter[status][neq]=deleted`,
          varName: "orderItems",
        })
      );
    }
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    getData();
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    if (orderItems) {
      prepareTableRows();
    }
  }, [orderItems]);

  if (newArr === [] || !orderItemsMeta) {
    return null;
  }

  return (
    <Page>
      <Grid item xs={12}>
        <MDBox bgColor="#fff" p={5}>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xl={12} xs={12}>
              <FilterForm
                resetData={resetForm}
                pageNum={pageNum}
                baseUrl={baseUrl}
                setUrl={setUrl}
              />
              <DataTable
                table={{ columns: dataVal.columns, rows: newArr }}
                item={false}
                isSorted
                isPagination
                setSortByHandeller={() => {}}
                entriesPerPage
                showTotalEntries
                noEndBorder
                setPageNum={setPageNumber}
                pageIndex={pageNum - 1}
                pageOptions={pageOptions}
              />
            </Grid>
          </Grid>

          {renderSuccessSB}
        </MDBox>
      </Grid>
    </Page>
  );
};

export default OrderItems;
