import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import localStoragePrefix from "helpers/config";
import Api from "../../services/api";

const initialState = {
  userInfo: localStorage.getItem(`${localStoragePrefix}userInfo`)
    ? JSON.parse(localStorage.getItem(`${localStoragePrefix}userInfo`))
    : null,
  token: localStorage.getItem(`${localStoragePrefix}token`)
    ? localStorage.getItem(`${localStoragePrefix}token`)
    : null,
  message: null,
  loading: false,
  userInfoDetail: null,
  usersLogsDetail: null,
};

export const loginAction = createAsyncThunk(
  "auth/loginAction",
  async (payload, { rejectWithValue }) => {
    try {
      const respo = {};
      const { data } = await Api.post("auth/authenticate", payload);
      const { user, token } = data.data;

      localStorage.setItem(`${localStoragePrefix}token`, token);

      await Api.get(`custom/checkeExpiryDate`);
      const status = await Api.get(`items/admins/${user.admin_id}?fields=status`);

      if (status.data.data.status !== "published") {
        console.log(status.data.data.status);
        return rejectWithValue({
          error: { message: "subscriptionHasExpired" },
        });
      }

      const routes = await Api.get(
        `items/job_title_routes?fields=route_name,create,update,delete&filter[job_title_id]=100000`
      );

      const r = [];
      routes.data.data.forEach((element) => {
        const y = r.filter((x) => x.route_name === element.route_name);
        if (y.length === 0) r.push(element);
      });

      let avatar = null;
      if (data.data.user.avatar) {
        avatar = await Api.get(`files/${data.data.user.avatar}`);
      }
      user.userAvatar = avatar ? avatar.data.data.data.full_url : null;
      user.routes = r;

      localStorage.setItem(`${localStoragePrefix}userInfo`, JSON.stringify(user));
      respo.user = user;
      respo.token = token;
      return respo;
    } catch (error) {
      const message =
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message;
      console.log(error);
      if (message) {
        throw message;
      }

      return rejectWithValue(message);
    }
  }
);

export const getUserInfoAction = createAsyncThunk(
  "auth/getUserInfoAction",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await Api.get(
        `users/${payload}?fields=*,job_titles.job_title_id.title,job_titles.job_title_id.id,avatar.data.full_url,job_titles.job_title_id.routes_id.route_name`
      );
      return data.data;
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message;
      console.log(error);
      if (message) {
        throw message;
      }

      return rejectWithValue(message);
    }
  }
);

export const getUserActivitiesAction = createAsyncThunk(
  "auth/getUserActivitiesAction",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { userInfo },
      } = getState();
      let url = `activity?fields=*,action_by.id,action_by.admin_id,action_by.first_name,action_by.last_name`;

      if (payload?.userId) url += `&filter[action_by.id]=${payload.userId}&limit=50`;
      else if (payload?.collection && userInfo.admin_id === "1")
        url += `&filter[collection]=${payload.collection}&limit=50`;
      else if (payload?.collection)
        url += `&filter[collection]=${payload.collection}&filter[action_by.admin_id]=${userInfo.admin_id}&limit=50`;
      else if (!payload) url += `&filter[action_by.admin_id]=${userInfo.admin_id}&limit=100`;

      url += `&sort=-action_on`;
      const { data } = await Api.get(url);
      return data.data;
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.error.message
          ? error.response.data.error.message
          : error.message;
      console.log(error);
      if (message) {
        throw message;
      }

      return rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutAction: () => {
      localStorage.removeItem(`${localStoragePrefix}userInfo`);
      localStorage.removeItem(`${localStoragePrefix}token`);
    },
  },
  extraReducers: {
    [loginAction.pending]: (state) => ({ ...state, loading: true }),
    [loginAction.fulfilled]: (state, { payload }) => ({
      ...state,
      userInfo: payload.user,
      token: payload.token,
      loading: false,
    }),
    [loginAction.rejected]: (state, { payload, error: { message } }) => ({
      ...state,
      message: payload ? payload.error.message : message,
      loading: false,
    }),
    [getUserInfoAction.pending]: (state) => ({ ...state, loading: true }),
    [getUserInfoAction.fulfilled]: (state, { payload }) => ({
      ...state,
      userInfoDetail: payload,
      loading: false,
    }),
    [getUserInfoAction.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),

    [getUserActivitiesAction.pending]: (state) => ({ ...state, loading: true }),
    [getUserActivitiesAction.fulfilled]: (state, { payload }) => ({
      ...state,
      usersLogsDetail: payload,
      loading: false,
    }),
    [getUserActivitiesAction.rejected]: (state, { error: { message } }) => ({
      ...state,
      message,
      loading: false,
    }),
  },
});

// Action creators are generated for each case reducer function
// export const {} = authSlice.actions;

export const { logoutAction } = authSlice.actions;

export default authSlice.reducer;
