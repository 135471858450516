import Icon from "@mui/material/Icon";
import BookStoreSettings from "layouts/book-store/book-store-settings";
import Buying from "layouts/book-store/Buying";
import Proposals from "layouts/book-store/Proposals";
import AdminSettings from "layouts/sys-admin/admin-settings";
import Reviews from "layouts/sys-admin/reviews";
import Support from "layouts/sys-admin/support";
import NotifyMe from "layouts/sys-admin/notify-me";
import Stores from "layouts/book-store/Stores";
import Gifts from "layouts/book-store/Gifts";
import Returns from "layouts/book-store/Returns";
import Inventory from "layouts/book-store/Inventory";
import Distribution from "layouts/book-store/Distribusion";
import InsertUser from "layouts/sys-users/insert-user";
import InsertLibrary from "layouts/libraries";
import DistributionReport from "layouts/reports/distribution-report";
import LibraryReport from "layouts/reports/library-report";
import AccountSettings from "layouts/accounts/account-settings";
import InsertAccount from "layouts/accounts/insert-account";
import ProductsSettings from "layouts/productsSettings";
import Products from "layouts/products";
import Coupons from "layouts/coupons";
import Packageing from "layouts/packageing";
import PharmacyCategories from "layouts/categories/screens/PharmacyCategories";
import LibraryCategories from "layouts/categories/screens/LibraryCategories";
import PharmacySubCategories from "layouts/categories/screens/PharmacySubCategories";
import Tags from "layouts/categories/screens/Tags";
import BookTypes from "layouts/categories/screens/BookTypes";
import Celebrities from "layouts/celebrities";
import SystemSettings from "layouts/systemSettings";
import Orders from "layouts/orders";
import OrderItems from "layouts/orderItems";
import AdminPermissions from "layouts/sys-admin/admin-permissions";
import UserSettings from "layouts/sys-users/user-settings";

const routes = [
  {
    type: "collapse",
    name: "admin",
    key: "sys-admin",
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    collapse: [
      {
        name: "settings",
        key: "admin-settings",
        route: "/sys-admin/admin-settings",
        component: <AdminSettings />,
      },
      {
        name: "system-settings",
        key: "system-settings",
        route: "/system-settings",
        component: <SystemSettings />,
      },
      {
        table: "job_title_routes",
        name: "permissions",
        key: "admin-permissions",
        route: "/sys-admin/admin-permissions",
        component: <AdminPermissions />,
      },
      {
        name: "reviews",
        key: "reviews",
        route: "/reviews",
        component: <Reviews />,
      },
      {
        name: "support",
        key: "support",
        route: "/support",
        component: <Support />,
      },
      {
        name: "notify-me",
        key: "notify-me",
        route: "/notify-me",
        component: <NotifyMe />,
      },
    ],
  },
  {
    type: "collapse",
    name: "users",
    key: "sys-users",
    icon: <Icon fontSize="medium">person</Icon>,
    collapse: [
      {
        name: "insert-user",
        key: "insert-user",
        route: "/sys-users/insert-user",
        component: <InsertUser />,
      },
      {
        name: "user-settings",
        key: "user-settings",
        route: "/sys-users/user-settings",
        component: <UserSettings />,
      },
      {
        name: "add-celebrity",
        key: "add-celebrity",
        route: "/add-celebrity",
        component: <Celebrities />,
      },
    ],
  },
  {
    type: "collapse",
    name: "shipping",
    key: "shipping",
    icon: <Icon fontSize="medium">local_shipping</Icon>,
    collapse: [
      {
        name: "packageing",
        key: "packageing",
        route: "/packageing",
        component: <Packageing />,
      },
    ],
  },
  {
    type: "collapse",
    name: "accounts",
    key: "accounts",
    icon: <Icon fontSize="medium">account_balance_wallet</Icon>,
    collapse: [
      {
        name: "insert-account",
        key: "insert-account",
        route: "/accounts/insert-account",
        component: <InsertAccount />,
      },
      {
        name: "coupons",
        key: "coupons",
        route: "/coupons",
        component: <Coupons />,
      },
      {
        name: "orders",
        key: "orders",
        route: "/orders",
        component: <Orders />,
      },
      {
        name: "account-settings",
        key: "account-settings",
        route: "/accounts/account-settings",
        component: <AccountSettings />,
      },
    ],
  },
  { type: "divider", key: "divider-distributions" },
  {
    type: "collapse",
    name: "Products",
    key: "Products",
    icon: <Icon fontSize="medium">auto_stories</Icon>,
    collapse: [
      {
        name: "product",
        key: "product",
        route: "/product",
        component: <Products />,
      },
      {
        name: "products-settings",
        key: "products-settings",
        route: "/products-settings",
        component: <ProductsSettings />,
      },
    ],
  },
  { type: "divider", key: "divider-distributions" },
  {
    type: "collapse",
    name: "Categories",
    key: "Categories",
    icon: <Icon fontSize="medium">vaccines</Icon>,
    collapse: [
      {
        name: "pharmacy-categories",
        key: "pharmacy-categories",
        route: "/pharmacy-categories",
        component: <PharmacyCategories />,
      },
      {
        name: "pharmacy-subcategories",
        key: "pharmacy-subcategories",
        route: "/pharmacy-subcategories",
        component: <PharmacySubCategories />,
      },
      {
        name: "library-categories",
        key: "library-categories",
        route: "/library-categories",
        component: <LibraryCategories />,
      },
      {
        name: "tags",
        key: "tags",
        route: "/tags",
        component: <Tags />,
      },
      {
        name: "book-types",
        key: "book-types",
        route: "/book-types",
        component: <BookTypes />,
      },
    ],
  },
  { type: "divider", key: "divider-distributions" },
  {
    type: "collapse",
    name: "book-store",
    key: "book-store",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "buying",
        key: "buying",
        route: "/book-store/buying",
        component: <Buying />,
      },
      {
        table: "stores",
        name: "stores",
        key: "stores",
        route: "/book-store/stores",
        component: <Stores />,
      },
      {
        name: "proposal",
        key: "proposals",
        table: "proposals",
        route: "/book-store/proposals",
        component: <Proposals />,
      },
      {
        table: "distributions",
        name: "distribution",
        key: "distribution",
        route: "/book-store/distribution",
        component: <Distribution />,
      },
      {
        table: "libraries",
        name: "insert-library",
        key: "insert-library",
        route: "/book-store/insert-library",
        component: <InsertLibrary />,
      },
      {
        name: "setting",
        key: "setting",
        route: "/book-store/setting",
        component: <BookStoreSettings />,
      },
    ],
  },
  { type: "divider", key: "divider-inventories" },
  {
    type: "collapse",
    name: "book-store-inventory",
    key: "book-store-inventory",
    icon: <Icon fontSize="medium">paid</Icon>,
    collapse: [
      {
        table: "inventory",
        name: "inventory",
        key: "inventory",
        route: "/book-store-inventory/inventory",
        component: <Inventory />,
      },
      {
        table: "returns",
        name: "returns",
        key: "returns",
        route: "/book-store-inventory/returns",
        component: <Returns />,
      },
      {
        table: "gifts",
        name: "gifts",
        key: "gifts",
        route: "/book-store-inventory/gifts",
        component: <Gifts />,
      },
    ],
  },
  { type: "divider", key: "divider-reports" },
  {
    type: "collapse",
    name: "reports",
    key: "reports",
    icon: <Icon fontSize="medium">report</Icon>,
    collapse: [
      {
        name: "distribution-report",
        key: "distribution-report",
        route: "/reports/distribution-report",
        component: <DistributionReport />,
      },
      {
        name: "library-report",
        key: "library-report",
        route: "/reports/library-report",
        component: <LibraryReport />,
      },
      {
        name: "orderItems",
        key: "orderItems",
        route: "/orderItems",
        component: <OrderItems />,
      },
    ],
  },
];

export default routes;
