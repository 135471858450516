import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";

import * as yup from "yup";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import UpdateRecord from "helpers/compare";
import Page from "components/UI/Page";
import { Grid, Icon } from "@mui/material";
import PagesCount from "helpers/pages-count";

import {
  addComplexDataAction,
  fetchDataAction,
  deleteDataAction,
  reset,
} from "../../../store/features/coreSlice";
import dataVal from "./dataVal.json";

const BookTypes = () => {
  const dispatch = useDispatch();

  const { bookTypesMeta, bookTypes, resStatus, limit } = useSelector((state) => state.core);

  const [newArr, setNewArr] = useState([]);
  const [data, setData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [successSB, setSuccessSB] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const closeSuccessSB = () => setSuccessSB(false);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "arabic_title",
        id: "arabic_title",
        label: "arabic_title",
        size: 4,
        title: "Arabic Title",
      },
      {
        type: "TextField",
        name: "english_title",
        id: "english_title",
        label: "english_title",
        size: 4,
        title: "English Title",
      },
      {
        type: "TextField",
        name: "order",
        id: "order",
        label: "order",
        size: 4,
        title: "Sequence",
      },
    ]);
  };

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      english_title: values.english_title,
      arabic_title: name === "reset" ? null : values.arabic_title,
      order: values.order,
    });
  };

  const resetForm = () => {
    setInitialValues(dataVal.initialValues);
    orgData();
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const getData = () => {
    // get product data
    const url = `items/book_type?fields=id,arabic_title,english_title,order&meta=*&limit=${limit}&page=${pageNum}`;
    dispatch(
      fetchDataAction({
        url,
        varName: "bookTypes",
      })
    );
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+bookTypesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    bookTypes.forEach((bookType) => {
      item = {};
      keys = Object.keys(bookType);
      keys.forEach((element) => {
        item[element] = bookType[element];
      });

      item.title = bookType.arabic_title;
      item.sequence = bookType.order;

      item.action = (
        <>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onUpdateHandler("bookTypes", bookType)}
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#e9423f" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onDeleteHandler("bookTypes", bookType)}
          >
            <Icon fontSize="small">delete</Icon>
          </MDTypography>
        </>
      );
      Arr.push(item);
    });
    setNewArr(Arr);
  };

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      const url = `items/book_type?fields=id,arabic_title,english_title,order&meta=*&limit=${limit}&page=${num}`;
      dispatch(
        fetchDataAction({
          url,
          varName: "bookTypes",
        })
      );
    }
  };

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      id: values.id,
      file: null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "book_type",
      },
      getInfo: {
        url: `items/book_type?fields=id,arabic_title,english_title,order&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "bookTypes",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/book_type",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `items/book_type?fields=id,arabic_title,english_title,order&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "bookTypes",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    getData();
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    if (bookTypes && bookTypes.length !== 0) {
      prepareTableRows();
    }
  }, [bookTypes]);

  useEffect(() => {
    orgData();
  }, []);

  if (data.length === 0 || newArr === [] || !bookTypesMeta) {
    return null;
  }

  return (
    <Page>
      <Grid item xs={12}>
        <Main
          layout="simple"
          allRowsCount={+bookTypesMeta.filter_count}
          pageIndex={pageNum - 1}
          count={pageOptions}
          setPageNum={setPageNumber}
          setOpenDeleteModal={setOpenDeleteModal}
          openDeleteModal={openDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
          //   setOpen={setOpenModal}
          //   open={open}
          entriesPerPage
          name="bookTypes"
          setPayload={setPayload}
          data={data}
          initialValues={initialValues}
          title="Add Library Categories"
          columns={dataVal.columns}
          rows={newArr}
          onUpdateHandler={onUpdateHandler}
          onDeleteHandler={onDeleteHandler}
          yupObj={{
            arabic_title: yup.string("Enter your Title").required("Title is required").nullable(),
          }}
        />
        {renderSuccessSB}
      </Grid>
    </Page>
  );
};

export default BookTypes;
