import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl }) => {
  const dispatch = useDispatch();
  const {
    limit,
    filterproducts,
    filterpublisher,
    filterbuying,
    filterstores,
    filterjobtitles,
    filtertypes,
    filterusers,
  } = useSelector((state) => state.core);
  useSelector((state) => state.core);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);
  const [publisherId, setPublisherId] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [buyingId, setBuyingId] = useState(null);
  const [initialValues, setInitialValues] = useState(dataVal.initialValuesFilter);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: filterproducts,
        size: 3,
        title: "book",
      },
      {
        type: "Select",
        optionTitle: "buying_date",
        name: "buying_id",
        id: "buying_id",
        label: "buying_id",
        data: filterbuying,
        size: 3,
        title: "buying",
      },
      {
        type: "Select",
        optionTitle: "place_id",
        oterOptionTitle: "title",
        name: "store_id",
        id: "store_id",
        label: "store_id",
        data: filterstores,
        size: 3,
        title: "store",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: filterpublisher,
        size: 3,
        title: "publisher",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "type_id",
        id: "type_id",
        label: "type_id",
        data: filtertypes,
        size: 3,
        title: "type",
      },
      {
        type: "Date",
        name: "gift_date",
        id: "gift_date",
        label: "gift_date",
        size: 4,
        title: "giftDate",
      },
    ]);
  };

  useEffect(() => {
    if (
      filterproducts &&
      filterpublisher &&
      filterbuying &&
      filterstores &&
      filterjobtitles &&
      filtertypes &&
      filterusers
    ) {
      orgData();
    }
  }, [
    filterproducts,
    filterpublisher,
    filterbuying,
    filterstores,
    filterjobtitles,
    filtertypes,
    filterusers,
  ]);

  const generateUrl = (values) => {
    let str = baseUrl;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (typeof values[element] === "object") {
          if (element === "book_id")
            str += `&filter[store_id.buying_id.${element}.id]=${values[element].id}`;
          else if (element === "buying_id")
            str += `&filter[store_id.${element}.id]=${values[element].id}`;
          else str += `&filter[${element}.id]=${values[element].id}`;
        } else {
          str += `&filter[${element}]=${values[element]}`;
        }
      }
    });
    return str;
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "publisher") {
      setPublisherId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/products?fields=id,arabic_title,publisher&filter[publisher]=${values.id}`,
          varName: "filterproducts",
        })
      );
    }
    if (name === "book_id") {
      setBookId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/buying?fields=id,notes,buying_date&filter[book_id]=${values.id}`,
          varName: "filterbuying",
        })
      );
    } else if (name === "buying_id") {
      setBuyingId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/stores?fields=id,count,place_id.title,place_id.id,distributions.count,distributions.returns.count,distributions.id,gifts.count,gifts.id,buying_id.buying_date&filter[buying_id]=${values.id}`,
          varName: "filterstores",
        })
      );
    }
  };

  const onChangeHandler = (name, text) => {
    if (name === "publisher") {
      dispatch(
        fetchDataAction({
          url: `items/publisher?fields=id,arabic_title&filter[arabic_title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filterpublisher",
        })
      );
    }
    let urlVal;
    if (name === "book_id") {
      urlVal = `items/products?fields=id,arabic_title&filter[arabic_title][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (publisherId) urlVal += `&filter[publisher]=${publisherId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterproducts",
        })
      );
    }

    if (name === "buying_id") {
      urlVal = `items/buying?fields=id,notes,buying_date&filter[buying_date][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (bookId) urlVal += `&filter[book_id]=${bookId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterbuying",
        })
      );
    }

    if (name === "store_id") {
      urlVal = `items/stores?fields=id,count,place_id.title,place_id.id,distributions.count,distributions.returns.count,distributions.id,gifts.count,gifts.id,buying_id.buying_date&filter[place_id.title][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (buyingId) urlVal += `&filter[buying_id]=${buyingId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterstores",
        })
      );
    }

    if (name === "job_title_id")
      dispatch(
        fetchDataAction({
          url: `items/job_title?filter[title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filterjobtitles",
        })
      );

    if (name === "type_id")
      dispatch(
        fetchDataAction({
          url: `items/gifts_types?filter[title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filtertypes",
        })
      );

    if (name === "user_id")
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name&filter[first_name][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filterusers",
        })
      );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "users")
        dispatch(
          fetchDataAction({
            url: `${element}?fields=id,first_name&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      else if (element === "buying")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,buying_date,notes&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      else if (element === "stores")
        dispatch(
          fetchDataAction({
            url: `items/stores?fields=id,place_id.title,place_id.id,count,distributions.count,gifts.count,gifts.id,distributions.returns.count,distributions.id,buying_id.buying_date&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      else if (element === "job_title")
        dispatch(
          fetchDataAction({
            url: `items/${element}?limit=${limit}`,
            varName: `filterjobtitles`,
          })
        );
      else if (element === "gifts_types")
        dispatch(
          fetchDataAction({
            url: `items/${element}?limit=${limit}`,
            varName: `filtertypes`,
          })
        );
      else
        dispatch(
          fetchDataAction({
            url: `items/${element}?limit=${limit}`,
            varName: `filter${element}`,
          })
        );
    });
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "gifts",
      })
    );
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      name="returnFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeHandler}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
