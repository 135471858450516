import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import UpdateRecord from "helpers/compare";
import DataForm from "components/UI/form/DataForm";

import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { formusers, formproducts, formaccountsItems, limit, accountsImgList } = useSelector(
    (state) => state.core
  );

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [userId, setUserId] = useState(null);

  const [filesData, setFilesData] = useState(undefined);
  const [isSalary, setIsSalary] = useState(false);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "title",
        name: "type",
        id: "type",
        label: "type",
        data: dataVal.types,
        size: 3,
        title: "type",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "item_id",
        id: "item_id",
        label: "item_id",
        data: isSalary ? formaccountsItems : formaccountsItems.filter((I) => I.id !== 1),
        disable: isSalary,
        size: 3,
        title: "item",
      },
      {
        type: "TextField",
        disable: !!initialValues.id,
        name: "debt",
        id: "debt",
        label: "debt",
        size: 3,
        title: "debt",
      },
      {
        type: "Date",
        name: "due_date",
        id: "due_date",
        label: "due_date",
        disable: isSalary,
        size: 3,
        title: "dueDate",
      },
      {
        type: "Date",
        name: "date",
        id: "date",
        label: "date",
        size: 3,
        title: "accountDate",
      },

      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: formproducts,
        size: 3,
        title: "book",
      },
      {
        type: "TextArea",
        name: "description",
        id: "description",
        label: "description",
        maxRows: 4,
        minRows: 4,
        size: 6,
        title: "description",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 6,
        title: "notes",
      },
      {
        type: "MImg",
        name: "files",
        id: "files",
        label: "files",
        size: 12,
        title: "image",
      },
    ]);
  };

  const resetForm = () => {
    setFilesData(undefined);
    setUserId(null);
    setIsSalary(false);
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "item_id") {
      if (values.id === 1) {
        setIsSalary(true);
      } else setIsSalary(false);
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "users") {
        dispatch(
          fetchDataAction({
            url: `${element}?fields=id,first_name&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      } else if (element === "accountsItems") {
        dispatch(
          fetchDataAction({
            url: `items/accounts_items?fields=id,title&limit=${limit}`,
            varName: `formaccountsItems`,
          })
        );
      } else if (element === "products") {
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,arabic_title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      }
    });
  };

  const onChangeSelectInputs = (name, text) => {
    // setInitialValuesFilter(initFilter);
    if (name === "item_id") {
      dispatch(
        fetchDataAction({
          url: `items/accounts_items?fields=id,title&filter[title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: `formaccountsItems`,
        })
      );
    } else if (name === "book_id") {
      dispatch(
        fetchDataAction({
          url: `items/products?fields=id,arabic_title&filter[arabic_title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "formproducts",
        })
      );
    } else if (name === "user_id") {
      dispatch(
        fetchDataAction({
          url: `users?fields=id,first_name&filter[first_name][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "formusers",
        })
      );
    }
  };

  const onUpdateHandler = (name, values, copy) => {
    if (!copy) {
      dispatch(
        fetchDataAction({
          url: `items/all_files?fields=id,collection,file_id.data.full_url,file_id.filesize,file_id.id,item_id,file_id.type,file_id.filename_download&filter[collection]=accounts&filter[item_id]=${values.id}`,
          varName: "accountsImgList",
        })
      );
    }
    setUserId(values.user_id);
    if (values.item_id?.id === 1) setIsSalary(true);
    onChangeSelectInputs("user_id", values.user_id ? values.user_id.first_name : " ");
    onChangeSelectInputs("book_id", values.book_id ? values.book_id.arabic_title : " ");
    onChangeSelectInputs("item_id", values.item_id ? values.item_id.title : " ");
    if (values.book_id) setFieldSelectedValue("book_id", values.book_id);

    setInitialValues({
      id: copy ? null : values.id,
      debt: values.debt,
      item_id: values.item_id,
      date: values.date,
      due_date: values.due_date,
      book_id: values.book_id,
      user_id: values.user_id,
      description: values.description,
      notes: values.notes,
      type: dataVal.types.filter((item) => item.value === values.type)[0],
    });
  };

  const setPayload = ({ files, ...values }) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;
    if (initialValues.type?.value !== values.type?.value || !values.id)
      payloadData.type = values.type.value;

    delete lastData.type;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      imgFieldName: "file",
      id: values.id,
      file: files,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "accounts",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "accounts",
      },
    };
    setInitialValues(values);
    dispatch(addComplexDataAction(req));
    resetForm();
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(
        reset({
          alias: "form",
          data: ["users", "products", "accountsItems"],
        })
      );
    };
  }, []);

  useEffect(() => {
    setFilesData(accountsImgList);
  }, [accountsImgList]);

  useEffect(() => {
    if (formusers && formproducts && formaccountsItems) orgData();
  }, [formusers, formproducts, formaccountsItems]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      message={userId && `${t("debtUserName")} : ${userId.first_name}`}
      yupObj={{
        type: yup.mixed("required").required("required").nullable(),
        item_id: yup.mixed("required").required("required").nullable(),
        date: yup.string("required").required("required").nullable(),
        due_date: yup.string("required").required("required").nullable(),
        debt: yup
          .number("must be number")
          .required("required")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
      }}
      resetData={resetForm}
      name="accounts"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      imgList={filesData}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
