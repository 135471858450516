import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import UpdateRecord from "helpers/compare";
import DataForm from "components/UI/form/DataForm";
import PropTypes from "prop-types";

import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import dataVal from "../dataVal.json";

const PageForm = ({ resetData, updateHandlerVal, pageNum, baseUrl }) => {
  const dispatch = useDispatch();

  const { couponProducts, brands, categories, tags, limit } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "code",
        id: "code",
        label: "code",
        size: 3,
        title: "Code",
      },
      {
        type: "TextField",
        name: "user_max_num",
        id: "user_max_num",
        label: "user_max_num",
        size: 3,
        title: "user max num",
      },
      {
        type: "TextField",
        name: "persentage",
        id: "persentage",
        label: "persentage",
        size: 3,
        title: "persentage",
      },
      {
        type: "TextField",
        name: "value",
        id: "value",
        label: "value",
        size: 3,
        title: "value",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "status",
        id: "status",
        label: "status",
        data: dataVal.statuses,
        size: 6,
        title: "Product Status",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "product_id",
        id: "product_id",
        label: "product_id",
        data: couponProducts,
        size: 4,
        title: "products",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "category_id",
        id: "category_id",
        label: "category_id",
        data: categories,
        size: 6,
        title: "categories",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "tag_id",
        id: "tag_id",
        label: "tag_id",
        data: tags,
        size: 6,
        title: "tags",
      },
      {
        type: "Date",
        name: "start_date",
        id: "start_date",
        label: "start_date",
        size: 4,
        title: "start date",
      },
      {
        type: "Date",
        name: "exp_date",
        id: "exp_date",
        label: "exp_date",
        size: 4,
        title: "exp date",
      },
      {
        type: "chk",
        name: "free_shipping",
        id: "free_shipping",
        label: "free_shipping",
        size: 4,
        title: "free shipping",
      },
    ]);
  };

  const resetForm = () => {
    setInitialValues(dataVal.initialValues);
    orgData();
    resetData();
  };

  const setFieldSelectedValue = (name, values, isUpdate = false, updateCode) => {
    console.log(name, values, isUpdate, updateCode);
  };

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      status: dataVal.statuses.filter((status) => status.value === values.status)[0],
      code: values.code,
      product_id: values.product_id,
      category_id: values.category_id,
      tag_id: values.tag_id,
      user_max_num: values.user_max_num,
      start_date: values.start_date,
      exp_date: values.exp_date,
      persentage: values.persentage,
      value: values.value,
      free_shipping: values.free_shipping,
    });
  };

  const setPayload = (values) => {
    const payloadData = {};

    const lastData = values.id ? UpdateRecord(values, initialValues) : values;
    console.log(initialValues, values, lastData);

    if (initialValues.status?.value !== values.status?.value)
      payloadData.status = values.status.value;

    delete lastData.status;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });
    const req = {
      id: values.id,
      file: typeof values.image === "object" ? values.image : null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "coupons",
      },
      related: null,
      getInfo: {
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}&filter[status][neq]=deleted`,
        varName: "coupons",
      },
    };
    dispatch(addComplexDataAction(req));
    resetForm();
  };

  const onChangeHandler = (name, val) => {
    console.log("data", name, val);
  };

  const getData = () => {
    // get data for selects in form
    dispatch(
      fetchDataAction({
        url: "items/tags?fields=id,arabic_title&sort=arabic_title",
        varName: "tags",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/brands?fields=id,english_title,arabic_title",
        varName: "brands",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/categories?fields=id,english_title,arabic_title,parent_category.id,child_categories.id,child_categories.parent_category&sort=arabic_title",
        varName: "categories",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/products?fields=id,arabic_title",
        varName: "couponProducts",
      })
    );
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(reset(null));
    };
  }, []);

  useEffect(() => {
    if (
      couponProducts !== undefined &&
      categories !== undefined &&
      tags !== undefined &&
      brands !== undefined
    ) {
      orgData();
    }
  }, [tags, brands, categories, couponProducts]);

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value);
    }
  }, [updateHandlerVal]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        code: yup.string("Enter your Title").required("Title is required").nullable(),
        status: yup.mixed("Enter your status").required("Status is required").nullable(),
      }}
      resetData={resetForm}
      name="coupons"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeHandler}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};
PageForm.propTypes = {
  resetData: PropTypes.func.isRequired,
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
