import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../dataVal.json";

const FilterForm = ({ resetData, baseUrl, setUrl }) => {
  const dispatch = useDispatch();
  const { aramixCountries, limit, orderProducts, orderPublishers } = useSelector(
    (state) => state.core
  );

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.filterInitialValues);

  const orgData = () => {
    setData([
      {
        type: "MSelect",
        optionTitle: "title",
        name: "order_status",
        id: "order_status",
        label: "order_status",
        data: dataVal.orderStatus,
        size: 4,
        title: "order Status",
      },
      {
        type: "MSelect",
        optionTitle: "arabic_title",
        name: "product_id",
        id: "product_id",
        label: "product_id",
        data: orderProducts,
        size: 4,
        title: "products",
      },
      {
        type: "MSelect",
        optionTitle: "arabic_title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: orderPublishers,
        size: 4,
        title: "publishers",
      },
      {
        type: "MSelect",
        optionTitle: "title",
        name: "payment_status",
        id: "payment_status",
        label: "payment_status",
        data: dataVal.paymentStatus,
        size: 4,
        title: "shipping Status",
      },
      {
        type: "Select",
        optionTitle: "Name",
        name: "country_name",
        id: "country_name",
        label: "country_name",
        data: aramixCountries,
        size: 4,
        title: "country",
      },
      {
        type: "Date",
        name: "date_from",
        id: "date_from",
        label: "date_from",
        size: 6,
        title: "from",
      },
      {
        type: "Date",
        name: "date_to",
        id: "date_to",
        label: "date_to",
        size: 6,
        title: "to",
      },
    ]);
  };

  useEffect(() => {
    if (
      aramixCountries !== undefined &&
      orderProducts !== undefined &&
      orderPublishers !== undefined
    ) {
      orgData();
    }
  }, [aramixCountries, orderProducts, orderPublishers]);

  const resetForm = () => {
    setInitialValues(dataVal.filterInitialValues);
    orgData();
    resetData();
  };

  const getData = () => {
    // get order data
    dispatch(
      fetchDataAction({
        url: `items/products?fields=id,arabic_title`,
        varName: "orderProducts",
      })
    );
    dispatch(
      fetchDataAction({
        url: `items/publisher?fields=id,arabic_title`,
        varName: "orderPublishers",
      })
    );
    dispatch(
      fetchDataAction({
        url: `custom/getAramexCountries`,
        varName: "aramixCountries",
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const setPayload = (values) => {
    let str = `${baseUrl}`;
    // let countryFilterVal;
    let otherFilterVal;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element]) {
        if (element === "country_name") {
          // countryFilterVal = null;
          // values[element].forEach((country, i) => {
          //   if (values[element].length === 1) countryFilterVal = `${country.Name}`;
          //   else if (i === 0) {
          //     countryFilterVal = `${country.Name},`;
          //   } else if (i === values[element].length - 1) {
          //     countryFilterVal += `${country.Name}`;
          //   } else {
          //     countryFilterVal += `${country.Name},`;
          //   }
          // });
          // str += `&filter[address_id.${element}][in]=${countryFilterVal}`;
          str += `&filter[address_id.${element}][in]=${values[element].Name}`;
        } else if (element === "product_id") {
          otherFilterVal = null;
          values[element].forEach((oVal, i) => {
            if (values[element].length === 1) otherFilterVal = `${oVal.id}`;
            else if (i === 0) {
              otherFilterVal = `${oVal.id},`;
            } else if (i === values[element].length - 1) {
              otherFilterVal += `${oVal.id}`;
            } else {
              otherFilterVal += `${oVal.id},`;
            }
          });
          str += `&filter[order_items.product_id.id][in]=${otherFilterVal}`;
        } else if (element === "publisher_id") {
          otherFilterVal = null;
          values[element].forEach((oVal, i) => {
            if (values[element].length === 1) otherFilterVal = `${oVal.id}`;
            else if (i === 0) {
              otherFilterVal = `${oVal.id},`;
            } else if (i === values[element].length - 1) {
              otherFilterVal += `${oVal.id}`;
            } else {
              otherFilterVal += `${oVal.id},`;
            }
          });
          str += `&filter[order_items.product_id.publisher.id][in]=${otherFilterVal}`;
        } else if (values[element].length > 0) {
          otherFilterVal = null;
          values[element].forEach((oVal, i) => {
            if (values[element].length === 1) otherFilterVal = `${oVal.value}`;
            else if (i === 0) {
              otherFilterVal = `${oVal.value},`;
            } else if (i === values[element].length - 1) {
              otherFilterVal += `${oVal.value}`;
            } else {
              otherFilterVal += `${oVal.value},`;
            }
          });

          str += `&filter[${element}][in]=${otherFilterVal}`;
          // str += `&filter[${element}]=${values[element].value}`;
        }
      }
    });
    if (values.date_from && values.date_to) {
      str += `&filter[date][between]=${values.date_from},${values.date_to}`;
    }
    setUrl(`${str}`);
    dispatch(
      fetchDataAction({
        url: `${str}&meta=*&limit=${limit}&page=1`,
        varName: "orders",
      })
    );
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      resetData={resetForm}
      name="ordersFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={() => {}}
      onChange={() => {}}
    />
  );
};
FilterForm.propTypes = {
  resetData: PropTypes.func.isRequired,
  setUrl: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

export default FilterForm;
