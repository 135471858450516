import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Became Possible components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import trakibLogo from "assets/images/logos/trakib-logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginAction } from "store/features/authSlice";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import PageLayout from "examples/LayoutContainers/PageLayout";
import AppBar from "components/UI/Header/AppBar";

function Basic() {
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: Yup.object({
      email: Yup.string().email("wrong email").required("required field"),
      password: Yup.string().required("required field"),
    }),

    onSubmit: (values) => {
      dispatch(loginAction(values));
    },
  });

  return (
    <PageLayout>
      <AppBar />
      <MDTypography
        variant="h3"
        fontWeight="medium"
        color="primary"
        align="center"
        sx={{ position: "absolute", top: 100, right: "48%" }}
      >
        Sign in
      </MDTypography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <MDBox component="img" src={trakibLogo} alt="Trakib" width="90%" height="100%" />
        </Grid>
        <Grid item xs={12} md={4}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  id="email"
                  name="email"
                  form="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </MDBox>
              {formik.touched.email && formik.errors.email ? (
                <div
                  style={{
                    position: "absolute",
                    margin: "-20px 75px",
                    color: "rgb(216, 23, 23)",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.email}
                </div>
              ) : null}
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  id="password"
                  name="password"
                  form="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
              </MDBox>
              {formik.touched.password && formik.errors.password ? (
                <div
                  style={{
                    position: "absolute",
                    margin: "-20px 75px",
                    color: "rgb(216, 23, 23)",
                    fontSize: "12px",
                  }}
                >
                  {formik.errors.password}
                </div>
              ) : null}
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-up/cover"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Basic;
