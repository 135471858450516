import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";

import {
  fetchDataAction,
  deleteDataAction,
  reset,
  addComplexDataAction,
  setAlert,
} from "store/features/coreSlice";
import { Icon } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import { useTranslation } from "react-i18next";
import PagesCount from "helpers/pages-count";

const GiftItems = () => {
  const { t } = useTranslation();

  const columns = [
    { Header: "title", accessor: "title", width: "45%", align: "center" },
    { Header: "gifts", accessor: "relatedGifts", width: "45%", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [initialValues, setInitialValues] = useState({
    id: null,
    title: "",
  });
  const [newArr, setNewArr] = useState([]);
  const [number, setNumber] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const { giftsType, giftsTypeMeta, resStatus, limit } = useSelector((state) => state.core);
  const dispatch = useDispatch();
  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      title: values.title,
    });
    setOpen(true);
  };
  const onDeleteHandler = (name, values) => {
    if (values.gifts.length === 0) {
      setOpenDeleteModal(true);
      setDeleteItemId(values.id);
    } else {
      dispatch(
        setAlert({
          status: "error",
          content: t("haveStores"),
        })
      );
      // alert("have stores ");
    }
  };

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    const payload = {
      url: `items/gifts_types?fields=*,gifts.id&meta=*&limit=${limit}&page=${number}`,
      varName: "giftsType",
    };
    dispatch(fetchDataAction(payload));
  }, [limit]);

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `items/gifts_types?fields=*,gifts.id&meta=*&limit=${limit}&page=${num}`,
          varName: "giftsType",
        })
      );
    }
  };
  const resetForm = () => {
    setInitialValues({
      id: null,
      title: "",
    });
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    let item = {};
    const Arr = [];

    if (giftsType && giftsType.length !== 0) {
      const d = [];
      for (let i = 0; i < PagesCount(+giftsTypeMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      giftsType.forEach((itemVal) => {
        item = {};
        item.title = itemVal.title;
        item.id = itemVal.id;
        item.relatedGifts = itemVal.gifts.length;
        item.action = (
          <>
            <MDTypography
              style={{ padding: 20, color: "#3b94ee" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onUpdateHandler("giftsType", itemVal)}
            >
              <Icon fontSize="small">edit</Icon>
            </MDTypography>
            <MDTypography
              style={{ padding: 20, color: "#e9423f" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onDeleteHandler("giftsType", itemVal)}
            >
              <Icon fontSize="small">delete</Icon>
            </MDTypography>
          </>
        );
        Arr.push(item);
      });
      setNewArr(Arr);
    }
  }, [giftsType]);

  const data = [
    {
      type: "TextField",
      name: "title",
      id: "title",
      label: "title",
      size: 12,
      title: t("giftsType"),
    },
  ];

  const setPayload = (values) => {
    const req = {
      id: values.id,
      origin: {
        data: values,
        table: "gifts_types",
      },
      getInfo: {
        url: `items/gifts_types?fields=*,gifts.id&meta=*&limit=${limit}&page=${number}`,
        varName: "giftsType",
      },
    };
    setInitialValues(values);
    dispatch(addComplexDataAction(req));
  };

  const setOpenModal = () => {
    setInitialValues({
      id: null,
      title: "",
    });
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: `items/gifts_types`,
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      varName: "giftsType",
      getInfo: {
        url: `items/gifts_types?fields=*,gifts.id&meta=*&limit=${limit}&page=${number}`,
        varName: "giftsType",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  if (data.length === 0 || newArr === [] || !giftsTypeMeta) {
    return null;
  }
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={t("systemDashboard")}
      content={t("successfulProcess")}
      dateTime={t("recently")}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  return (
    <>
      <Main
        count={pageOptions}
        layout="simple"
        pageIndex={number - 1}
        allRowsCount={+giftsTypeMeta.filter_count}
        setPageNum={setPageNumber}
        openDeleteModal={openDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        setOpenDeleteModal={setOpenDeleteModal}
        setOpen={setOpenModal}
        open={open}
        name="giftsType"
        setPayload={setPayload}
        data={data}
        initialValues={initialValues}
        title={t("addGiftType")}
        columns={columns}
        rows={newArr}
        report={false}
        onUpdateHandler={onUpdateHandler}
        onDeleteHandler={onDeleteHandler}
        yupObj={{
          title: yup.string("Enter your Title").required(t("TitleIsRequired")).nullable(),
        }}
      />
      {renderSuccessSB}
    </>
  );
};

export default GiftItems;
