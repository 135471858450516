import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "components/UI/Page";
import { Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction } from "store/features/coreSlice";
import DataTable from "components/UI/Table/DataTable";
import PagesCount from "helpers/pages-count";
import dataVal from "./dataVal.json";

const NotifyMe = () => {
  const baseUrl = `items/notify_me?fields=id,customer_id.first_name,customer_id.last_name,product_id.arabic_title`;
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { notifyMesMeta, notifyMes, limit } = useSelector((state) => state.core);
  const [newArr, setNewArr] = useState([]);
  const [number, setNumber] = useState(1);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+notifyMesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (notifyMes && notifyMes.length !== 0) {
      notifyMes.forEach((notifyMe) => {
        item = {};
        keys = Object.keys(notifyMe);
        keys.forEach((element) => {
          item[element] = notifyMe[element];
        });
        item.product = notifyMe.product_id.arabic_title;
        item.name = notifyMe.customer_id.first_name;
        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${baseUrl}&meta=*&limit=${limit}&page=${num}`,
          varName: "notifyMes",
        })
      );
    }
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${baseUrl}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "notifyMes",
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchDataAction({
        url: `${baseUrl}&meta=*&limit=${limit}&page=${number}`,
        varName: "notifyMes",
      })
    );
  }, [limit]);

  useEffect(() => {
    // if (!notifyMes) {
    //   dispatch(
    //     fetchDataAction({
    //       url: `${baseUrl}&meta=*&limit=${limit}&page=1`,
    //       varName: "notifyMes",
    //     })
    //   );
    // }
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (notifyMes && route) {
      prepareTableRows();
    }
  }, [notifyMes, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        {/* <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal> */}
        {/* <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        /> */}
        {/* <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} /> */}

        {/* {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )} */}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={notifyMesMeta ? +notifyMesMeta.filter_count : 0}
        />
        {/* <input type="file" name="upload" id="upload" onChange={readUploadFile} /> */}
      </Card>
    </Page>
  );
};

export default NotifyMe;
