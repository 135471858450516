import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Icon, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction, addComplexDataAction } from "store/features/coreSlice";
import DataTable from "components/UI/Table/DataTable";
import PagesCount from "helpers/pages-count";
import dataVal from "./dataVal.json";

const Reviews = () => {
  const baseUrl = `items/review?fields=id,status,customer_id.first_name,customer_id.last_name,product_id.arabic_title,rating,review,title,order_id,order_id.id,date`;
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { reviewsMeta, reviews, limit } = useSelector((state) => state.core);
  const [newArr, setNewArr] = useState([]);
  const [number, setNumber] = useState(1);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);

  const changeStatus = (status, review) => {
    const req = {
      id: review.id,
      origin: {
        data: { status },
        table: "review",
      },
      getInfo: {
        url: `${baseUrl}&meta=*&limit=${limit}&page=${number}`,
        varName: "reviews",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+reviewsMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (reviews && reviews.length !== 0) {
      reviews.forEach((review) => {
        item = {};
        keys = Object.keys(review);
        keys.forEach((element) => {
          item[element] = review[element];
        });
        item.product = review.product_id.arabic_title;
        item.name = review.customer_id.first_name;
        item.order = review.order_id.id;
        item.action =
          route.delete && review.status === "draft" ? (
            <MDTypography
              className="button-group"
              style={{ padding: 20, color: "#e9423f" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => changeStatus("published", review)}
            >
              <Icon fontSize="small">public_off</Icon>
            </MDTypography>
          ) : (
            route.delete && (
              <MDTypography
                style={{ padding: 20, color: "green" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => changeStatus("draft", review)}
              >
                <Icon fontSize="small">public</Icon>
              </MDTypography>
            )
          );

        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${baseUrl}&meta=*&limit=${limit}&page=${num}`,
          varName: "reviews",
        })
      );
    }
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${baseUrl}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "reviews",
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchDataAction({
        url: `${baseUrl}&meta=*&limit=${limit}&page=${number}`,
        varName: "reviews",
      })
    );
  }, [limit]);

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (reviews && route) {
      prepareTableRows();
    }
  }, [reviews, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        {/* <DataModal
          handleClose={() => {
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal> */}
        {/* <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        /> */}
        {/* <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} /> */}

        {/* {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )} */}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={reviewsMeta ? +reviewsMeta.filter_count : 0}
        />
        {/* <input type="file" name="upload" id="upload" onChange={readUploadFile} /> */}
      </Card>
    </Page>
  );
};

export default Reviews;
