import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Icon, Grid, Card } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction, deleteDataAction } from "store/features/coreSlice";
import Avatar from "components/UI/Table/avatarWithOutImg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import DefaultInfoCard from "components/UI/Cards/InfoCards/DefaultInfoCard";
import PageForm from "./components/PageForm";
import FilterForm from "./components/FilterForm";
import dataVal from "./dataVal.json";

const Accounts = () => {
  const baseUrl = `items/accounts?fields=*,item_id.id,item_id.title,book_id.id,book_id.arabic_title,buying_id.id,buying_id.buying_date,buying_id.discount_percentage,buying_id.cost,buying_id.count,buying_id.distribution_cost,buying_id.buying_date,buying_id.stores.gifts.count,user_id.id,user_id.first_name`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { accountsMeta, accounts, limit, simbleLayout, accountsTotal } = useSelector(
    (state) => state.core
  );
  const [url, setUrl] = useState(baseUrl);
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);
  const [info, setInfo] = useState([]);

  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const resetForm = () => {
    // setFormContainer(<h1>wait ...</h1>);
  };

  useEffect(() => {
    if (accountsTotal)
      setInfo([
        {
          icon: "credit_score",
          title: t("rDebt"),
          description: "",
          value: accountsTotal.rDebt?.toFixed(2),
        },
        {
          icon: "payments",
          title: t("sDebt"),
          description: "",
          value: accountsTotal.sDebt?.toFixed(2),
        },
      ]);
  }, [accountsTotal]);

  const setOpenModal = (container) => {
    resetForm();
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(
      <PageForm resetData={resetForm} updateHandlerVal={null} pageNum={number} url={url} />
    );

  const onDeleteHandler = (values) => {
    // if (values.inventories.length === 0 && values.returns.length === 0) {
    console.log(values);
    setOpenDeleteModal(true);
    setDeleteItemId(values);
    // } else {
    //   alert("has inventories and gifts");
    // }
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+accountsMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (accounts && accounts.length !== 0) {
      accounts.forEach((account) => {
        item = {};
        keys = Object.keys(account);
        keys.forEach((element) => {
          item[element] = account[element];
        });
        item.bookTitle = account.book_id?.arabic_title;
        item.userName = account.user_id?.first_name;
        item.title = (
          <Avatar
            dir="ltr"
            name={account.item_id?.title || ""}
            email={account.type === "S" ? "صرف" : "إيراد"}
          />
        );
        if (route.update) {
          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        resetData={resetForm}
                        updateHandlerVal={{ name: "accounts", value: account }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler(account)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#3b00ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        resetData={resetForm}
                        updateHandlerVal={{ name: "accounts", value: account, isCopy: true }}
                        pageNum={number}
                        url={url}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">content_copy</Icon>
              </MDTypography>
            </>
          );
        }
        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "accounts",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/accounts",
      data: {
        id: deleteItemId.id,
        status: "deleted",
      },

      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${number}`,
        varName: "accounts",
      },
    };
    if (deleteItemId.item_id.id === 5)
      payload.related = [
        {
          url: "items/inventory",
          data: {
            id: deleteItemId.inventory_id?.id,
            status: "deleted",
          },
        },
      ];
    dispatch(deleteDataAction(payload));

    setOpenDeleteModal(false);
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "accounts",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (accounts && route) {
      prepareTableRows();
    }
  }, [accounts, route]);

  if (newArr === []) {
    return null;
  }

  return (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        {" "}
        <DataModal
          handleClose={() => {
            resetForm();
            setOpen(false);
          }}
          open={open}
        >
          {formContainer}
        </DataModal>
        <DeleteModal
          handleClose={handleDeleteModalClose}
          handleConfirmDelete={handleConfirmDelete}
          openDeleteModal={openDeleteModal}
        />
        <FilterForm pageNum={number} baseUrl={baseUrl} setUrl={setUrl} setNumber={setNumber} />
        {info.length > 0 && (
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ m: 2, p: 3 }}
          >
            {info.map((dataItem) => (
              <MDBox
                width="100%"
                mr={{ xs: 0, sm: 3 }}
                mb={{ xs: 3, sm: 0 }}
                key={`${dataItem.title}+${dataItem.value}`}
              >
                <DefaultInfoCard
                  icon={dataItem.icon}
                  title={dataItem.title}
                  description={dataItem.description}
                  value={dataItem.value}
                />
              </MDBox>
            ))}
          </Grid>
        )}
        {simbleLayout && route?.create && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        <DataTable
          table={{ columns: dataVal.columns, rows: newArr }}
          item={false}
          pageOptions={pageOptions}
          isSorted
          isPagination
          entriesPerPage
          showTotalEntries
          noEndBorder
          search
          setPageNum={setPageNumber}
          setSortByHandeller={setSortByHandeller}
          pageIndex={number - 1}
          allRowsCount={accountsMeta ? +accountsMeta.filter_count : 0}
        />
      </Card>
    </Page>
  );
};

export default Accounts;
