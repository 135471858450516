import { useState, useEffect, useMemo } from "react";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Trakib System components
import MDBox from "components/MDBox";

// Trakib System examples
import Sidenav from "examples/Sidenav";
import Configurator from "components/UI/Configurator";

// Trakib System themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Trakib System Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Trakib System routes
import routes from "routes";

// Trakib System contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SignIn from "layouts/authentication/sign-in";
import { logoutAction } from "store/features/authSlice";
import { fetchDataAction, setPagesArr, setAlert } from "store/features/coreSlice";
import Analytics from "layouts/dashboards/analytics";
import MDAvatar from "components/MDAvatar";
import ProfileOverview from "layouts/sys-users/profile";

import { signInUrl } from "constants";

import i18n from "./i18n";

export default function App() {
  // new
  const i18Dir = useTranslation();
  // document.body.dir = i18n.dir();
  document.body.setAttribute("dir", i18n.dir());
  const dispatchItem = useDispatch();
  const [routeItems, setRouteItems] = useState([]);

  const { message, userInfo } = useSelector((state) => state.auth);
  const coreRed = useSelector((state) => state.core);

  const authRoutes = [
    {
      type: "collapse",
      name: "Sign In",
      key: "sign-in",
      icon: <Icon fontSize="small">login</Icon>,
      route: signInUrl,
      component: <SignIn />,
    },
  ];

  useEffect(() => {
    if (message)
      dispatchItem(
        setAlert({
          status: "error",
          content: i18Dir.t(message),
        })
      );
    if (
      coreRed.message === "Request failed with status code 401" ||
      message === "Item not found" ||
      coreRed.message === "Request failed with status code 403"
    ) {
      dispatchItem(logoutAction());
      window.location.reload();
    }
  }, [message, coreRed.message]);

  const createRoutes = () => {
    const array = [
      {
        type: "collapse",
        name: `${userInfo.first_name}`,
        key: "brooklyn-alice",
        icon: <MDAvatar src={userInfo.userAvatar} alt={`${userInfo.first_name}`} size="sm" />,
        collapse: [
          {
            name: i18Dir.t("profile"),
            key: "profile",
            route: "/pages/profile/profile",
            component: <ProfileOverview />,
          },
        ],
      },
      { type: "divider", key: "divider-0" },
      {
        type: "collapse",
        name: i18Dir.t("Dashboards"),
        key: "dashboards",
        icon: <Icon fontSize="medium">dashboard</Icon>,
        collapse: [
          {
            name: i18Dir.t("charts"),
            key: "analytics",
            route: "/dashboards/analytics",
            component: <Analytics />,
          },
        ],
      },
    ];
    const pagesArr = [];
    routes.forEach((element) => {
      const array1 = [];
      if (element.type !== "collapse") {
        if (element.type === "title") {
          element.title = i18Dir.t(element.title);
          array.push({ ...element, title: i18Dir.t(element.title) });
        }
      } else if (element.noCollapse === true) {
        userInfo.routes.forEach((element1) => {
          if (element.key === element1.route_name) {
            array.push({ ...element, name: i18Dir.t(element.name) });
            pagesArr.push({ id: element.key, title: i18Dir.t(element.name) });
          }
        });
      } else if (element.type === "collapse") {
        element.collapse.forEach((element2, index) => {
          userInfo.routes.forEach((element1) => {
            if (element2.key === element1.route_name) {
              array1.push({ ...element2, name: i18Dir.t(element2.name) });
              pagesArr.push({ id: element2.key, title: i18Dir.t(element2.name) });
            }
          });
          if (index === element.collapse.length - 1 && array1.length > 0) {
            array.push({
              ...element,
              name: i18Dir.t(element.name),
              collapse: array1,
            });
          }
        });
      }
    });
    dispatchItem(setPagesArr(pagesArr));
    setRouteItems(array);
  };

  useEffect(() => {
    if (userInfo) {
      createRoutes();
      const payload = {
        url: `items/admins/${userInfo.admin_id}?fields=*,logo.data.full_url,city_id.arabic_title,city_id.country_id.arabic_title`,
        varName: "settingsItem",
      };
      dispatchItem(fetchDataAction(payload));
      const payload1 = {
        url: `items/sys_messages`,
        varName: "messages",
      };
      dispatchItem(fetchDataAction(payload1));
    } else {
      setRouteItems(authRoutes);
    }
  }, [userInfo]);

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    // direction,
    layout,
    openConfigurator,
    sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => {
    setOpenConfigurator(dispatch, {
      value: !openConfigurator,
      table: userInfo.routes.filter(
        (x) => `${x.route_name}` === pathname.split("/")[pathname.split("/").length - 1]
      )[0]?.route_name,
    });
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  // useEffect(() => {
  //   document.body.setAttribute("dir", direction);
  // }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );
  if (routeItems?.length === 0) return null;
  return i18n.dir() === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            {coreRed.settingsItem && (
              <Sidenav
                color={sidenavColor}
                brand={coreRed.settingsItem.logo?.data.full_url}
                brandName={coreRed.settingsItem.title || "company title"}
                routes={routeItems}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            )}
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routeItems)}
          <Route
            path="*"
            element={<Navigate to={userInfo ? "/dashboards/analytics" : signInUrl} />}
          />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && userInfo && (
        <>
          {coreRed.settingsItem && (
            <Sidenav
              color={sidenavColor}
              brand={coreRed.settingsItem.logo.data.full_url}
              brandName={coreRed.settingsItem.title || "company title"}
              routes={routeItems}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {/* {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboards/analytics" />} /> */}
        {getRoutes(routeItems)}
        <Route
          path="*"
          element={<Navigate to={userInfo ? "/dashboards/analytics" : signInUrl} />}
        />
      </Routes>
    </ThemeProvider>
  );
}
