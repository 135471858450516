import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Avatar from "components/UI/Table/avatar";
import AvatarWithOutImg from "components/UI/Table/avatarWithOutImg";
import DataModal from "components/UI/DataModal";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import PagesCount from "helpers/pages-count";

import { fetchDataAction, deleteDataAction, reset } from "store/features/coreSlice";
import ProductAttribute from "../ProductAttribute";
import ProductMedias from "../ProductMedia";
import dataVal from "./dataVal.json";
import PageForm from "./PageForm";
import FilterForm from "./FilterForm";

const Products = () => {
  const dispatch = useDispatch();
  const { products, productsMeta, resStatus, limit } = useSelector((state) => state.core);
  const baseUrl = `items/products?fields=book_language.id,book_language.arabic_title,*,notes,publisher.id,publisher.arabic_title,publisher.countery_id,currency.title,currency.id,image.*,image.data.full_url,book_type_id.english_title,book_type_id.arabic_title,attributes.id,child_products.image,child_products.id,child_products.attributes.id,categories.id,tags.id,attributes.id,media.id,categories.category_id.english_title,categories.category_id.id,tags.tag_id.arabic_title,tags.tag_id.english_title,tags.tag_id.id,child_products.attributes.attribute_value_id.english_title,child_products.attributes.attribute_value_id.id,child_products.attributes.attribute_value_id.attribute_id.english_title,child_products.attributes.attribute_value_id.attribute_id.id,child_products.*,media.*,book_type_id.id,authors.author_id.id,authors.author_id.arabic_title,translators.author_id.id,translators.author_id.arabic_title,authors.id,translators.id`;
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [updateHandlerVal, setUpdateHandlerVal] = useState(null);
  const [formContainer, setFormContainer] = useState(<h1>hi</h1>);
  const [open, setOpen] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [url, setUrl] = useState(baseUrl);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const closeSuccessSB = () => setSuccessSB(false);
  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const setOpenModal = (container) => {
    setFormContainer(container);
    setOpen(!open);
  };

  const resetForm = () => {
    setFormContainer(<h1>wait ...</h1>);
    dispatch(reset(null));
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values);
  };

  const getData = () => {
    // get product data
    dispatch(
      fetchDataAction({
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}&filter[parent_id][null]&filter[status][neq]=deleted&sort=-quantity,+arabic_title`,
        varName: "products",
      })
    );
  };

  useEffect(() => {
    getData();
  }, [limit]);

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+productsMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    products.forEach((product) => {
      item = {};
      keys = Object.keys(product);

      item.publisherTitle = (
        <Avatar
          dir="ltr"
          name={product.publisher?.arabic_title}
          email={product.book_type_id?.arabic_title}
        />
      );

      item.aPrice = (
        <AvatarWithOutImg dir="ltr" name={product.discounted_price} email={product.actual_price} />
      );

      item.skuData = <Avatar dir="ltr" name={product.sku} email={product.years} />;

      item.shippingData = (
        <Avatar
          dir="ltr"
          name={`${product.height} ${product.height_mes}`}
          email={`${product.shipping_weight} ${product.weight_meg}`}
        />
      );

      let color = "";
      if (product.status === "published") {
        color = "green";
      } else if (product.status === "draft") {
        color = "gray";
      } else if (product.status === "rejected") {
        color = "red";
      }
      item.colory = (
        <div
          style={{
            borderRadius: "25px",
            width: "15px",
            height: "15px",
            background: color,
          }}
        />
      );
      let authorsName = null;
      product.authors?.forEach((author) => {
        if (authorsName) {
          authorsName += ` -  ${author.author_id.arabic_title}`;
        } else {
          authorsName = author.author_id.arabic_title;
        }
      });
      item.authorsName = authorsName;

      if (product.type === "S") {
        item.type1 = "Simple";
      } else if (product.type === "V") {
        item.type1 = "With Variables";
      }

      if (product.genre === "B") {
        item.genre1 = "Book";
      } else if (product.genre === "I") {
        item.genre1 = "Item";
      }

      item.title = (
        <Avatar
          dir="ltr"
          image={product.image?.data.full_url}
          name={product.arabic_title}
          email={authorsName}
        />
      );

      keys.forEach((element) => {
        item[element] = product[element];
      });

      item.action = (
        <>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => setUpdateHandlerVal({ name: "products", value: product })}
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#e9423f" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onDeleteHandler("products", product)}
          >
            <Icon fontSize="small">delete</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "tan" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => {
              // setProductId(product.id);
              setOpenModal(<ProductAttribute productId={product.id} />);
            }}
          >
            <Icon fontSize="small">palette</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "green" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => {
              // setProductId(product.id);
              setOpenModal(<ProductMedias productId={product.id} />);
            }}
          >
            <Icon fontSize="small">perm_media</Icon>
          </MDTypography>
        </>
      );
      Arr.push(item);
    });
    setNewArr(Arr);
  };

  const setPageNumber = (num) => {
    let link;
    if (url === baseUrl)
      link = `${url}&meta=*&limit=${limit}&page=${num}&filter[parent_id][null]&filter[status][neq]=deleted&sort=-quantity,+arabic_title`;
    else
      link = `${url}&meta=*&limit=${limit}&page=${num}&filter[parent_id][null]&sort=-quantity,+arabic_title`;
    if (num) {
      setPageNum(num);
      dispatch(
        fetchDataAction({
          url: link,
          varName: "products",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const arra = [];
    deleteItemId.authors.forEach((element) => {
      arra.push({
        url: "items/product_author",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.categories.forEach((element) => {
      arra.push({
        url: "items/product_category",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.celebrity_fav.forEach((element) => {
      arra.push({
        url: "items/celebrity_favourities",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.celebrity_pro.forEach((element) => {
      arra.push({
        url: "items/celebrity_products",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.child_products.forEach((element) => {
      arra.push({
        url: "items/products",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.media.forEach((element) => {
      arra.push({
        url: "items/product_media",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.tags.forEach((element) => {
      arra.push({
        url: "items/product_tag",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    deleteItemId.translators.forEach((element) => {
      arra.push({
        url: "items/product_translator",
        data: {
          id: element.id,
          status: "deleted",
        },
      });
    });
    const payload = {
      url: "items/products",
      data: {
        id: deleteItemId.id,
        status: "deleted",
      },
      related: arra,
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}&filter[parent_id][null]&filter[status][neq]=deleted&sort=-quantity,+arabic_title`,
        varName: "products",
      },
    };

    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (resStatus && resStatus === "success" && !open) {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    if (products) {
      prepareTableRows();
    }
  }, [products]);

  if (newArr === [] || !productsMeta) {
    return null;
  }

  return (
    <MDBox bgColor="#fff" p={5}>
      <DataModal
        handleClose={() => {
          resetForm();
          setOpen(!open);
        }}
        open={open}
      >
        {formContainer}
      </DataModal>
      <DeleteModal
        handleClose={handleDeleteModalClose}
        handleConfirmDelete={handleConfirmDelete}
        openDeleteModal={openDeleteModal}
      />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xl={7} xs={12}>
          <FilterForm
            resetData={resetForm}
            updateHandlerVal={updateHandlerVal}
            pageNum={pageNum}
            baseUrl={baseUrl}
            setUrl={setUrl}
          />
          <DataTable
            table={{ columns: dataVal.columns, rows: newArr }}
            item={false}
            entriesPerPage
            showTotalEntries
            search
            isSorted
            isPagination
            noEndBorder
            setPageNum={setPageNumber}
            pageIndex={pageNum - 1}
            pageOptions={pageOptions}
            setSortByHandeller={() => {}}
          />
        </Grid>
        <Grid item xl={5} xs={12}>
          <PageForm
            resetData={resetForm}
            updateHandlerVal={updateHandlerVal}
            pageNum={pageNum}
            baseUrl={baseUrl}
          />
        </Grid>
      </Grid>

      {renderSuccessSB}
    </MDBox>
  );
};

export default Products;
