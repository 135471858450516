import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
// import Avatar from "components/UI/Table/avatar";
// import DataModal from "components/UI/DataModal";
import MDSnackbar from "components/MDSnackbar";
import Icon from "@mui/material/Icon";
import DataTable from "components/UI/Table/DataTable";
import DeleteModal from "components/UI/DeleteModal";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import PagesCount from "helpers/pages-count";

import {
  fetchDataAction,
  deleteDataAction,
  reset,
  addComplexDataAction,
} from "store/features/coreSlice";
import Page from "components/UI/Page";
import dataVal from "./dataVal.json";
import PageForm from "./PageForm";
// import FilterForm from "./FilterForm";

const Packageing = () => {
  const dispatch = useDispatch();

  // read here data from redix
  const { packages, packagesMeta, resStatus, limit } = useSelector((state) => state.core);
  // chenge here req url
  const baseUrl = `items/shipping_package?fields=status,id,width,height,length,from,to,height_mes,weight,weight_mes`;
  const [newArr, setNewArr] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [updateHandlerVal, setUpdateHandlerVal] = useState(null);
  const [successSB, setSuccessSB] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const closeSuccessSB = () => setSuccessSB(false);
  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const resetForm = () => {
    dispatch(reset(null));
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  // get here data feed select inputs
  const getData = () => {
    dispatch(
      fetchDataAction({
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "packages",
      })
    );
  };

  const changeStatus = (status, category) => {
    const req = {
      id: category.id,
      origin: {
        data: { status },
        table: "shipping_package",
      },
      getInfo: {
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "packages",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+packagesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    packages.forEach((shippingPackage) => {
      item = {};
      keys = Object.keys(shippingPackage);

      // let color = "";
      // if (shippingPackage.status === "published") {
      //   color = "green";
      // } else if (shippingPackage.status === "draft") {
      //   color = "gray";
      // } else if (shippingPackage.status === "rejected") {
      //   color = "red";
      // }
      // item.colory = (
      //   <div
      //     style={{
      //       borderRadius: "25px",
      //       width: "15px",
      //       height: "15px",
      //       background: color,
      //     }}
      //   />
      // );

      keys.forEach((element) => {
        item[element] = shippingPackage[element];
      });

      item.action = (
        <>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => setUpdateHandlerVal({ name: "packages", value: shippingPackage })}
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#e9423f" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onDeleteHandler("packages", shippingPackage)}
          >
            <Icon fontSize="small">delete</Icon>
          </MDTypography>
          {shippingPackage.status === "draft" ? (
            <MDTypography
              className="button-group"
              style={{ padding: 20, color: "#e9423f" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => changeStatus("published", shippingPackage)}
            >
              <Icon fontSize="small">public_off</Icon>
            </MDTypography>
          ) : (
            <MDTypography
              style={{ padding: 20, color: "green" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => changeStatus("draft", shippingPackage)}
            >
              <Icon fontSize="small">public</Icon>
            </MDTypography>
          )}
        </>
      );
      Arr.push(item);
    });
    setNewArr(Arr);
  };

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);
      dispatch(
        fetchDataAction({
          url: `${baseUrl}&meta=*&limit=${limit}&page=${num}&filter[status][neq]=deleted`,
          varName: "packages",
        })
      );
    }
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/shipping_package",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}&filter[status][neq]=deleted`,
        varName: "packages",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    getData();
  }, [limit]);

  useEffect(() => {
    if (packages) {
      prepareTableRows();
    }
  }, [packages]);

  if (newArr === [] || !packagesMeta) {
    return null;
  }

  return (
    <Page>
      <Grid item xs={12}>
        <MDBox bgColor="#fff" p={5}>
          <DeleteModal
            handleClose={handleDeleteModalClose}
            handleConfirmDelete={handleConfirmDelete}
            openDeleteModal={openDeleteModal}
          />
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xl={7} xs={12}>
              {/* <FilterForm
                resetData={resetForm}
                updateHandlerVal={updateHandlerVal}
                pageNum={pageNum}
                baseUrl={baseUrl}
                setUrl={setUrl}
              /> */}
              <DataTable
                table={{ columns: dataVal.columns, rows: newArr }}
                item={false}
                isSorted
                isPagination
                entriesPerPage
                showTotalEntries
                noEndBorder
                setSortByHandeller={() => {}}
                setPageNum={setPageNumber}
                // count={packagesMeta.filter_count}
                pageIndex={pageNum - 1}
                pageOptions={pageOptions}
              />
            </Grid>
            <Grid item xl={5} xs={12}>
              <PageForm
                resetData={resetForm}
                updateHandlerVal={updateHandlerVal}
                pageNum={pageNum}
                baseUrl={baseUrl}
              />
            </Grid>
          </Grid>

          {renderSuccessSB}
        </MDBox>
      </Grid>
    </Page>
  );
};

export default Packageing;
