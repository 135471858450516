import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";
import { addComplexDataAction, fetchDataAction, reset, setAlert } from "store/features/coreSlice";
import PropTypes from "prop-types";
import UpdateRecord from "helpers/compare";
import { useTranslation } from "react-i18next";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    formproducts,
    formpublisher,
    limit,
    formstores,
    formlibraries,
    distributionsImgList,
    formproposal,
    formbuying,
  } = useSelector((state) => state.core);
  const { userInfo } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [publisherId, setPublisherId] = useState(null);
  const [buyingId, setBuyingId] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [inventories, setInventories] = useState([]);
  const [returns, setReturns] = useState([]);
  const [filesData, setFilesData] = useState(undefined);

  useEffect(() => {
    setFilesData(distributionsImgList);
  }, [distributionsImgList]);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "publisher",
        id: "publisher",
        label: "publisher",
        data: formpublisher,
        size: 3,
        title: "publisher",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: formproducts,
        size: 3,
        title: "book",
      },
      {
        type: "Select",
        optionTitle: "buying_date",
        optionTitle1: "notes",
        name: "buying_id",
        id: "buying_id",
        label: "buying_id",
        data: formbuying,
        size: 3,
        title: "buying",
      },
      {
        type: "Select",
        optionTitle: "place_id",
        oterOptionTitle: "title",
        name: "store_id",
        id: "store_id",
        label: "store_id",
        data: formstores,
        size: 3,
        title: "store",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "library_id",
        id: "library_id",
        label: "library_id",
        data: formlibraries,
        size: 3,
        title: "libraries",
      },
      {
        type: "Select",
        optionTitle: "cost",
        optionTitlecond: "is_dolar",
        optionTitleText: "$",
        optionTitle1: "discount_percentage",
        optionTitle1Text: "%",
        name: "proposal_id",
        id: "proposal_id",
        label: "proposal_id",
        data: formproposal,
        disable: inventories.length > 0 && !updateHandlerVal.isCopy,
        size: 3,
        title: "proposal",
      },
      {
        type: "TextField",
        name: "count",
        id: "count",
        label: "count",
        size: 3,
        title: "count",
      },
      {
        type: "Date",
        name: "distribution_date",
        id: "distribution_date",
        label: "distribution_date",
        size: 3,
        title: "distributionDate",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 6,
        title: "notes",
      },
      {
        type: "MImg",
        name: "files",
        id: "files",
        label: "files",
        size: 6,
        title: "image",
      },
    ]);
  };

  const resetForm = () => {
    setPublisherId(null);
    setBookId(null);
    setPublisherId(null);
    setBuyingId(null);
    setFilesData(undefined);
    setInventories([]);
    setReturns([]);
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "publisher") {
      setPublisherId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/products?fields=id,arabic_title,publisher&filter[publisher]=${values.id}`,
          varName: "formproducts",
        })
      );
    } else if (name === "book_id") {
      setBookId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/buying?fields=id,buying_date,notes&filter[book_id]=${values.id}`,
          varName: "formbuying",
        })
      );
      dispatch(
        fetchDataAction({
          url: `items/proposal?fields=id,cost,discount_percentage,is_dolar&filter[status]=published&filter[book_id]=${values.id}`,
          varName: "formproposal",
        })
      );
    } else if (name === "buying_id") {
      setBuyingId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/stores?fields=id,place_id.title,place_id.id,count,distributions.count,gifts.count,gifts.id,distributions.returns.count,distributions.id,buying_id.buying_date&filter[buying_id]=${values.id}`,
          varName: "formstores",
        })
      );
    }
  };

  const onChangeSelectInputs = (name, text) => {
    let urlVal;
    if (name === "publisher") {
      dispatch(
        fetchDataAction({
          url: `items/publisher?fields=id,arabic_title&filter[arabic_title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "formpublisher",
        })
      );
    } else if (name === "book_id") {
      urlVal = `items/products?fields=id,arabic_title&filter[arabic_title][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (publisherId) urlVal += `&filter[publisher]=${publisherId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formproducts",
        })
      );
    } else if (name === "buying_id") {
      urlVal = `items/buying?fields=id,buying_date,notes&filter[buying_date][eq]=${text}&limit=${limit}`;
      if (bookId) urlVal += `&filter[book_id]=${bookId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formbuying",
        })
      );
    } else if (name === "store_id") {
      urlVal = `items/stores?fields=id,place_id.title,place_id.id,count,distributions.count,gifts.count,gifts.id,distributions.returns.count,distributions.id,buying_id.buying_date&filter[place_id.title][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (buyingId) urlVal += `&filter[buying_id]=${buyingId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formstores",
        })
      );
    } else if (name === "library_id") {
      urlVal = `items/libraries?filter[title][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (!userInfo.all_libraries) urlVal += `&filter[users.user_id][eq]=${userInfo.id}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formlibraries",
        })
      );
    } else if (name === "proposal_id") {
      urlVal = `items/proposal?fields=id,cost,discount_percentage,is_dolar&filter[status]=published&filter[cost][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (bookId) urlVal += `&filter[book_id]=${bookId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formproposal",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "proposal")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,cost,discount_percentage,is_dolar&filter[status]=published&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else if (element === "buying")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,buying_date,notes&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else if (element === "stores")
        dispatch(
          fetchDataAction({
            url: `items/stores?fields=id,place_id.title,place_id.id,count,distributions.count,gifts.count,gifts.id,distributions.returns.count,distributions.id,buying_id.buying_date&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      else if (element === "libraries") {
        let urlVal = `items/libraries?limit=${limit}`;
        if (!userInfo.all_libraries) urlVal += `&filter[users.user_id][eq]=${userInfo.id}`;
        dispatch(
          fetchDataAction({
            url: urlVal,
            varName: `form${element}`,
          })
        );
      } else
        dispatch(
          fetchDataAction({
            url: `items/${element}?limit=${limit}`,
            varName: `form${element}`,
          })
        );
    });
  };

  const onUpdateHandler = (name, values, copy) => {
    if (!copy)
      dispatch(
        fetchDataAction({
          url: `items/all_files?fields=id,collection,file_id.data.full_url,file_id.filesize,file_id.id,item_id,file_id.type,file_id.filename_download&filter[collection]=distributions&filter[item_id]=${values.id}`,
          varName: "distributionsImgList",
        })
      );

    if (values.store_id?.buying_id?.book_id?.publisher) {
      setFieldSelectedValue("publisher", values.store_id.buying_id.book_id.publisher);
      onChangeSelectInputs("publisher", values.store_id.buying_id.book_id.publisher.arabic_title);
    }
    if (values.store_id?.buying_id?.book_id) {
      setFieldSelectedValue("book_id", values.store_id.buying_id.book_id);
      onChangeSelectInputs("book_id", values.store_id.buying_id.book_id.arabic_title);
    }
    if (values.store_id?.buying_id) {
      setFieldSelectedValue("buying_id", values.store_id.buying_id);
      onChangeSelectInputs("buying_id", values.store_id.buying_id.buying_date);
    }

    if (values.library_id) {
      onChangeSelectInputs("library_id", values.library_id.title);
    }

    setInventories(values.inventories);
    setReturns(values.returns);
    setInitialValues({
      id: copy ? null : values.id,
      book_id: values.store_id.buying_id.book_id,
      publisher: values.store_id.buying_id.book_id.publisher,
      buying_id: values.store_id.buying_id,
      library_id: values.library_id,
      count: values.count,
      store_id: values.store_id,
      proposal_id: values.proposal_id,
      distribution_date: values.distribution_date,
      notes: values.notes,
    });
  };

  const setPayload = ({ files, ...values }) => {
    let count = 0;
    let count1 = 0;
    if (values.id) {
      inventories.forEach((element) => {
        count1 += element.count;
      });
      returns.forEach((element) => {
        count1 += element.count;
      });
    }

    values.store_id.distributions.forEach((element) => {
      if (element.id === values.id) {
        count -= element.count;
      }
      element.returns.forEach((element1) => {
        count -= element1.count;
      });
      count += element.count;
    });

    values.store_id.gifts.forEach((element) => {
      count += element.count;
    });

    if (values.distribution_date < values.store_id.buying_id.buying_date) {
      dispatch(
        setAlert({
          status: "error",
          content: t("dateError"),
        })
      );
    } else if (
      (values.id === null ||
        values.count === initialValues.count ||
        (values.id && count1 <= values.count)) &&
      values.store_id.count - (+values.count + count) >= 0
    ) {
      const payloadData = {};
      const lastData = values.id ? UpdateRecord(values, initialValues) : values;

      Object.keys(lastData).forEach((key) => {
        if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
        else if (values.id || lastData[key]) payloadData[key] = lastData[key];
      });

      delete payloadData.publisher;
      delete payloadData.book_id;
      delete payloadData.buying_id;
      const req = {
        id: values.id,
        file: files,
        origin: {
          data: Object.keys(payloadData).length === 0 ? null : payloadData,
          table: "distributions",
        },
        getInfo: {
          url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
          varName: "distributions",
        },
      };
      setInitialValues(values);
      dispatch(addComplexDataAction(req));
      resetForm();
    } else
      dispatch(
        setAlert({
          status: "error",
          content: t("countError"),
        })
      );
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: dataVal.oterData }));
    };
  }, []);

  useEffect(() => {
    if (formproducts && formpublisher && formbuying && formstores && formlibraries && formproposal)
      orgData();
  }, [formproducts, formpublisher, formbuying, formstores, formlibraries, formproposal]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        publisher: yup.mixed("required").required("required").nullable(),
        book_id: yup.mixed("required").required("required").nullable(),
        buying_id: yup.mixed("required").required("required").nullable(),
        store_id: yup.mixed("required").required("required").nullable(),
        library_id: yup.mixed("required").required("required").nullable(),
        proposal_id: yup.mixed("required").required("required").nullable(),
        distribution_date: yup.string("Enter your title").required("required").nullable(),
        count: yup
          .number("must be number")
          .required("required")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
      }}
      imgList={filesData}
      resetData={resetForm}
      name="distributions"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};
export default PageForm;
