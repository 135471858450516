import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";

const DataModal = ({ open, handleClose, children }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      sx={{ overflow: "visible", p: 2, m: 2 }}
    >
      <DialogTitle>{t("details")}</DialogTitle>
      <DialogContent>
        <DialogContentText />
        <Box>{children}</Box>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleClose} variant="outlined" color="info">
          {t("close")}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};
DataModal.defaultProps = {
  children: null,
};
DataModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default DataModal;
