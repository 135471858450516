import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import Icon from "@mui/material/Icon";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import Avatar from "components/UI/Table/avatar";
import MDSnackbar from "components/MDSnackbar";
import UpdateRecord from "helpers/compare";
import PagesCount from "helpers/pages-count";

import {
  addComplexDataAction,
  fetchDataAction,
  deleteDataAction,
  reset,
} from "store/features/coreSlice";
import dataVal from "../dataVal.json";

const Currencies = () => {
  const dispatch = useDispatch();

  const { currenciesMeta, currencies, resStatus, limit } = useSelector((state) => state.core);
  const [open, setOpen] = useState(false);

  const [newArr, setNewArr] = useState([]);
  const [data, setData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [initialValues, setInitialValues] = useState(dataVal.currencyInitialValues);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [successSB, setSuccessSB] = useState(false);

  const closeSuccessSB = () => setSuccessSB(false);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "arabic_title",
        id: "arabic_title",
        label: "arabic_title",
        size: 4,
        title: "Arabic Title",
      },
      {
        type: "TextField",
        name: "english_title",
        id: "english_title",
        label: "english_title",
        size: 4,
        title: "English Title",
      },
      {
        type: "TextField",
        name: "title",
        id: "title",
        label: "title",
        size: 4,
        title: "Title",
      },
      {
        type: "TextField",
        name: "to",
        id: "to",
        label: "to",
        maxRows: 4,
        minRows: 4,
        size: 4,
        title: "To KWD",
      },
      {
        type: "TextField",
        name: "from",
        id: "from",
        label: "from",
        maxRows: 4,
        minRows: 4,
        size: 4,
        title: "From KWD",
      },
      {
        type: "Img",
        name: "image",
        id: "image",
        label: "image",
        size: 4,
        title: "Image",
      },
    ]);
  };

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      english_title: values.english_title,
      arabic_title: name === "reset" ? null : values.arabic_title,
      from: values.from,
      to: values.to,
      image: values.image?.data.full_url,
      title: values.title,
    });
    setOpen(true);
  };

  const setOpenModal = () => {
    if (open)
      setInitialValues({
        id: null,
        arabic_title: "",
      });
    setOpen(!open);
  };

  const resetForm = () => {
    setInitialValues(dataVal.currencyInitialValues);
    orgData();
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const getData = () => {
    // get product data
    const url = `items/currency?fields=id,arabic_title,english_title,to,from,title,image.data.full_url&meta=*&limit=${limit}&page=${pageNum}`;
    dispatch(
      fetchDataAction({
        url,
        varName: "currencies",
      })
    );
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+currenciesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    currencies.forEach((currency) => {
      item = {};
      keys = Object.keys(currency);

      item.title = (
        <Avatar
          dir="ltr"
          image={currency.image?.data.full_url}
          name={currency.arabic_title}
          email=""
        />
      );

      keys.forEach((element) => {
        item[element] = currency[element];
      });

      item.action = (
        <>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onUpdateHandler("currencies", currency)}
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#e9423f" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onDeleteHandler("currencies", currency)}
          >
            <Icon fontSize="small">delete</Icon>
          </MDTypography>
        </>
      );
      Arr.push(item);
    });
    setNewArr(Arr);
  };

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      const url = `items/currency?fields=id,arabic_title,english_title,to,from,title,image.data.full_url&meta=*&limit=${limit}&page=${num}`;
      console.log("pageNum", num, url);

      dispatch(
        fetchDataAction({
          url,
          varName: "currencies",
        })
      );
    }
  };

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      imgFieldName: "image",
      id: values.id,
      file: typeof values.image === "object" ? values.image : null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "currency",
      },
      getInfo: {
        url: `items/currency?fields=id,arabic_title,english_title,to,from,title,image.data.full_url,image.data.full_url&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "currencies",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/currency",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `items/currency?fields=id,arabic_title,english_title,to,from,title,image.data.full_url,image.data.full_url&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "currencies",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    getData();
    return () => {
      dispatch(reset(null));
    };
  }, []);

  useEffect(() => {
    if (currencies && currencies.length !== 0) {
      prepareTableRows();
    }
  }, [currencies]);

  useEffect(() => {
    orgData();
  }, []);

  if (data.length === 0 || newArr === [] || !currenciesMeta) {
    return null;
  }

  return (
    <>
      <Main
        layout="simple"
        report={false}
        allRowsCount={+currenciesMeta.filter_count}
        pageIndex={pageNum - 1}
        count={pageOptions}
        setOpen={setOpenModal}
        open={open}
        setPageNum={setPageNumber}
        setOpenDeleteModal={setOpenDeleteModal}
        openDeleteModal={openDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        name="currencies"
        setPayload={setPayload}
        data={data}
        initialValues={initialValues}
        title="Add Currency"
        columns={dataVal.columns}
        defIniVal={dataVal.currencyInitialValues}
        rows={newArr}
        onUpdateHandler={onUpdateHandler}
        onDeleteHandler={onDeleteHandler}
        yupObj={{
          arabic_title: yup.string("Enter your Title").required("Title is required").nullable(),
        }}
      />
      {renderSuccessSB}
    </>
  );
};

export default Currencies;
