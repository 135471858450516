import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";

import * as yup from "yup";
import MDTypography from "components/MDTypography";
import Avatar from "components/UI/Table/avatar";
import MDSnackbar from "components/MDSnackbar";
import UpdateRecord from "helpers/compare";
import Page from "components/UI/Page";
import { Grid, Icon } from "@mui/material";
import PagesCount from "helpers/pages-count";

import {
  addComplexDataAction,
  fetchDataAction,
  deleteDataAction,
  reset,
} from "../../../store/features/coreSlice";
import dataVal from "./dataVal.json";

const LibraryCategories = () => {
  const dispatch = useDispatch();

  const { libraryCategoriesMeta, libraryCategories, resStatus, limit } = useSelector(
    (state) => state.core
  );

  const [newArr, setNewArr] = useState([]);
  const [data, setData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [successSB, setSuccessSB] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const closeSuccessSB = () => setSuccessSB(false);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "arabic_title",
        id: "arabic_title",
        label: "arabic_title",
        size: 4,
        title: "Arabic Title",
      },
      {
        type: "TextField",
        name: "english_title",
        id: "english_title",
        label: "english_title",
        size: 4,
        title: "English Title",
      },
      {
        type: "TextField",
        name: "sequence",
        id: "sequence",
        label: "sequence",
        size: 4,
        title: "Sequence",
      },
      {
        type: "TextArea",
        name: "arabic_description",
        id: "arabic_description",
        label: "arabic_description",
        maxRows: 4,
        minRows: 4,
        size: 4,
        title: "Arabic Description",
      },
      {
        type: "TextArea",
        name: "english_description",
        id: "english_description",
        label: "english_description",
        maxRows: 4,
        minRows: 4,
        size: 4,
        title: "English Description",
      },
      {
        type: "Img",
        name: "category_image",
        id: "category_image",
        label: "category_image",
        size: 4,
        title: "Image",
      },
    ]);
  };

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      english_title: values.english_title,
      arabic_title: name === "reset" ? null : values.arabic_title,
      english_description: values.english_description,
      arabic_description: values.arabic_description,
      category_image: values.category_image?.data.full_url,
      sequence: values.sequence,
    });
  };

  const resetForm = () => {
    setInitialValues(dataVal.initialValues);
    orgData();
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const getData = () => {
    // get product data
    const url = `items/categories?fields=status,products.product_id.status,child_categories.id,id,arabic_title,english_title,arabic_description,english_description,background_color,sequence,parent_category.english_title,parent_category.id,category_image.id,category_image.data.full_url,component_type_id.title&meta=*&limit=${limit}&page=${pageNum}&filter[parent_category.id][in]=103&filter[parent_category][nempty]`;
    dispatch(
      fetchDataAction({
        url,
        varName: "libraryCategories",
      })
    );
  };
  const changeStatus = (status, category) => {
    const req = {
      id: category.id,
      origin: {
        data: { status },
        table: "categories",
      },
      getInfo: {
        url: `items/categories?fields=status,products.product_id.status,child_categories.id,id,arabic_title,english_title,arabic_description,english_description,background_color,sequence,parent_category.english_title,parent_category.id,category_image.id,category_image.data.full_url,component_type_id.title&meta=*&limit=${limit}&page=${pageNum}&filter[parent_category.id][in]=103&filter[parent_category][nempty]`,
        varName: "libraryCategories",
      },
    };
    dispatch(addComplexDataAction(req));
  };
  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+libraryCategoriesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    libraryCategories.forEach((category) => {
      item = {};
      keys = Object.keys(category);

      item.title = (
        <Avatar
          dir="ltr"
          image={category.category_image?.data.full_url}
          name={category.arabic_title}
          email=""
        />
      );

      keys.forEach((element) => {
        item[element] = category[element];
      });

      item.action = (
        <>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onUpdateHandler("libraryCategories", category)}
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#e9423f" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onDeleteHandler("libraryCategories", category)}
          >
            <Icon fontSize="small">delete</Icon>
          </MDTypography>
          {category.status === "draft" ? (
            <MDTypography
              className="button-group"
              style={{ padding: 20, color: "#e9423f" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => changeStatus("published", category)}
            >
              <Icon fontSize="small">public_off</Icon>
            </MDTypography>
          ) : (
            <MDTypography
              style={{ padding: 20, color: "green" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => changeStatus("draft", category)}
            >
              <Icon fontSize="small">public</Icon>
            </MDTypography>
          )}
        </>
      );
      Arr.push(item);
    });
    setNewArr(Arr);
  };

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      const url = `items/categories?fields=status,products.product_id.status,child_categories.id,id,arabic_title,english_title,arabic_description,english_description,background_color,sequence,parent_category.english_title,parent_category.id,category_image.id,category_image.data.full_url,component_type_id.title&meta=*&limit=${limit}&page=${num}&filter[parent_category.id][in]=103&filter[parent_category][nempty]`;

      dispatch(
        fetchDataAction({
          url,
          varName: "libraryCategories",
        })
      );
    }
  };

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      imgFieldName: "category_image",
      id: values.id,
      file: typeof values.category_image === "object" ? values.category_image : null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "categories",
      },
      getInfo: {
        url: `items/categories?fields=status,products.product_id.status,child_categories.id,id,arabic_title,english_title,arabic_description,english_description,background_color,sequence,parent_category.english_title,parent_category.id,category_image.id,category_image.data.full_url,component_type_id.title&meta=*&limit=${limit}&page=${pageNum}&filter[parent_category.id][in]=103&filter[parent_category][nempty]`,
        varName: "libraryCategories",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/categories",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `items/categories?fields=status,products.product_id.status,child_categories.id,id,arabic_title,english_title,arabic_description,english_description,background_color,sequence,parent_category.english_title,parent_category.id,category_image.id,category_image.data.full_url,component_type_id.title&meta=*&limit=${limit}&page=${pageNum}&filter[parent_category.id][in]=103&filter[parent_category][nempty]`,
        varName: "libraryCategories",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    getData();
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    if (libraryCategories && libraryCategories.length !== 0) {
      prepareTableRows();
    }
  }, [libraryCategories]);

  useEffect(() => {
    orgData();
  }, []);

  if (data.length === 0 || newArr === [] || !libraryCategoriesMeta) {
    return null;
  }

  return (
    <Page>
      <Grid item xs={12}>
        <Main
          layout="simple"
          allRowsCount={+libraryCategoriesMeta.filter_count}
          pageIndex={pageNum - 1}
          count={pageOptions}
          setPageNum={setPageNumber}
          setOpenDeleteModal={setOpenDeleteModal}
          openDeleteModal={openDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
          name="libraryCategories"
          setPayload={setPayload}
          data={data}
          entriesPerPage
          initialValues={initialValues}
          title="Add Library Categories"
          columns={dataVal.columns}
          rows={newArr}
          onUpdateHandler={onUpdateHandler}
          onDeleteHandler={onDeleteHandler}
          yupObj={{
            arabic_title: yup.string("Enter your Title").required("Title is required").nullable(),
          }}
        />
        {renderSuccessSB}
      </Grid>
    </Page>
  );
};

export default LibraryCategories;
