import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl, setPublisherId }) => {
  const dispatch = useDispatch();
  const { filtercounteries, filterpublisher, limit } = useSelector((state) => state.core);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);

  const [initialValues, setInitialValues] = useState(dataVal.initialValuesFilter);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "title",
        id: "title",
        label: "title",
        size: 3,
        title: "title",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "publisher_id",
        id: "publisher_id",
        label: "publisher_id",
        data: filterpublisher,
        size: 3,
        title: "publisher",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "country_id",
        id: "country_id",
        label: "country_id",
        data: filtercounteries,
        size: 3,
        title: "country",
      },
    ]);
  };

  useEffect(() => {
    if (filtercounteries && filterpublisher) orgData();
  }, [filtercounteries, filterpublisher]);

  const generateUrl = (values) => {
    let str = baseUrl;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element]) {
        if (element === "publisher_id") {
          setPublisherId(values[element].id);
          str += `&filter[distributions.store_id.buying_id.book_id.publisher.id]=${values[element].id}`;
        } else if (element === "country_id") {
          str += `&filter[country_id.id]=${values[element].id}`;
        } else if (element === "title") str += `&filter[${element}][contains]=${values[element]}`;
      }
    });
    return str;
  };

  const onChangeHandler = (name, text) => {
    if (name === "publisher_id") {
      dispatch(
        fetchDataAction({
          url: `items/publisher?fields=id,arabic_title&filter[arabic_title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filterpublisher",
        })
      );
    }
    if (name === "country_id") {
      dispatch(
        fetchDataAction({
          url: `items/counteries?fields=id,arabic_title&filter[arabic_title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filtercounteries",
        })
      );
    }
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "librariesRep",
      })
    );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      dispatch(
        fetchDataAction({
          url: `items/${element}?fields=id,arabic_title&limit=${limit}`,
          varName: `filter${element}`,
        })
      );
    });
  };

  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      name="researchFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      onChange={onChangeHandler}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setPublisherId: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
