import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import PagesCount from "helpers/pages-count";

import {
  fetchDataAction,
  addDataAction,
  deleteDataAction,
  reset,
  addComplexDataAction,
} from "../../../store/features/coreSlice";

const Authors = () => {
  const { mainAuthers, mainAuthersMeta, resStatus, limit } = useSelector((state) => state.core);

  const dispatch = useDispatch();

  const columns = [
    { Header: "Title", accessor: "arabic_title", width: "45%", align: "left" },
    { Header: "En Title", accessor: "english_title", width: "45%", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [initialValues, setInitialValues] = useState({
    id: null,
    arabic_title: null,
    image: null,
    english_title: null,
    sequence: null,
  });

  const authorInitialValues = {
    id: null,
    arabic_title: null,
    image: null,
    english_title: null,
    sequence: null,
  };

  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      arabic_title: values.arabic_title,
      image: values.image?.data.full_url,
      english_title: values.english_title,
      sequence: values.sequence,
    });
    setOpen(true);
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const getData = (filter) => {
    let url = `items/author?meta=*&limit=${limit}&page=${pageNum}`;
    if (filter) {
      const keys = Object.keys(filter);
      keys.forEach((element) => {
        if (filter[element] && filter[element] !== []) {
          if (typeof filter[element] === "object") {
            url += `&filter[${element}.id]=${filter[element].id}`;
          } else {
            url += `&filter[${element}][rlike]=%${filter[element]}%`;
          }
        }
      });
    }

    const payload = {
      url,
      varName: "mainAuthers",
    };
    dispatch(fetchDataAction(payload));
  };

  useEffect(() => {
    getData();
  }, [limit]);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      dispatch(
        fetchDataAction({
          url: `items/author?meta=*&limit=${limit}&page=${num}`,
          varName: "mainAuthers",
        })
      );
    }
  };

  const changeStatus = (status, category) => {
    const req = {
      id: category.id,
      origin: {
        data: { status },
        table: "author",
      },
      getInfo: {
        url: `items/author?meta=*&limit=${limit}&page=${pageNum}`,
        varName: "mainAuthers",
      },
    };
    dispatch(addComplexDataAction(req));
  };
  useEffect(() => {
    if (mainAuthers) {
      let item = {};
      const Arr = [];
      const d = [];
      for (let i = 0; i < PagesCount(+mainAuthersMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      if (mainAuthers && mainAuthers.length !== 0) {
        mainAuthers.forEach((author) => {
          item = {};
          item.arabic_title = author.arabic_title;
          item.english_title = author.english_title;
          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onUpdateHandler("mainAuthers", author)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("mainAuthers", author)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
              {author.status === "draft" ? (
                <MDTypography
                  className="button-group"
                  style={{ padding: 20, color: "#e9423f" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => changeStatus("published", author)}
                >
                  <Icon fontSize="small">public_off</Icon>
                </MDTypography>
              ) : (
                <MDTypography
                  style={{ padding: 20, color: "green" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => changeStatus("draft", author)}
                >
                  <Icon fontSize="small">public</Icon>
                </MDTypography>
              )}
            </>
          );
          Arr.push(item);
        });
        setNewArr(Arr);
      }
    }
  }, [mainAuthers]);

  const data = [
    {
      type: "TextField",
      name: "arabic_title",
      id: "arabic_title",
      label: "arabic_title",
      size: 3,
      title: "Author",
    },
    {
      type: "TextField",
      name: "english_title",
      id: "english_title",
      label: "english_title",
      size: 3,
      title: "English Title",
    },
    {
      type: "TextField",
      name: "sequence",
      id: "sequence",
      label: "sequence",
      size: 3,
      title: "Sequence",
    },
    {
      type: "Img",
      name: "image",
      id: "image",
      label: "image",
      size: 3,
      title: "Image",
    },
  ];

  const resetForm = () => {
    setInitialValues({
      id: null,
      arabic_title: null,
      image: null,
      english_title: null,
      sequence: null,
    });
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  const setPayload = (values) => {
    const payload = {
      url: `items/author`,
      data: values,
      varName: "mainAuthers",
      getInfo: {
        url: `items/author?meta=*&limit=${limit}&page=${pageNum}`,
        varName: "mainAuthers",
      },
    };
    dispatch(addDataAction(payload));
  };

  const setOpenModal = () => {
    if (open)
      setInitialValues({
        id: null,
        arabic_title: null,
        image: null,
        english_title: null,
        sequence: null,
      });
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: `items/author`,
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      varName: "mainAuthers",
      getInfo: {
        url: `items/author?meta=*&limit=${limit}&page=${pageNum}`,
        varName: "mainAuthers",
      },
    };

    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  if (data.length === 0 || newArr === [] || !mainAuthersMeta) {
    return null;
  }

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <>
      <Main
        layout="simple"
        allRowsCount={+mainAuthersMeta.filter_count}
        pageIndex={pageNum - 1}
        count={pageOptions}
        setPageNum={setPageNumber}
        openDeleteModal={openDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        setOpen={setOpenModal}
        setOpenDeleteModal={setOpenDeleteModal}
        open={open}
        name="mainAuthers"
        setPayload={setPayload}
        defIniVal={authorInitialValues}
        data={data}
        initialValues={initialValues}
        title="Add Author"
        columns={columns}
        rows={newArr}
        onUpdateHandler={onUpdateHandler}
        report={false}
        onDeleteHandler={onDeleteHandler}
        yupObj={{
          arabic_title: yup.string("Enter your Title").required("Title is required"),
        }}
        yupObjFilter={{}}
        nameFilter="nameFilter"
        dataFilter={[
          {
            type: "TextField",
            name: "english_title",
            id: "english_title",
            label: "english_title",
            size: 6,
            title: "English Title",
          },
        ]}
        initialValuesFilter={{}}
        setPayloadFilter={(val) => {
          getData(val);
        }}
        setFieldSelectedValue={(val, gal, mal) => {
          console.log("setFieldSelectedValue", val, gal, mal);
        }}
        onChange={() => {
          console.log("onChange");
        }}
        filter
      />

      {renderSuccessSB}
    </>
  );
};

export default Authors;
