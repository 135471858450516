import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import Icon from "@mui/material/Icon";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import UpdateRecord from "helpers/compare";
import PagesCount from "helpers/pages-count";

import {
  addComplexDataAction,
  fetchDataAction,
  deleteDataAction,
  reset,
} from "store/features/coreSlice";
import dataVal from "../dataVal.json";

const Countries = () => {
  const dispatch = useDispatch();

  const { countriesMeta, countries, resStatus, currencies, limit } = useSelector(
    (state) => state.core
  );
  const [open, setOpen] = useState(false);

  const [newArr, setNewArr] = useState([]);
  const [data, setData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [initialValues, setInitialValues] = useState(dataVal.countryInitialValues);
  const [successSB, setSuccessSB] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const closeSuccessSB = () => setSuccessSB(false);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "arabic_title",
        id: "arabic_title",
        label: "arabic_title",
        size: 6,
        title: "Arabic Title",
      },
      {
        type: "TextField",
        name: "english_title",
        id: "english_title",
        label: "english_title",
        size: 6,
        title: "English Title",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "currency_id",
        id: "currency_id",
        label: "currency_id",
        data: currencies,
        size: 4,
        title: "Currency",
      },
      {
        type: "TextField",
        name: "dialing_code",
        id: "dialing_code",
        label: "dialing_code",
        maxRows: 4,
        minRows: 4,
        size: 4,
        title: "Dialing Code",
      },
      {
        type: "TextField",
        name: "iso_code",
        id: "iso_code",
        label: "iso_code",
        maxRows: 4,
        minRows: 4,
        size: 4,
        title: "Iso Code",
      },
    ]);
  };

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      english_title: values.english_title,
      arabic_title: name === "reset" ? null : values.arabic_title,
      iso_code: values.iso_code,
      dialing_code: values.dialing_code,
      currency_id: values.currency_id,
    });
    setOpen(true);
  };

  const setOpenModal = () => {
    if (open)
      setInitialValues({
        id: null,
        arabic_title: "",
      });
    setOpen(!open);
  };

  const resetForm = () => {
    setInitialValues(dataVal.countryInitialValues);
    orgData();
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const getData = () => {
    // get product data
    let url = `items/counteries?fields=id,arabic_title,english_title,dialing_code,iso_code,currency_id.id,currency_id.arabic_title&meta=*&limit=${limit}&page=${pageNum}`;
    dispatch(
      fetchDataAction({
        url,
        varName: "countries",
      })
    );

    url = `items/currency?fields=id,arabic_title,english_title,to,from,title,image.id,image.data.full_url&meta=*&limit=${limit}&page=${pageNum}`;
    dispatch(
      fetchDataAction({
        url,
        varName: "currencies",
      })
    );
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+countriesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    countries.forEach((counteries) => {
      item = {};
      keys = Object.keys(counteries);

      keys.forEach((element) => {
        item[element] = counteries[element];
      });

      item.action = (
        <>
          <MDTypography
            style={{ padding: 20, color: "#3b94ee" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onUpdateHandler("countries", counteries)}
          >
            <Icon fontSize="small">edit</Icon>
          </MDTypography>
          <MDTypography
            style={{ padding: 20, color: "#e9423f" }}
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => onDeleteHandler("countries", counteries)}
          >
            <Icon fontSize="small">delete</Icon>
          </MDTypography>
        </>
      );
      Arr.push(item);
    });
    setNewArr(Arr);
  };

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      const url = `items/counteries?fields=id,arabic_title,english_title,dialing_code,iso_code&meta=*&limit=${limit}&page=${num}`;
      console.log("pageNum", num, url);

      dispatch(
        fetchDataAction({
          url,
          varName: "countries",
        })
      );
    }
  };

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      imgFieldName: "image",
      id: values.id,
      file: typeof values.image === "object" ? values.image : null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "counteries",
      },
      getInfo: {
        url: `items/counteries?fields=id,arabic_title,english_title,dialing_code,iso_code,currency_id.id,currency_id.arabic_title&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "countries",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/counteries",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `items/counteries?fields=id,arabic_title,english_title,dialing_code,iso_code,currency_id.id,currency_id.arabic_title&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "countries",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    getData();
    return () => {
      dispatch(reset(null));
    };
  }, []);

  useEffect(() => {
    if (countries && countries.length !== 0) {
      prepareTableRows();
    }
  }, [countries]);

  useEffect(() => {
    if (currencies) orgData();
  }, [currencies]);

  if (data.length === 0 || newArr === [] || !countriesMeta) {
    return null;
  }

  return (
    <>
      <Main
        report={false}
        layout="simple"
        allRowsCount={+countriesMeta.filter_count}
        pageIndex={pageNum - 1}
        count={pageOptions}
        setOpen={setOpenModal}
        open={open}
        setPageNum={setPageNumber}
        setOpenDeleteModal={setOpenDeleteModal}
        openDeleteModal={openDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        name="countries"
        setPayload={setPayload}
        defIniVal={dataVal.countryInitialValues}
        data={data}
        initialValues={initialValues}
        title="Add Country"
        columns={dataVal.countryColumns}
        rows={newArr}
        onUpdateHandler={onUpdateHandler}
        onDeleteHandler={onDeleteHandler}
        yupObj={{
          arabic_title: yup.string("Enter your Title").required("Title is required").nullable(),
        }}
      />
      {renderSuccessSB}
    </>
  );
};

export default Countries;
