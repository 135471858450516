import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import MDTypography from "components/MDTypography";
import UpdateRecord from "helpers/compare";
import { Icon } from "@mui/material";
import PropTypes from "prop-types";
import PagesCount from "helpers/pages-count";
import dataVal from "./dataVal.json";

import {
  addComplexDataAction,
  deleteDataAction,
  fetchDataAction,
  reset,
} from "../../store/features/coreSlice";

const OrdersNotes = ({ ordetId }) => {
  const dispatch = useDispatch();

  const { ordersNotesValue, ordersNotesValueMeta, resStatus, limit } = useSelector(
    (state) => state.core
  );
  const [newArr, setNewArr] = useState([]);
  const [arrData, setData] = useState([]);
  const [notesOpenDeleteModal, setNotesOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [notesInitialValues, setInitialValues] = useState({
    ...dataVal.ordersNotes.initialValues,
    order_id: ordetId,
  });

  const setNotesFieldSelectedValue = (name, values, isUpdate = false, updateCode) => {
    console.log(name, values, isUpdate, updateCode);
  };

  const onNotesUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      note: values.note,
    });
  };

  const onNotesDeleteHandler = (name, values) => {
    setNotesOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const orgData = useCallback(() => {
    setData([
      {
        type: "TextArea",
        name: "note",
        id: "note",
        label: "note",
        size: 12,
        title: "note",
      },
    ]);
  }, []);

  useEffect(
    () => () => {
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    if (ordersNotesValue) {
      orgData();
    }
  }, [ordersNotesValue]);

  const replaceAt = (str, replacement, index) =>
    str.substr(0, index) + replacement + str.substr(index + 1);

  useEffect(() => {
    if (ordersNotesValue) {
      let item = {};
      const Arr = [];
      let keys = [];
      const d = [];
      for (let i = 0; i < PagesCount(+ordersNotesValueMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      if (ordersNotesValue && ordersNotesValue.length !== 0) {
        ordersNotesValue.forEach((order) => {
          item = {};
          keys = Object.keys(order);

          const index = order.created_on.indexOf(" ") + 1;
          const index1 = order.created_on.indexOf(" ") + 2;
          const h = Number(order.created_on.charAt(index));
          const h1 = Number(order.created_on.charAt(index1));
          let date = order.created_on;
          if (h < 2) {
            if (h1 < 7) {
              item.date = replaceAt(date, h1 + 3, index1);
            } else {
              item.date = replaceAt(date, h1 - 7, index1);
              date = order.created_on;
              item.date = replaceAt(date, h + 1, index);
            }
          } else if (h1 < 2) {
            item.date = replaceAt(date, h1 + 3, index1);
          } else {
            item.date = replaceAt(date, h1 - 1, index1);
            date = order.created_on;
            item.date = replaceAt(date, 0, index);
          }

          keys.forEach((element) => {
            item[element] = order[element];
          });

          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onNotesUpdateHandler("orders", order)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onNotesDeleteHandler("orders", order)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            </>
          );
          Arr.push(item);
        });
        setNewArr(Arr);
      } else {
        setNewArr([]);
      }
    }
  }, [ordersNotesValue]);

  const setArrPayload = (values) => {
    const lastData = values.id ? UpdateRecord(values, notesInitialValues) : values;

    const req = {
      id: values.id,
      file: typeof values.image1 === "object" ? values.image1 : null,
      origin: {
        data: { ...lastData, order_id: ordetId },
        table: "orders_notes",
      },
      getInfo: {
        url: `items/orders_notes?fields=id,note,created_on&filter[order_id]=${ordetId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "ordersNotesValue",
      },
    };
    console.log(req);
    dispatch(addComplexDataAction(req));
  };

  useEffect(() => {
    dispatch(
      fetchDataAction({
        url: `items/orders_notes?fields=id,note,created_on&filter[order_id]=${ordetId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "ordersNotesValue",
      })
    );
  }, [limit]);

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);
      dispatch(
        fetchDataAction({
          url: `items/orders_notes?fields=id,note,created_on&filter[order_id]=${ordetId}&meta=*&limit=${limit}&page=${num}`,
          varName: "ordersNotesValue",
        })
      );
    }
  };

  const handleNotesConfirmDelete = async () => {
    const payload = {
      url: "items/orders_notes",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `items/orders_notes?fields=id,note,created_on&filter[order_id]=${ordetId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "ordersNotesValue",
      },
    };
    dispatch(deleteDataAction(payload));
    setNotesOpenDeleteModal(false);
  };

  const resetForm = () => {
    setInitialValues(dataVal.ordersNotes.initialValues);
    orgData();
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      console.log("iam here");
      resetForm();
    }
  }, [resStatus]);

  if (arrData.length === 0 || newArr === [] || !ordersNotesValueMeta) {
    return null;
  }
  return (
    <Main
      setPageNum={setPageNumber}
      setOpenDeleteModal={setNotesOpenDeleteModal}
      openDeleteModal={notesOpenDeleteModal}
      handleConfirmDelete={handleNotesConfirmDelete}
      name="ordersNotesValue"
      setPayload={setArrPayload}
      data={arrData}
      initialValues={notesInitialValues}
      title="Add attributeValue"
      columns={dataVal.ordersNotes.notesColumns}
      rows={newArr}
      onUpdateHandler={onNotesUpdateHandler}
      onDeleteHandler={onNotesDeleteHandler}
      setFieldSelectedValue={setNotesFieldSelectedValue}
      yupObj={null}
      entriesPerPage={false}
      pageIndex={pageNum - 1}
      count={pageOptions}
    />
  );
};
OrdersNotes.propTypes = {
  ordetId: PropTypes.number.isRequired,
};

export default OrdersNotes;
