import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import PagesCount from "helpers/pages-count";

import { fetchDataAction, addDataAction, deleteDataAction, reset } from "store/features/coreSlice";
import { Icon } from "@mui/material";
import MDSnackbar from "components/MDSnackbar";

const Publishers = () => {
  const columns = [
    { Header: "Title", accessor: "arabic_title", width: "45%", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [initialValues, setInitialValues] = useState({
    id: null,
    english_title: null,
    arabic_title: null,
    countery_id: null,
  });
  const publisherInitialValue = {
    id: null,
    english_title: null,
    arabic_title: null,
    countery_id: null,
  };

  const [newArr, setNewArr] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const { publisher, publisherMeta, resStatus, counteries, limit } = useSelector(
    (state) => state.core
  );
  const dispatch = useDispatch();
  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      english_title: values.english_title,
      arabic_title: values.arabic_title,
      countery_id: values.countery_id,
    });
    setOpen(true);
  };
  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };
  useEffect(() => {
    // if (publisher.length === 0) dispatch(fetchPublishersAction());
    const payload = {
      url: `items/publisher?fields=*,countery_id.id,countery_id.arabic_title&meta=*&limit=${limit}&page=${pageNum}`,
      varName: "publisher",
    };
    dispatch(fetchDataAction(payload));
    dispatch(
      fetchDataAction({
        url: `items/counteries?fields=id,arabic_title,english_title&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "counteries",
      })
    );
    return () => {
      dispatch(reset(null));
    };
  }, []);

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      dispatch(
        fetchDataAction({
          url: `items/publisher?meta=*&limit=${limit}&page=${num}`,
          varName: "publisher",
        })
      );
    }
  };
  const resetForm = () => {
    setInitialValues({
      id: null,
      english_title: null,
      arabic_title: null,
      countery_id: null,
    });
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    if (publisher) {
      let item = {};
      const Arr = [];
      const d = [];
      for (let i = 0; i < PagesCount(+publisherMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      if (publisher && publisher.length !== 0) {
        publisher.forEach((itemVal) => {
          item = {};
          item.arabic_title = itemVal.arabic_title;
          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onUpdateHandler("publisher", itemVal)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("publisher", itemVal)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
            </>
          );
          Arr.push(item);
        });
        setNewArr(Arr);
      }
    }
  }, [publisher]);

  const data = [
    {
      type: "TextField",
      name: "arabic_title",
      id: "arabic_title",
      label: "arabic_title",
      size: 4,
      title: "Publisher",
    },
    {
      type: "TextField",
      name: "english_title",
      id: "english_title",
      label: "english_title",
      size: 4,
      title: "English Title",
    },
    {
      type: "Select",
      optionTitle: "arabic_title",
      name: "countery_id",
      id: "countery_id",
      label: "countery_id",
      data: counteries,
      size: 4,
      title: "Country",
    },
  ];

  const setPayload = (values) => {
    const payload = {
      url: `items/publisher`,
      data: values,
      varName: "publisher",
      getInfo: {
        url: `items/publisher?meta=*&limit=${limit}&page=${pageNum}`,
        varName: "publisher",
      },
    };
    dispatch(addDataAction(payload));
  };

  const setOpenModal = () => {
    setInitialValues({
      id: null,
      english_title: null,
      arabic_title: null,
      countery_id: null,
    });
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: `items/publisher`,
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      varName: "publisher",
      getInfo: {
        url: `items/publisher?meta=*&limit=${limit}&page=${pageNum}`,
        varName: "publisher",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  if (data.length === 0 || newArr === [] || !publisherMeta) {
    return null;
  }
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  return (
    <>
      <Main
        layout="simple"
        allRowsCount={+publisherMeta.filter_count}
        pageIndex={pageNum - 1}
        count={pageOptions}
        report={false}
        setPageNum={setPageNumber}
        openDeleteModal={openDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        setOpenDeleteModal={setOpenDeleteModal}
        setOpen={setOpenModal}
        open={open}
        name="publisher"
        setPayload={setPayload}
        data={data}
        initialValues={initialValues}
        title="Add Publisher"
        columns={columns}
        rows={newArr}
        onUpdateHandler={onUpdateHandler}
        onDeleteHandler={onDeleteHandler}
        defIniVal={publisherInitialValue}
        yupObj={{
          arabic_title: yup.string("Enter your Title").required("Title is required"),
        }}
      />
      {renderSuccessSB}
    </>
  );
};

export default Publishers;
