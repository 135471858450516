import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";
import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import PropTypes from "prop-types";
import UpdateRecord from "helpers/compare";
import dataVal from "../../dataVal.json";

const PageForm = ({ pageNum, url }) => {
  const dispatch = useDispatch();
  const { formproducts, formpublisher, limit } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);
  const [publisherId, setPublisherId] = useState(null);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "publisher",
        id: "publisher",
        label: "publisher",
        data: formpublisher,
        size: 6,
        title: "publisher",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: formproducts,
        size: 6,
        title: "book",
      },
      {
        type: "TextField",
        name: "cost",
        id: "cost",
        label: "cost",
        size: 4,
        title: "price",
      },
      {
        type: "chk",
        name: "is_dolar",
        id: "is_dolar",
        label: "is_dolar",
        size: 4,
        title: "$",
      },
      {
        type: "TextField",
        name: "discount_percentage",
        id: "discount_percentage",
        label: "discount_percentage",
        size: 4,
        title: "discountPercentage",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 12,
        title: "notes",
      },
    ]);
  };

  const resetForm = () => {
    setPublisherId(null);
    orgData();
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "publisher") {
      setPublisherId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/products?fields=id,arabic_title,publisher&filter[publisher]=${values.id}`,
          varName: "formproducts",
        })
      );
    }
  };

  const onChangeSelectInputs = (name, text) => {
    if (name === "publisher") {
      dispatch(
        fetchDataAction({
          url: `items/publisher?fields=id,arabic_title&filter[arabic_title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "formpublisher",
        })
      );
    }

    if (name === "book_id") {
      let urlVal = `items/products?fields=id,arabic_title&filter[arabic_title][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (publisherId) urlVal += `&filter[publisher]=${publisherId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "formproducts",
        })
      );
    }
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      dispatch(
        fetchDataAction({
          url: `items/${element}?limit=${limit}`,
          varName: `form${element}`,
        })
      );
    });
  };

  // const onUpdateHandler = (name, values) => {
  //   if (values.book_id?.publisher) {
  //     onChangeSelectInputs("publisher", values.book_id.publisher.arabic_title);
  //     setFieldSelectedValue("publisher", values.book_id.publisher);
  //   }

  //   setInitialValues({
  //     id: values.id,
  //     book_id: values.book_id,
  //     publisher: values.book_id.publisher,
  //     is_dolar: values.is_dolar,
  //     discount_percentage: values.discount_percentage,
  //     cost: values.cost,
  //     notes: values.notes,
  //   });
  // };

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    delete payloadData.publisher;

    const req = {
      id: values.id,
      file: null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "proposal",
      },
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "proposals",
      },
    };
    if (!payloadData.status) setInitialValues(values);
    dispatch(addComplexDataAction(req));
    resetForm();
  };

  useEffect(() => {
    // if (updateHandlerVal) {
    //   onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    // } else {
    getData(dataVal.oterData);
    // }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: dataVal.oterData }));
    };
  }, []);

  useEffect(() => {
    if (formproducts && formpublisher) orgData();
  }, [formproducts, formpublisher]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        book_id: yup.mixed("required").required("required").nullable(),
        publisher: yup.mixed("required").required("required").nullable(),
        cost: yup
          .number("must be number")
          .required("required")
          .typeError("must be a number")
          .positive("must be greater than zero")
          .nullable(),
        discount_percentage: yup
          .number("must be number")
          .required("required")
          .typeError("must be a number")
          // .positive("must be greater than zero")
          .nullable(),
      }}
      resetData={resetForm}
      name="proposals"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  // updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  // updateHandlerVal: null,
};
export default PageForm;
