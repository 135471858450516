import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import UpdateRecord from "helpers/compare";
import DataForm from "components/UI/form/DataForm";
import { directusRoleId } from "constants";

import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const PageForm = ({ updateHandlerVal, pageNum, userId, url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.auth);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const { formjobtitles, formlibraries, formstores, limit } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);

  const rePrepareData = (items, values) => {
    items.forEach((x) => {
      if (
        x.name === "libraries" ||
        x.name === "stores" ||
        x.name === "all_libraries" ||
        x.name === "all_stores"
      )
        x.condition = false;
    });

    values.forEach((element) => {
      if (
        element.job_title_id?.routes_id.filter((y) => y.route_name === "distribution").length > 0 ||
        element.routes_id?.filter((y) => y.route_name === "distribution").length > 0
      )
        items.forEach((x) => {
          if (x.name === "libraries") x.condition = true;
          if (x.name === "all_libraries") x.condition = true;
        });
      if (
        element.job_title_id?.routes_id.filter((y) => y.route_name === "stores").length > 0 ||
        element.routes_id?.filter((y) => y.route_name === "stores").length > 0
      )
        items.forEach((x) => {
          if (x.name === "stores") x.condition = true;
          if (x.name === "all_stores") x.condition = true;
        });
    });
    setData(items);
  };

  const orgData = () => {
    let dataArr = [];
    if (userId) {
      dataArr = [
        {
          type: "TextField",
          name: "first_name",
          id: "first_name",
          label: "first_name",
          size: 3,
          title: "fullName",
        },
        {
          type: "TextField",
          name: "last_name",
          id: "last_name",
          label: "forgnFulllName",
          size: 3,
          title: "lastName",
        },
        {
          type: "TextField",
          name: "email",
          id: "email",
          label: "email",
          size: 3,
          title: "email",
        },
        {
          type: "TextField",
          name: "password",
          id: "password",
          label: "password",
          size: 3,
          title: "password",
        },
        {
          type: "TextField",
          name: "phone",
          id: "phone",
          label: "phone",
          size: 3,
          title: "phone",
        },
        {
          type: "Img",
          name: "avatar",
          id: "avatar",
          label: "avatar",
          size: 4,
          title: "image",
        },
      ];
    } else {
      dataArr = [
        {
          type: "TextField",
          name: "first_name",
          id: "first_name",
          label: "first_name",
          size: 3,
          title: "fullName",
        },
        {
          type: "TextField",
          name: "last_name",
          id: "last_name",
          label: "last_name",
          size: 3,
          title: "forgnFullName",
        },
        {
          type: "TextField",
          name: "email",
          id: "email",
          label: "email",
          size: 3,
          title: "email",
        },
        {
          type: "TextField",
          name: "password",
          id: "password",
          label: "password",
          size: 3,
          title: "password",
        },
        {
          type: "MSelect",
          optionTitle: "title",
          name: "job_titles",
          id: "job_titles",
          label: "job_titles",
          data: formjobtitles,
          size: 3,
          title: t("jobTitles"),
        },
        {
          type: "TextField",
          name: "phone",
          id: "phone",
          label: "phone",
          size: 3,
          title: "phone",
        },
        {
          type: "MSelect",
          optionTitle: "title",
          name: "stores",
          id: "stores",
          label: "stores",
          data: formstores,
          condition: false,
          size: 3,
          title: t("stores"),
        },
        {
          type: "chk",
          name: "all_stores",
          id: "all_stores",
          label: "all_stores",
          size: 3,
          title: t("allStores"),
          condition: false,
        },
        {
          type: "MSelect",
          optionTitle: "title",
          name: "libraries",
          id: "libraries",
          label: "libraries",
          data: formlibraries,
          condition: false,
          size: 3,
          title: t("libraries"),
        },
        {
          type: "chk",
          name: "all_libraries",
          id: "all_libraries",
          label: "all_libraries",
          size: 3,
          title: t("allLibraries"),
          condition: false,
        },
        {
          type: "Img",
          name: "avatar",
          id: "avatar",
          label: "avatar",
          size: 3,
          title: "image",
        },
      ];
    }

    if (updateHandlerVal) rePrepareData(dataArr, updateHandlerVal.value.job_titles);
    else setData(dataArr);
  };

  const resetForm = () => {};

  const setFieldSelectedValue = (name, values) => {
    if (name === "job_titles") {
      rePrepareData(data, values);
    }
  };

  const getData = (name, val) => {
    let txt = "";
    if (name === "job_titles") {
      val.forEach((element) => {
        txt = txt ? `${txt},${element.id}` : element.id;
      });
      console.log(txt, name, val);
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title,routes_id.route_name&filter[id][in]=${txt}&limit=${limit}`,
          varName: `formjobtitles`,
        })
      );
    } else if (name === "libraries") {
      val.forEach((element) => {
        txt = txt ? `${txt},${element.id}` : element.id;
      });
      dispatch(
        fetchDataAction({
          url:
            txt !== ""
              ? `items/libraries?fields=id,title&filter[id][in]=${txt}&limit=${limit}`
              : `items/libraries?fields=id,title&limit=${limit}`,
          varName: "formlibraries",
        })
      );
    } else if (name === "stores") {
      val.forEach((element) => {
        txt = txt ? `${txt},${element.id}` : element.id;
      });
      dispatch(
        fetchDataAction({
          url:
            txt !== ""
              ? `items/stores_places?fields=id,title&filter[id][in]=${txt}&limit=${limit}`
              : `items/stores_places?fields=id,title&limit=${limit}`,
          varName: "formstores",
        })
      );
    }
  };

  const getInitData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "jobtitles") {
        dispatch(
          fetchDataAction({
            url: `items/job_title?fields=id,title,routes_id.route_name&limit=${limit}`,
            varName: `formjobtitles`,
          })
        );
      } else if (element === "stores") {
        dispatch(
          fetchDataAction({
            url: "items/stores_places?fields=id,title",
            varName: `form${element}`,
          })
        );
      } else {
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,title&limit=${limit}`,
            varName: `form${element}`,
          })
        );
      }
    });
  };

  const onChangeSelectInputs = (name, text) => {
    if (name === "job_titles") {
      dispatch(
        fetchDataAction({
          url: `items/job_title?fields=id,title,routes_id.route_name&filter[title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "formjobtitles",
        })
      );
    } else if (name === "libraries") {
      dispatch(
        fetchDataAction({
          url: `items/libraries?fields=id,title&filter[title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "formlibraries",
        })
      );
    } else if (name === "stores") {
      dispatch(
        fetchDataAction({
          url: `items/stores_places?fields=id,title&filter[title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "formstores",
        })
      );
    }
  };

  const onUpdateHandler = (name, values, copy) => {
    const jobTitlesData = [];
    const librariesData = [];
    const storesData = [];

    values.job_titles.forEach((element) => {
      jobTitlesData.push(element.job_title_id);
    });
    values.libraries.forEach((element) => {
      librariesData.push(element.library_id);
    });
    values.stores.forEach((element) => {
      storesData.push(element.store_id);
    });

    getData("job_titles", jobTitlesData);
    getData("libraries", librariesData);
    getData("stores", storesData);

    setInitialValues({
      id: copy ? null : values.id,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      all_libraries: values.all_libraries,
      all_stores: values.all_stores,
      password: null,
      avatar: values.avatar && values.avatar.data?.full_url,
      job_titles: jobTitlesData,
      stores: storesData,
      libraries: librariesData,
    });
  };

  const setPayload = ({ files, ...values }) => {
    const payloadData = {};
    let jobTitleArr = [];
    let storeArr = [];
    let libraryArr = [];
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;
    if (values.id === null) {
      jobTitleArr = values.job_titles.length > 0 ? { add: values.job_titles } : null;
      storeArr = values.stores?.length > 0 ? { add: values.stores } : null;
      libraryArr = values.libraries?.length > 0 ? { add: values.libraries } : null;
    } else {
      jobTitleArr = lastData?.job_titles || null;
      storeArr = lastData?.stores || null;
      libraryArr = lastData?.libraries || null;
    }
    // delete lastData.status;
    delete lastData.job_titles;
    delete lastData.stores;
    delete lastData.libraries;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });
    const req = {
      updateMyProfile: !!userId,
      imgFieldName: "avatar",
      id: values.id,
      file: typeof values.avatar === "object" ? values.avatar : null,
      origin: {
        data:
          Object.keys(payloadData).length === 0
            ? null
            : { role: directusRoleId, admin_id: userInfo.admin_id, ...payloadData },
        table: "directus_users",
      },
      related: [
        {
          table: "users_job_title",
          data: jobTitleArr,
          column: "job_title_id",
          origin_column: "user_id",
        },
        {
          table: "users_stores",
          data: storeArr,
          column: "store_id",
          origin_column: "user_id",
        },
        {
          table: "users_libraries",
          data: libraryArr,
          column: "library_id",
          origin_column: "user_id",
        },
      ],
      getInfo: {
        url: `${url}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "users",
      },
    };
    setInitialValues(values);

    dispatch(addComplexDataAction(req));
  };

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value, updateHandlerVal.isCopy);
    } else {
      getInitData(dataVal.oterData);
    }
    return () => {
      resetForm();
      dispatch(reset({ alias: "form", data: dataVal.oterData }));
    };
  }, []);

  useEffect(() => {
    if (formjobtitles && formlibraries && formstores) {
      if (data !== []) orgData();
    }
  }, [formjobtitles, formlibraries, formstores]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        first_name: yup
          .string("Enter your first name")
          .required("first name is required")
          .nullable(),
        email: yup.string("required").email("wrong email").required("required").nullable(),
        phone: yup
          .string("required")
          .matches(phoneRegExp, "Phone number is not valid")
          .required("required")
          .nullable(),
        job_titles: yup.array().min(1, "at least 1").required("required"),
      }}
      resetData={resetForm}
      name="products"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeSelectInputs}
      defaultInitialValues={dataVal.initialValues}
    />
  );
};

PageForm.propTypes = {
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  userId: PropTypes.string,
  url: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
  userId: null,
};
export default PageForm;
