import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import coreReducer from "./features/coreSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    core: coreReducer,
  },
});
export default store;
