import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTypography from "components/MDTypography";
import Page from "components/UI/Page";
import { Card, Icon } from "@mui/material";
import { useLocation } from "react-router-dom";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import Avatar from "components/UI/Table/avatar";
import MDButton from "components/MDButton";
import DataModal from "components/UI/DataModal";
import DataTable from "components/UI/Table/DataTable";
import PagesCount from "helpers/pages-count";
import { useTranslation } from "react-i18next";
import PageForm from "./components/PageForm";
import dataVal from "./dataVal.json";
import FilterForm from "./components/FilterForm";

const InsertUsers = ({ userId }) => {
  const baseUrl = `users?fields=*,avatar.data.full_url,role.name,role.id,job_titles.job_title_id.routes_id.route_name,job_titles.job_title_id.id,job_titles.job_title_id.title,stores.store_id.id,stores.store_id.title,libraries.library_id.id,libraries.library_id.title`;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { usersMeta, users, limit, simbleLayout } = useSelector((state) => state.core);

  const [url, setUrl] = useState(baseUrl);
  const [newArr, setNewArr] = useState([]);
  const [number, setNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [route, setRoute] = useState(null);
  const [pageOptions, setPageOption] = useState([]);
  const [formContainer, setFormContainer] = useState(<h1>wait ...</h1>);

  useEffect(() => {
    if (userId) {
      let str = baseUrl;
      str += `&filter[id]=${userId}`;

      dispatch(
        fetchDataAction({
          url: `${str}&meta=*&limit=${limit}&page=${1}`,
          varName: "users",
        })
      );
    }
  }, [userId]);

  const resetForm = () => {
    // setFormContainer(<h1>wait ...</h1>);
  };

  const setOpenModal = (container) => {
    resetForm();
    setFormContainer(container);
    setOpen(true);
  };

  const handleOpen = () =>
    setOpenModal(
      <PageForm
        resetData={resetForm}
        updateHandlerVal={null}
        pageNum={number}
        url={url}
        userId={userId}
      />
    );

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+usersMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (users && users.length !== 0) {
      users.forEach((user) => {
        item = {};
        keys = Object.keys(user);
        keys.forEach((element) => {
          item[element] = user[element];
        });
        item.title = (
          <Avatar
            dir="ltr"
            image={user.avatar && `${user.avatar.data?.full_url}`}
            name={`${user.first_name}` || ""}
            email={user.email}
          />
        );

        if (route?.update || userId) {
          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => {
                  if (simbleLayout) {
                    setOpenModal(
                      <PageForm
                        resetData={resetForm}
                        updateHandlerVal={{ name: "users", value: user }}
                        pageNum={number}
                        url={url}
                        userId={userId}
                      />
                    );
                  }
                }}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              {!userId && (
                <MDTypography
                  style={{ padding: 20, color: "#3b00ee" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => {
                    if (simbleLayout) {
                      setOpenModal(
                        <PageForm
                          resetData={resetForm}
                          updateHandlerVal={{ name: "users", value: user, isCopy: true }}
                          pageNum={number}
                          url={url}
                          userId={userId}
                        />
                      );
                    }
                  }}
                >
                  <Icon fontSize="small">content_copy</Icon>
                </MDTypography>
              )}
            </>
          );
        }
        Arr.push(item);
      });
      setNewArr(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setNumber(num);
      dispatch(
        fetchDataAction({
          url: `${url}&meta=*&limit=${limit}&page=${num}`,
          varName: "users",
        })
      );
    }
  };

  const setSortByHandeller = (val) => {
    let urlVal = `${url}&meta=*&limit=${limit}&page=${number}`;

    urlVal += `&sort=${val.desc ? "-" : ""}${val.id}`;
    dispatch(
      fetchDataAction({
        url: urlVal,
        varName: "users",
      })
    );
  };

  useEffect(() => {
    const routeItem = userInfo.routes.filter(
      (x) =>
        `${x.route_name}` === location.pathname.split("/")[location.pathname.split("/").length - 1]
    )[0];
    setRoute(routeItem);
  }, []);

  useEffect(() => {
    if (users && (userId || route)) {
      prepareTableRows();
    }
  }, [users, route]);

  if (newArr === []) {
    return null;
  }
  const renderItem = (
    <>
      <DataModal
        handleClose={() => {
          resetForm();
          setOpen(false);
        }}
        open={open}
      >
        {formContainer}
      </DataModal>
      <DataTable
        table={{ columns: dataVal.columns, rows: newArr }}
        item={false}
        pageOptions={pageOptions}
        isSorted
        isPagination
        entriesPerPage
        showTotalEntries
        noEndBorder
        search
        setPageNum={setPageNumber}
        setSortByHandeller={setSortByHandeller}
        pageIndex={number - 1}
        allRowsCount={usersMeta ? +usersMeta.filter_count : 0}
      />
    </>
  );
  return userId ? (
    renderItem
  ) : (
    <Page>
      <Card style={{ padding: 20, position: "relative" }}>
        <FilterForm
          pageNum={number}
          baseUrl={baseUrl}
          setUrl={setUrl}
          userId={userId}
          setNumber={setNumber}
        />
        {usersMeta && route?.create && !userId && (
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpen}
            style={{ float: "left" }}
            size="large"
          >
            <Icon>add</Icon>&nbsp; {t("add")}
          </MDButton>
        )}
        {renderItem}
      </Card>
    </Page>
  );
};

InsertUsers.propTypes = {
  userId: PropTypes.string,
};

InsertUsers.defaultProps = {
  userId: null,
};

export default InsertUsers;
