import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";
import dataVal from "../../dataVal.json";

const FilterForm = ({ setNumber, setUrl, baseUrl }) => {
  const dispatch = useDispatch();
  const { filterproducts, filterpublisher, limit, filterstores, filterlibraries, filterbuying } =
    useSelector((state) => state.core);
  const { userInfo } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [filteredValues, setFilteredValues] = useState(null);
  const [publisherId, setPublisherId] = useState(null);
  const [bookId, setBookId] = useState(null);
  const [initialValues, setInitialValues] = useState(dataVal.initialValuesFilter);
  const [buyingId, setBuyingId] = useState(null);

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "publisher",
        id: "publisher",
        label: "publisher",
        data: filterpublisher,
        size: 3,
        title: "publisher",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "book_id",
        id: "book_id",
        label: "book_id",
        data: filterproducts,
        size: 3,
        title: "book",
      },
      {
        type: "Select",
        optionTitle: "buying_date",
        name: "buying_id",
        id: "buying_id",
        label: "buying_id",
        data: filterbuying,
        size: 3,
        title: "buying",
      },
      {
        type: "Select",
        optionTitle: "place_id",
        oterOptionTitle: "title",
        name: "store_id",
        id: "store_id",
        label: "store_id",
        data: filterstores,
        size: 3,
        title: "store",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "library_id",
        id: "library_id",
        label: "library_id",
        data: filterlibraries,
        size: 3,
        title: "libraries",
      },
      {
        type: "Date",
        name: "distributionDateFrom",
        id: "distributionDateFrom",
        label: "distributionDateFrom",
        size: 3,
        title: "distributionDateFrom",
      },
      {
        type: "Date",
        name: "distributionDateTo",
        id: "distributionDateTo",
        label: "distributionDateTo",
        size: 3,
        title: "distributionDateTo",
      },
    ]);
  };

  useEffect(() => {
    if (filterproducts && filterpublisher && filterbuying && filterstores && filterlibraries)
      orgData();
  }, [filterproducts, filterpublisher, filterbuying, filterstores, filterlibraries]);

  const generateUrl = (values) => {
    let str = baseUrl;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (element === "publisher") {
          str += `&filter[store_id.buying_id.book_id.publisher.id]=${values[element].id}`;
        } else if (typeof values[element] === "object") {
          if (element === "book_id")
            str += `&filter[store_id.buying_id.${element}.id]=${values[element].id}`;
          else if (element === "buying_id")
            str += `&filter[store_id.${element}.id]=${values[element].id}`;
          else str += `&filter[${element}.id]=${values[element].id}`;
        } else if (element !== "distributionDateFrom" && element !== "distributionDateTo") {
          str += `&filter[${element}]=${values[element]}`;
        }
      }
    });

    if (values.distributionDateFrom && values.distributionDateTo) {
      str += `&filter[distribution_date][between]=${values.distributionDateFrom},${values.distributionDateTo}`;
    }
    return str;
  };

  const setFieldSelectedValue = (name, values) => {
    if (name === "publisher") {
      setPublisherId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/products?fields=id,arabic_title,publisher&filter[publisher]=${values.id}`,
          varName: "filterproducts",
        })
      );
    } else if (name === "book_id") {
      setBookId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/buying?fields=id,buying_date,notes&filter[book_id]=${values.id}`,
          varName: "filterbuying",
        })
      );
      dispatch(
        fetchDataAction({
          url: `items/proposal?fields=id,cost,discount_percentage,is_dolar&filter[status]=published&filter[book_id]=${values.id}`,
          varName: "filterproposals",
        })
      );
    } else if (name === "buying_id") {
      setBuyingId(values.id);
      dispatch(
        fetchDataAction({
          url: `items/stores?fields=id,place_id.title,place_id.id,count,distributions.count,gifts.count,gifts.id,distributions.returns.count,distributions.id,buying_id.buying_date&filter[buying_id]=${values.id}`,
          varName: "filterstores",
        })
      );
    }
  };

  const onChangeHandler = (name, text) => {
    let urlVal;
    if (name === "publisher") {
      dispatch(
        fetchDataAction({
          url: `items/publisher?fields=id,arabic_title&filter[arabic_title][${
            text ? `contains]=${text}` : "nempty]=true"
          }&limit=${limit}`,
          varName: "filterpublisher",
        })
      );
    } else if (name === "book_id") {
      urlVal = `items/products?fields=id,arabic_title&filter[arabic_title][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (publisherId) urlVal += `&filter[publisher]=${publisherId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterproducts",
        })
      );
    } else if (name === "buying_id") {
      urlVal = `items/buying?fields=id,buying_date,notes&filter[buying_date][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (bookId) urlVal += `&filter[book_id]=${bookId}`;

      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterbuying",
        })
      );
    } else if (name === "store_id") {
      urlVal = `items/stores?fields=id,place_id.title,place_id.id,count,distributions.count,gifts.count,gifts.id,distributions.returns.count,distributions.id,buying_id.buying_date&filter[place_id.title][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (buyingId) urlVal += `&filter[buying_id]=${buyingId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterstores",
        })
      );
    } else if (name === "library_id") {
      urlVal = `items/libraries?filter[title][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (!userInfo.all_libraries) urlVal += `&filter[users.user_id][eq]=${userInfo.id}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterlibraries",
        })
      );
    } else if (name === "proposal_id") {
      urlVal = `items/proposal?fields=id,cost,discount_percentage,is_dolar&filter[status]=published&filter[cost][${
        text ? `contains]=${text}` : "nempty]=true"
      }&limit=${limit}`;
      if (bookId) urlVal += `&filter[book_id]=${bookId}`;
      dispatch(
        fetchDataAction({
          url: urlVal,
          varName: "filterproposals",
        })
      );
    }
  };

  const setPayload = (values) => {
    setFilteredValues(values);
    setNumber(1);
    if (values) setInitialValues(values);
    setUrl(generateUrl(values));
    dispatch(
      fetchDataAction({
        url: `${generateUrl(values)}&meta=*&limit=${limit}&page=${1}`,
        varName: "distributions",
      })
    );
  };

  const getData = (dataNeeded) => {
    dataNeeded.forEach((element) => {
      if (element === "proposal")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,cost,discount_percentage,is_dolar&filter[status]=published&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      else if (element === "buying")
        dispatch(
          fetchDataAction({
            url: `items/${element}?fields=id,buying_date,notes&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      else if (element === "stores")
        dispatch(
          fetchDataAction({
            url: `items/stores?fields=id,place_id.title,place_id.id,count,distributions.count,gifts.count,gifts.id,distributions.returns.count,distributions.id,buying_id.buying_date&limit=${limit}`,
            varName: `filter${element}`,
          })
        );
      else if (element === "libraries") {
        let urlVal = `items/libraries?limit=${limit}`;
        if (!userInfo.all_libraries) urlVal += `&filter[users.user_id][eq]=${userInfo.id}`;
        dispatch(
          fetchDataAction({
            url: urlVal,
            varName: `filter${element}`,
          })
        );
      } else
        dispatch(
          fetchDataAction({
            url: `items/${element}?limit=${limit}`,
            varName: `filter${element}`,
          })
        );
    });
  };
  useEffect(() => {
    getData(dataVal.oterData);
  }, []);

  useEffect(() => {
    setPayload(filteredValues || initialValues);
  }, [limit]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      name="researchFilter"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={onChangeHandler}
    />
  );
};

FilterForm.propTypes = {
  setUrl: PropTypes.func.isRequired,
  setNumber: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
