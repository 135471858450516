import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import UpdateRecord from "helpers/compare";
import DataForm from "components/UI/form/DataForm";
import PropTypes from "prop-types";

import { addComplexDataAction, fetchDataAction, reset } from "store/features/coreSlice";
import dataVal from "../dataVal.json";

const PageForm = ({ resetData, updateHandlerVal, pageNum, baseUrl }) => {
  const dispatch = useDispatch();

  const {
    authorsData,
    publishersData,
    brandsData,
    bookTypeIdData,
    languagesData,
    currenciesData,
    categoriesData,
    tagsData,
    limit,
  } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  const [libraryCategories, setLibraryCategories] = useState([]);
  const [pharmacyCategories, setPharmacyCategories] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "arabic_title",
        id: "arabic_title",
        label: "arabic_title",
        size: 3,
        title: "Arabic Title",
      },
      {
        type: "TextField",
        name: "english_title",
        id: "english_title",
        label: "english_title",
        size: 3,
        title: "English Title",
      },
      {
        type: "TextField",
        name: "sku",
        id: "sku",
        label: "sku",
        size: 3,
        title: "sku",
      },
      {
        type: "TextField",
        name: "isbn",
        id: "isbn",
        label: "isbn",
        size: 3,
        title: "isbn",
      },
      {
        type: "TextArea",
        name: "arabic_description",
        id: "arabic_description",
        label: "arabic_description",
        maxRows: 4,
        minRows: 4,
        size: 6,
        title: "Arabic Description",
      },
      {
        type: "TextArea",
        name: "english_description",
        id: "english_description",
        label: "english_description",
        maxRows: 4,
        minRows: 4,
        size: 6,
        title: "English Description",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "status",
        id: "status",
        label: "status",
        data: dataVal.statuses,
        size: 6,
        title: "Product Status",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "type",
        id: "type",
        label: "type",
        data: dataVal.types,
        size: 6,
        title: "Types",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "genre",
        id: "genre",
        label: "genre",
        data: dataVal.genres,
        size: 6,
        title: "Product Genre",
      },
      {
        type: "MSelect",
        optionTitle: "arabic_title",
        name: "tagsData",
        id: "tagsData",
        label: "tagsData",
        data: tagsData,
        size: 6,
        title: "Tags",
      },
      {
        type: "MSelect",
        optionTitle: "arabic_title",
        name: "libraryCategories",
        id: "libraryCategories",
        label: "libraryCategories",
        data: libraryCategories,
        size: 6,
        title: "Library Categories",
      },
      {
        type: "MSelect",
        optionTitle: "arabic_title",
        name: "pharmacyCategories",
        id: "pharmacyCategories",
        label: "pharmacyCategories",
        data: pharmacyCategories,
        size: 6,
        title: "Pharmacy Categories",
      },
      // genre === item ? brand_id : book_type_id    publisher     authorsData      translators     book_language    years    pages

      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "brand_id",
        id: "brand_id",
        label: "brand_id",
        data: brandsData,
        size: 4,
        title: "Brand",
        condition: false,
      },

      // genre : book
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "book_type_id",
        id: "book_type_id",
        label: "book_type_id",
        data: bookTypeIdData,
        size: 6,
        title: "Book Type",
        condition: false,
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "publisher",
        id: "publisher",
        label: "publisher",
        data: publishersData,
        size: 6,
        title: "Publishers",
        condition: false,
      },
      {
        type: "MSelect",
        optionTitle: "arabic_title",
        name: "authorsData",
        id: "authorsData",
        label: "authorsData",
        data: authorsData,
        size: 6,
        title: "Authors",
        condition: false,
      },
      {
        type: "MSelect",
        optionTitle: "arabic_title",
        name: "translators",
        id: "translators",
        label: "translators",
        data: authorsData,
        size: 6,
        title: "Translators",
        condition: false,
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "book_language",
        id: "book_language",
        label: "book_language",
        data: languagesData,
        size: 6,
        title: "Book Language",
        condition: false,
      },
      {
        type: "Select",
        optionTitle: "name",
        name: "years",
        id: "years",
        label: "years",
        data: dataVal.years,
        size: 6,
        title: "Years",
        condition: false,
      },
      {
        type: "TextField",
        name: "pages",
        id: "pages",
        label: "pages",
        size: 3,
        title: "Pages",
        condition: false,
      },

      // type === simple ?actual_price   discounted_price   currency    quantity    stock_quantity      dimensions   shipping_weight      weight_meg     height      height_mes   :
      {
        type: "TextField",
        name: "actual_price",
        id: "actual_price",
        label: "actual_price",
        size: 4,
        title: "Actual Price",
        condition: false,
      },
      {
        type: "TextField",
        name: "discounted_price",
        id: "discounted_price",
        label: "discounted_price",
        size: 4,
        title: "Discounted Price",
        condition: false,
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "currency",
        id: "currency",
        label: "currency",
        data: currenciesData,
        size: 4,
        title: "Currency",
        condition: false,
      },
      {
        type: "TextField",
        name: "quantity",
        id: "quantity",
        label: "quantity",
        size: 4,
        title: "Quantity",
        condition: false,
      },
      {
        type: "TextField",
        name: "stock_quantity",
        id: "stock_quantity",
        label: "stock_quantity",
        size: 4,
        title: "Stock Quantity",
        condition: false,
      },
      {
        type: "TextField",
        name: "dimensions",
        id: "dimensions",
        label: "dimensions",
        size: 4,
        title: "Dimensions",
        condition: false,
      },
      {
        type: "TextField",
        name: "shipping_weight",
        id: "shipping_weight",
        label: "shipping_weight",
        size: 3,
        title: "Shipping Weight",
        condition: false,
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "weight_meg",
        id: "weight_meg",
        label: "weight_meg",
        data: dataVal.weightMeg,
        size: 3,
        title: "Weight Meg",
        condition: false,
      },
      {
        type: "TextField",
        name: "height",
        id: "height",
        label: "height",
        size: 3,
        title: "Shipping Height",
        condition: false,
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "height_mes",
        id: "height_mes",
        label: "height_mes",
        data: dataVal.heightMes,
        size: 3,
        title: "Height Mes",
        condition: false,
      },

      // all
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 8,
        title: "Notes",
      },
      {
        type: "Img",
        name: "image",
        id: "image",
        label: "image",
        size: 4,
        title: "Image",
      },
    ]);
  };

  const resetForm = () => {
    setInitialValues(dataVal.initialValues);
    orgData();
    resetData();
  };

  const setFieldSelectedValue = (name, values, isUpdate = false, updateCode) => {
    console.log(name, values, isUpdate, updateCode);
    const { bookArr, typesArr } = dataVal;
    let dataArr = data;
    if (!isUpdate) {
      if (name === "type") {
        if (values.title === "Simple") {
          dataArr = data.map((x) => (typesArr.includes(x.name) ? { ...x, condition: true } : x));
        } else if (values.title === "Has Variations") {
          dataArr = data.map((x) => (typesArr.includes(x.name) ? { ...x, condition: false } : x));
        }
      } else if (name === "genre") {
        if (values.title === "Item") {
          dataArr = data.map((x) => {
            let item1;
            if (x.name === "brand_id") {
              item1 = { ...x, condition: true };
            } else if (bookArr.includes(x.name)) {
              item1 = { ...x, condition: false };
            } else {
              item1 = x;
            }
            return item1;
          });
        } else if (values.title === "Book") {
          dataArr = data.map((x) => {
            let item2;
            if (x.name === "brand_id") {
              item2 = { ...x, condition: false };
            } else if (bookArr.includes(x.name)) {
              item2 = { ...x, condition: true };
            } else {
              item2 = x;
            }
            return item2;
          });
        }
      }
    } else {
      let i;
      switch (updateCode) {
        case 1:
          dataArr = data.map((x) => {
            if (typesArr.includes(x.name) || x.name === "brand_id") {
              i = { ...x, condition: true };
            } else if (bookArr.includes(x.name)) {
              i = { ...x, condition: false };
            } else i = x;
            return i;
          });
          break;

        case 2:
          dataArr = data.map((x) => {
            if (typesArr.includes(x.name) || bookArr.includes(x.name)) {
              i = { ...x, condition: true };
            } else if (x.name === "brand_id") {
              i = { ...x, condition: false };
            } else i = x;
            return i;
          });
          break;

        case 3:
          dataArr = data.map((x) => {
            if (typesArr.includes(x.name) || x.name === "brand_id") {
              i = { ...x, condition: false };
            } else if (bookArr.includes(x.name)) {
              i = { ...x, condition: true };
            } else i = x;
            return i;
          });
          break;

        case 4:
          dataArr = data.map((x) => {
            if (typesArr.includes(x.name) || x.name === "brand_id") {
              i = { ...x, condition: false };
            } else if (bookArr.includes(x.name)) {
              i = { ...x, condition: true };
            } else i = x;
            return i;
          });
          break;

        case 5:
          dataArr = data.map((x) => {
            i = { ...x, condition: false };
            return i;
          });
          break;

        default:
          break;
      }
    }
    setData(dataArr);
  };

  const onUpdateHandler = (name, values) => {
    const tagsDataData = [];
    const authorsDataData = [];
    const translatorsData = [];
    const pharmacyCategoriesData = [];
    const libraryCategoriesData = [];

    if (values.authorsData && values.authorsData.length > 0)
      values.authorsData
        .filter((c) => authorsData.filter((d) => d.id === c.author_id.id))
        .forEach((element) => {
          authorsDataData.push(element.author_id);
        });

    if (values.translators && values.translators.length > 0)
      values.translators
        .filter((c) => authorsData.filter((d) => d.id === c.author_id.id))
        .forEach((element) => {
          translatorsData.push(element.author_id);
        });

    if (values.tagsData && values.tagsData.length > 0)
      values.tagsData
        .filter((c) => tagsData.filter((d) => d.id === c.tag_id.id))
        .forEach((element) => {
          tagsDataData.push(element.tag_id);
        });

    if (values.categoriesData && values.categoriesData.length > 0) {
      values.categoriesData.forEach((element) => {
        pharmacyCategories
          .filter((v) => v.id === element.category_id.id)
          .forEach((item) => {
            pharmacyCategoriesData.push(item);
          });
      });
      values.categoriesData.forEach((element) => {
        libraryCategories
          .filter((v) => v.id === element.category_id.id)
          .forEach((item) => {
            libraryCategoriesData.push(item);
          });
      });
    }

    setInitialValues({
      id: values.id,
      type: dataVal.types.filter((type) => type.value === values.type)[0],
      genre: dataVal.genres.filter((genre) => genre.value === values.genre)[0],
      weight_meg: dataVal.weightMeg.filter((meg) => meg.value === values.weight_meg)[0],
      height_mes: dataVal.heightMes.filter((meg) => meg.value === values.height_mes)[0],
      english_title: values.english_title,
      arabic_title: name === "reset" ? null : values.arabic_title,
      english_description: values.english_description,
      arabic_description: values.arabic_description,
      discounted_price: values.discounted_price,
      actual_price: values.actual_price,
      isbn: values.isbn,
      sku: values.sku,
      quantity: values.quantity,
      stock_quantity: values.stock_quantity,
      image: values.image?.data.full_url,
      dimensions: values.dimensions,
      height: values.height,
      shipping_weight: values.shipping_weight,
      book_language: values.book_language,
      brand_id: values.brand_id ? values.brand_id : null,
      book_type_id: values.book_type_id,
      publisher: values.publisher,
      sequence: values.sequence,
      years: dataVal.years.filter((year) => year.value === values.year)[0],
      pages: values.pages,
      currency: currenciesData.filter((currency) => currency.id === values.currency?.id)[0],
      status: dataVal.statuses.filter((status) => status.value === values.status)[0],
      notes: values.notes,
      authorsData: authorsDataData,
      tagsData: tagsDataData,
      translators: translatorsData,
      pharmacyCategories: pharmacyCategoriesData,
      libraryCategories: libraryCategoriesData,
    });

    let u;
    if (name !== "reset" || (dataVal.types && dataVal.genre)) {
      if (
        dataVal.types.filter((type) => type.value === values.type)[0].title === "Simple" &&
        dataVal.genres.filter((genre) => genre.value === values.genre)[0].title === "Item"
      ) {
        u = 1;
      } else if (
        dataVal.types.filter((type) => type.value === values.type)[0].title === "Simple" &&
        dataVal.genres.filter((genre) => genre.value === values.genre)[0].title === "Book"
      ) {
        u = 2;
      } else if (
        dataVal.types.filter((type) => type.value === values.type)[0].title === "With Variables" &&
        dataVal.genres.filter((genre) => genre.value === values.genre)[0].title === "Item"
      ) {
        u = 3;
      } else u = 4;

      setFieldSelectedValue(
        "type",
        dataVal.types.filter((type) => type.value === values.type)[0].title,
        true,
        u
      );
    }
  };

  const setPayload = (values) => {
    const payloadData = {};
    let authorsDataArr = [];
    let translatorsArr = [];
    let pharmacyCategoriesArr = [];
    let libraryCategoriesArr = [];
    let tagsDataArr = [];
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;
    if (initialValues.type?.value !== values.type?.value) payloadData.type = values.type.value;
    if (initialValues.genre?.value !== values.genre?.value) payloadData.genre = values.genre.value;
    if (initialValues.status?.value !== values.status?.value)
      payloadData.status = values.status.value;
    if (initialValues.years?.name !== values.years?.name) payloadData.years = values.years.name;
    if (initialValues.weight_meg?.value !== values.weight_meg?.value)
      payloadData.weight_meg = values.weight_meg?.value;
    if (initialValues.height_mes?.value !== values.height_mes?.value)
      payloadData.height_mes = values.height_mes?.value;

    let categoriesDataArr = [];
    if (values.id === null) {
      authorsDataArr = values.authorsData.length > 0 ? { add: values.authorsData } : null;
      translatorsArr = values.translators.length > 0 ? { add: values.translators } : null;
      pharmacyCategoriesArr =
        values.pharmacyCategories.length > 0 ? values.pharmacyCategories : null;
      libraryCategoriesArr = values.libraryCategories.length > 0 ? values.libraryCategories : null;
      tagsDataArr = values.tagsData.length > 0 ? { add: values.tagsData } : null;
      categoriesDataArr =
        !pharmacyCategoriesArr && !libraryCategoriesArr
          ? null
          : {
              add:
                pharmacyCategoriesArr.length > 0
                  ? pharmacyCategoriesArr.concat(libraryCategoriesArr)
                  : libraryCategoriesArr,
            };
    } else {
      authorsDataArr = lastData?.authorsData || null;
      translatorsArr = lastData?.translators || null;
      pharmacyCategoriesArr = lastData?.pharmacyCategories || null;
      libraryCategoriesArr = lastData?.libraryCategories || null;
      tagsDataArr = lastData?.tagsData || null;

      let categoriesDataArrAdd = [];
      let categoriesDataArrRemove = [];
      if (pharmacyCategoriesArr?.add.length > 0 || libraryCategoriesArr?.add.length > 0) {
        if (!pharmacyCategoriesArr) {
          categoriesDataArrAdd = libraryCategoriesArr.add;
          categoriesDataArrRemove = libraryCategoriesArr.remove;
        } else if (!libraryCategoriesArr) {
          categoriesDataArrAdd = pharmacyCategoriesArr.add;
          categoriesDataArrRemove = pharmacyCategoriesArr.remove;
        } else {
          categoriesDataArrAdd = pharmacyCategoriesArr.add.concat(libraryCategoriesArr.add);
          categoriesDataArrRemove = pharmacyCategoriesArr.remove.concat(
            libraryCategoriesArr.remove
          );
        }
        categoriesDataArr = {
          add: categoriesDataArrAdd,
          remove: categoriesDataArrRemove,
        };
      }
    }

    delete lastData.authorsData;
    delete lastData.translators;
    delete lastData.pharmacyCategories;
    delete lastData.libraryCategories;
    delete lastData.tagsData;
    delete lastData.years;
    delete lastData.type;
    delete lastData.genre;
    delete lastData.status;
    delete lastData.weight_meg;
    delete lastData.height_mes;
    delete lastData.categoriesData;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      id: values.id,
      imgFieldName: "image",
      file: typeof values.image === "object" ? values.image : null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "products",
      },
      related: [
        {
          table: "product_author",
          data: authorsDataArr,
          column: "author_id",
          origin_column: "product_id",
        },
        {
          table: "product_translator",
          data: translatorsArr,
          column: "author_id",
          origin_column: "product_id",
        },
        {
          table: "product_category",
          data: categoriesDataArr,
          column: "category_id",
          origin_column: "product_id",
        },
        {
          table: "product_tag",
          data: tagsDataArr,
          column: "tag_id",
          origin_column: "product_id",
        },
      ],
      getInfo: {
        url: `${baseUrl}&meta=*&limit=${limit}&page=${pageNum}&filter[parent_id][null]&filter[status][neq]=deleted&sort=-quantity,+arabic_title`,
        varName: "products",
      },
    };
    dispatch(addComplexDataAction(req));

    resetForm();
  };

  const getData = () => {
    // get data for selects in form
    dispatch(
      fetchDataAction({
        url: "items/author?fields=id,arabic_title&sort=arabic_title",
        varName: "authorsData",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/tags?fields=id,arabic_title&sort=arabic_title",
        varName: "tagsData",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/publisher?fields=id,arabic_title&sort=arabic_title",
        varName: "publishersData",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/brands?fields=id,english_title,arabic_title",
        varName: "brandsData",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/book_type?fields=id,english_title,arabic_title",
        varName: "bookTypeIdData",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/languages?fields=id,english_title,arabic_title",
        varName: "languagesData",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/currency?fields=id,title,arabic_title",
        varName: "currenciesData",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/categories?fields=id,english_title,arabic_title,parent_category.id,child_categories.id,child_categories.parent_category&sort=arabic_title",
        varName: "categoriesData",
      })
    );
    dispatch(
      fetchDataAction({
        url: "items/attribute_value?fields=id,english_title,attribute_id.id,attribute_id.english_title",
        varName: "attributeValueData",
      })
    );
  };

  const distributeCategories = () => {
    const libCategories = [];
    const phrmCategories = [];
    categoriesData.forEach((element) => {
      if (element.child_categories.length === 0) {
        if (element.parent_category && element.parent_category.id === 103) {
          libCategories.push(element);
        } else {
          phrmCategories.push(element);
        }
      }
    });
    setLibraryCategories(libCategories);
    setPharmacyCategories(phrmCategories);
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(reset(null));
    };
  }, []);

  useEffect(() => {
    if (
      authorsData !== undefined &&
      publishersData !== undefined &&
      brandsData !== undefined &&
      bookTypeIdData !== undefined &&
      languagesData !== undefined &&
      currenciesData !== undefined &&
      pharmacyCategories.length !== 0 &&
      tagsData !== undefined &&
      libraryCategories.length !== 0
    ) {
      orgData();
    }
  }, [
    tagsData,
    authorsData,
    publishersData,
    brandsData,
    bookTypeIdData,
    languagesData,
    currenciesData,
    libraryCategories,
    pharmacyCategories,
  ]);

  useEffect(() => {
    if (categoriesData && categoriesData.length > 0) {
      distributeCategories();
    }
  }, [categoriesData]);

  useEffect(() => {
    if (updateHandlerVal) {
      onUpdateHandler(updateHandlerVal.name, updateHandlerVal.value);
    }
  }, [updateHandlerVal]);

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      yupObj={{
        arabic_title: yup.string("Enter your Title").required("Title is required").nullable(),
        type: yup.mixed("Enter your type").required("Type is required").nullable(),
        status: yup.mixed("Enter your status").required("Status is required").nullable(),
        genre: yup.mixed("Enter your genre").required("Genre is required").nullable(),
      }}
      resetData={resetForm}
      name="products"
      data={data}
      initialValues={initialValues}
      setPayload={setPayload}
      setFieldSelectedValue={setFieldSelectedValue}
      onChange={() => {}}
    />
  );
};
PageForm.propTypes = {
  resetData: PropTypes.func.isRequired,
  updateHandlerVal: PropTypes.objectOf(PropTypes.any),
  pageNum: PropTypes.number.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

PageForm.defaultProps = {
  updateHandlerVal: null,
};

export default PageForm;
