import { Grid } from "@mui/material";
import Page from "components/UI/Page";
import JobTitles from "./SubContent/JobTitles";

const UserSettings = () => (
  <Page multi>
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <JobTitles />
      </Grid>
    </Grid>
  </Page>
);

export default UserSettings;
