import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataForm from "components/UI/form/DataForm";
import { fetchDataAction } from "store/features/coreSlice";
import PropTypes from "prop-types";

import dataVal from "../dataVal.json";

const FilterForm = ({ resetData, baseUrl, setUrl }) => {
  const dispatch = useDispatch();
  const { authorsData, publishersData, bookTypeIdData, limit } = useSelector((state) => state.core);

  const [data, setData] = useState([]);
  // const [initialValues, setInitialValues] = useState(dataVal.filterInitialValues);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "arabic_title",
        id: "arabic_title",
        label: "arabic_title",
        size: 4,
        title: "Arabic Title",
      },
      {
        type: "TextField",
        name: "english_title",
        id: "english_title",
        label: "english_title",
        size: 4,
        title: "English Title",
      },
      {
        type: "Select",
        optionTitle: "title",
        name: "status",
        id: "status",
        label: "status",
        data: dataVal.statuses,
        size: 4,
        title: "Product Status",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "book_type_id",
        id: "book_type_id",
        label: "book_type_id",
        data: bookTypeIdData,
        size: 4,
        title: "Book Type",
      },
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "publisher",
        id: "publisher",
        label: "publisher",
        data: publishersData,
        size: 4,
        title: "Publishers",
      },
      {
        type: "MSelect",
        optionTitle: "arabic_title",
        name: "authorsData",
        id: "authorsData",
        label: "authorsData",
        data: authorsData,
        size: 4,
        title: "Authors",
      },
    ]);
  };

  useEffect(() => {
    if (authorsData !== undefined && publishersData !== undefined && bookTypeIdData !== undefined) {
      orgData();
    }
  }, [authorsData, publishersData, bookTypeIdData]);

  const resetForm = () => {
    // setInitialValues(dataVal.filterInitialValues);
    // orgData();
    resetData();
  };

  const setPayload = (values) => {
    let str = `${baseUrl}`;
    const keys = Object.keys(values);
    keys.forEach((element) => {
      if (values[element] && values[element] !== []) {
        if (Array.isArray(values[element])) {
          if (element === "authorsData" && values.authorsData.length !== 0) {
            let val;
            values[element].forEach((element1) => {
              val = !val ? element1.id : `${val},${element1.id}`;
            });
            str += `&filter[${element}.author_id][in]=${val}`;
          }
        } else if (typeof values[element] === "object") {
          if (element === "status") str += `&filter[${element}]=${values[element].id}`;
          else str += `&filter[${element}.id]=${values[element].id}`;
        } else {
          str += `&filter[${element}][rlike]=%${values[element]}%`;
        }
      }
    });
    console.log(str);
    setUrl(str);
    dispatch(
      fetchDataAction({
        url: `${str}&meta=*&limit=${limit}&page=1`,
        varName: "products",
      })
    );
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <DataForm
      filter
      yupObj={{}}
      resetData={resetForm}
      name="productsFilter"
      data={data}
      initialValues={dataVal.filterInitialValues}
      setPayload={setPayload}
      setFieldSelectedValue={() => {}}
      onChange={() => {}}
    />
  );
};

FilterForm.propTypes = {
  resetData: PropTypes.func.isRequired,
  setUrl: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
};
export default FilterForm;
