import { useEffect, useState } from "react";
import * as yup from "yup";
import DataForm from "components/UI/form/DataForm";
import PropTypes from "prop-types";
import Api from "services/api";
import { Alert } from "@mui/material";
import dataVal from "../dataVal.json";

const PageForm = ({ order }) => {
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState(dataVal.initialValues);

  const orgData = () => {
    setData([
      {
        type: "TextField",
        name: "subject",
        id: "subject",
        label: "subject",
        size: 12,
        title: "subject",
      },
      {
        type: "TextArea",
        name: "notes",
        id: "notes",
        label: "notes",
        maxRows: 4,
        minRows: 4,
        size: 12,
        title: "message",
      },
      {
        type: "Editor",
        name: "enotes",
        id: "enotes",
        label: "enotes",
        maxRows: 4,
        minRows: 4,
        size: 12,
        title: "message",
      },
    ]);
  };

  const resetForm = () => {
    orgData();
  };

  const setPayload = async (values) => {
    if (order?.customer_id?.email) {
      if (order?.customer_id?.email === "all") {
        await Api.get(
          `custom/sendNotificationsAndEmail?body=${values.notes}&ebody=${values.enotes}&subject=${values.subject}&email=all`
        );
      } else {
        await Api.get(
          `custom/sendNotificationsAndEmail?userToken=${order.ownerpushtoken}&body=${values.notes}&ebody=${values.enotes}&subject=${values.subject}&email=${order.customer_id.email}&webpushnotification=${order.customer_id.web_push_notification_token}`
        );
      }
    } else {
      alert("where is user email");
    }
    setInitialValues(values);
    resetForm();
  };

  useEffect(() => {
    orgData();
  }, []);

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      {order?.customer_id?.email === "all" && (
        <Alert severity="warning">we will send this email for all customers</Alert>
      )}
      <DataForm
        yupObj={{
          subject: yup.string("required").required("required").nullable(),
          notes: yup.string("required").required("required").nullable(),
          enotes: yup.string("required").required("required").nullable(),
        }}
        resetData={resetForm}
        name="notifications"
        data={data}
        initialValues={initialValues}
        setPayload={setPayload}
        defaultInitialValues={dataVal.initialValues}
      />
    </>
  );
};
PageForm.propTypes = {
  order: PropTypes.objectOf(PropTypes.any),
};

PageForm.defaultProps = {
  order: null,
};
export default PageForm;
