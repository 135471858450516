import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import { Icon } from "@mui/material";
import Avatar from "components/UI/Table/avatar";
import MDSnackbar from "components/MDSnackbar";
import UpdateRecord from "helpers/compare";
import PagesCount from "helpers/pages-count";

import {
  fetchDataAction,
  deleteDataAction,
  reset,
  addComplexDataAction,
} from "../../../store/features/coreSlice";
import dataVal from "../dataVal.json";

const Banners = () => {
  const {
    bannersMain,
    bannersMainMeta,
    resStatus,
    limit,
    bannerscategories,
    bannerstag,
    bannersbrand,
    bannersbanners,
    bannersproducts,
    bannerscelebrities,
  } = useSelector((state) => state.core);

  const dispatch = useDispatch();

  const columns = [
    { Header: "Title", accessor: "titleAva", width: "45%", align: "center" },
    { Header: "Sequence", accessor: "sequence", width: "45%", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [initialValues, setInitialValues] = useState({
    id: null,
    title: null,
    category_id: null,
    tag_id: null,
    celebrity_id: null,
    brand_id: null,
    product_id: null,
    type: null,
    ar_image_id: null,
    en_image_id: null,
    ar_wsimage_id: null,
    en_wsimage_id: null,
    banner_id: null,
    sequence: 0,
  });
  const brandInitialValues = {
    id: null,
    title: null,
    category_id: null,
    tag_id: null,
    celebrity_id: null,
    brand_id: null,
    product_id: null,
    type: null,
    ar_image_id: null,
    en_image_id: null,
    ar_wsimage_id: null,
    en_wsimage_id: null,
    banner_id: null,
    sequence: 0,
  };
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      title: values.title,
      category_id: values.category_id,
      tag_id: values.tag_id,
      celebrity_id: values.celebrity_id,
      brand_id: values.brand_id,
      product_id: values.product_id,
      type: dataVal.types.filter((type) => type.value === values.type)[0],
      ar_image_id: values.ar_image_id?.data?.full_url,
      en_image_id: values.en_image_id?.data?.full_url,
      ar_wsimage_id: values.ar_wsimage_id?.data?.full_url,
      en_wsimage_id: values.en_wsimage_id?.data?.full_url,
      banner_id: values.banner_id,
      sequence: values.sequence,
    });
    setOpen(true);
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const getData = () => {
    let payload = {
      url: `items/banners_images?fields=status,id,title,type,banner_id.id,category_id.id,category_id.english_title,banner_id.title,product_id.id,product_id.english_title,celebrity_id.id,celebrity_id.first_name,brand_id.id,brand_id.english_title,ar_image_id.data.full_url,en_image_id.data.full_url,ar_wsimage_id.data.full_url,en_wsimage_id.data.full_url,sequence&meta=*&limit=${limit}&page=${pageNum}`,
      varName: "bannersMain",
    };
    dispatch(fetchDataAction(payload));

    payload = {
      url: "items/categories?fields=id,english_title,arabic_title",
      varName: "bannerscategories",
    };
    dispatch(fetchDataAction(payload));

    payload = {
      url: `items/tags?fields=id,english_title,arabic_title`,
      varName: "bannerstag",
    };
    dispatch(fetchDataAction(payload));
    payload = {
      url: `items/brands?fields=id,english_title,arabic_title`,
      varName: "bannersbrand",
    };
    dispatch(fetchDataAction(payload));
    payload = {
      url: `items/banners?fields=id,title,category_id.english_title,position,celebrity_id`,
      varName: "bannersbanners",
    };
    dispatch(fetchDataAction(payload));
    payload = {
      url: `items/products?fields=id,english_title,arabic_title&filter[parent_id][null]&limit=-1`,
      varName: "bannersproducts",
    };
    dispatch(fetchDataAction(payload));

    payload = {
      url: `users?fields=id,first_name,last_name,sequence,avatar.data.full_url,email&filter[role]=3`,
      varName: "bannerscelebrities",
    };
    dispatch(fetchDataAction(payload));
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(reset(null));
    };
  }, []);

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      dispatch(
        fetchDataAction({
          url: `items/banners_images?fields=status,id,title,type,banner_id.id,category_id.id,category_id.english_title,banner_id.title,product_id.id,product_id.english_title,celebrity_id.id,celebrity_id.first_name,brand_id.id,brand_id.english_title,ar_image_id.data.full_url,en_image_id.data.full_url,ar_wsimage_id.data.full_url,en_wsimage_id.data.full_url,sequence&meta=*&limit=${limit}&page=${num}`,
          varName: "bannersMain",
        })
      );
    }
  };

  const changeStatus = (status, category) => {
    const req = {
      id: category.id,
      origin: {
        data: { status },
        table: "banners_images",
      },
      getInfo: {
        url: `items/banners_images?fields=status,id,title,type,banner_id.id,category_id.id,category_id.english_title,banner_id.title,product_id.id,product_id.english_title,celebrity_id.id,celebrity_id.first_name,brand_id.id,brand_id.english_title,ar_image_id.data.full_url,en_image_id.data.full_url,ar_wsimage_id.data.full_url,en_wsimage_id.data.full_url,sequence&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "bannersMain",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  useEffect(() => {
    if (bannersMain) {
      let item = {};
      const Arr = [];
      const d = [];
      for (let i = 0; i < PagesCount(+bannersMainMeta.filter_count, limit); i += 1) {
        d.push(i);
      }
      setPageOption(d);
      let keys;
      if (bannersMain && bannersMain.length !== 0) {
        bannersMain.forEach((banner) => {
          item = {};
          keys = Object.keys(banner);
          item.titleAva = (
            <Avatar
              dir="ltr"
              image={banner.ar_image_id?.data?.full_url}
              email={banner.banner_id.title}
              name={banner.title}
            />
          );

          keys.forEach((element) => {
            item[element] = banner[element];
          });
          item.action = (
            <>
              <MDTypography
                style={{ padding: 20, color: "#3b94ee" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onUpdateHandler("bannersMain", banner)}
              >
                <Icon fontSize="small">edit</Icon>
              </MDTypography>
              <MDTypography
                style={{ padding: 20, color: "#e9423f" }}
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => onDeleteHandler("bannersMain", banner)}
              >
                <Icon fontSize="small">delete</Icon>
              </MDTypography>
              {banner.status === "draft" ? (
                <MDTypography
                  className="button-group"
                  style={{ padding: 20, color: "#e9423f" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => changeStatus("published", banner)}
                >
                  <Icon fontSize="small">public_off</Icon>
                </MDTypography>
              ) : (
                <MDTypography
                  style={{ padding: 20, color: "green" }}
                  component="a"
                  href="#"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => changeStatus("draft", banner)}
                >
                  <Icon fontSize="small">public</Icon>
                </MDTypography>
              )}
            </>
          );
          Arr.push(item);
        });
        setNewArr(Arr);
      }
    }
  }, [bannersMain]);

  const data = [
    {
      type: "TextField",
      name: "title",
      id: "title",
      label: "title",
      size: 5,
      title: "Title",
    },
    {
      type: "TextField",
      name: "sequence",
      id: "sequence",
      label: "sequence",
      size: 2,
      title: "Sequence",
    },
    {
      type: "Select",
      optionTitle: "english_title",
      name: "category_id",
      id: "category_id",
      label: "category_id",
      data: bannerscategories,
      size: 4,
      title: "category",
    },
    {
      type: "Select",
      optionTitle: "english_title",
      name: "tag_id",
      id: "tag_id",
      label: "tag_id",
      data: bannerstag,
      size: 4,
      title: "tags",
    },
    {
      type: "Select",
      optionTitle: "english_title",
      name: "brand_id",
      id: "brand_id",
      label: "brand_id",
      data: bannersbrand,
      size: 4,
      title: "brands",
    },
    {
      type: "Select",
      optionTitle: "title",
      name: "banner_id",
      id: "banner_id",
      label: "banner_id",
      data: bannersbanners,
      size: 4,
      title: "banner",
    },
    {
      type: "Select",
      optionTitle: "english_title",
      name: "product_id",
      id: "product_id",
      label: "product_id",
      data: bannersproducts,
      size: 4,
      title: "products",
    },
    {
      type: "Select",
      optionTitle: "first_name",
      name: "celebrity_id",
      id: "celebrity_id",
      label: "celebrity_id",
      data: bannerscelebrities,
      size: 3,
      title: "celebrity",
    },
    {
      type: "Select",
      optionTitle: "title",
      name: "type",
      id: "type",
      label: "type",
      data: dataVal.types,
      size: 4,
      title: "type",
    },
    {
      type: "Img",
      name: "ar_image_id",
      id: "ar_image_id",
      label: "ar_image_id",
      size: 6,
      title: "ar_image_id",
    },
    {
      type: "Img",
      name: "en_image_id",
      id: "en_image_id",
      label: "en_image_id",
      size: 6,
      title: "en_image_id",
    },
    {
      type: "Img",
      name: "ar_wsimage_id",
      id: "ar_wsimage_id",
      label: "ar_wsimage_id",
      size: 6,
      title: "ar_website_image_id",
    },
    {
      type: "Img",
      name: "en_wsimage_id",
      id: "en_wsimage_id",
      label: "en_wsimage_id",
      size: 6,
      title: "en_website_image_id",
    },
  ];

  const resetForm = () => {
    setInitialValues({
      id: null,
      title: null,
      category_id: null,
      tag_id: null,
      celebrity_id: null,
      brand_id: null,
      product_id: null,
      type: null,
      ar_image_id: null,
      en_image_id: null,
      ar_wsimage_id: null,
      en_wsimage_id: null,
      banner_id: null,
      sequence: 0,
    });
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      setSuccessSB(true);
      resetForm();
    }
  }, [resStatus]);

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    if (initialValues.type?.value !== values.type?.value) payloadData.type = values.type.value;
    delete lastData.type;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      id: values.id,
      haveMulti: true,
      file: [
        {
          file: typeof values.ar_image_id === "object" ? values.ar_image_id : null,
          imgFieldName: "ar_image_id",
        },
        {
          file: typeof values.en_image_id === "object" ? values.en_image_id : null,
          imgFieldName: "en_image_id",
        },
        {
          file: typeof values.ar_wsimage_id === "object" ? values.ar_wsimage_id : null,
          imgFieldName: "ar_wsimage_id",
        },
        {
          file: typeof values.en_wsimage_id === "object" ? values.en_wsimage_id : null,
          imgFieldName: "en_wsimage_id",
        },
      ],
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "banners_images",
      },
      getInfo: {
        url: `items/banners_images?fields=status,fields=id,title,type,banner_id.id,category_id.id,category_id.english_title,banner_id.title,product_id.id,product_id.english_title,celebrity_id.id,celebrity_id.first_name,brand_id.id,brand_id.english_title,ar_image_id.data.full_url,en_image_id.data.full_url,ar_wsimage_id.data.full_url,en_wsimage_id.data.full_url,sequence&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "bannersMain",
      },
    };
    console.log(req);
    dispatch(addComplexDataAction(req));
  };

  const setOpenModal = () => {
    if (open) resetForm();
    setOpen(!open);
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: `items/banners_images`,
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      varName: "bannersMain",
      getInfo: {
        url: `items/banners_images?fields=status,id,title,type,banner_id.id,category_id.id,category_id.english_title,banner_id.title,product_id.id,product_id.english_title,celebrity_id.id,celebrity_id.first_name,brand_id.id,brand_id.english_title,ar_image_id.data.full_url,en_image_id.data.full_url,ar_wsimage_id.data.full_url,en_wsimage_id.data.full_url,sequence&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "bannersMain",
      },
    };

    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };
  if (data.length === 0 || newArr === [] || !bannersMainMeta) {
    return null;
  }
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Diwan Dashboard"
      content="successful process"
      dateTime="recently"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <>
      <Main
        layout="simple"
        allRowsCount={+bannersMainMeta.filter_count}
        pageIndex={pageNum - 1}
        count={pageOptions}
        setPageNum={setPageNumber}
        report={false}
        openDeleteModal={openDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
        setOpen={setOpenModal}
        setOpenDeleteModal={setOpenDeleteModal}
        open={open}
        name="bannersMain"
        setPayload={setPayload}
        data={data}
        initialValues={initialValues}
        title="Add Brand"
        columns={columns}
        rows={newArr}
        onUpdateHandler={onUpdateHandler}
        onDeleteHandler={onDeleteHandler}
        defIniVal={brandInitialValues}
        yupObj={{
          title: yup.string("Enter your Title").required("Title is required"),
        }}
      />
      {renderSuccessSB}
    </>
  );
};

export default Banners;
