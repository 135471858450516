import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "components/UI/Main";
import * as yup from "yup";
import MDTypography from "components/MDTypography";
import UpdateRecord from "helpers/compare";
import { Icon } from "@mui/material";
import PropTypes from "prop-types";
import PagesCount from "helpers/pages-count";

import {
  addComplexDataAction,
  fetchDataAction,
  deleteDataAction,
  reset,
} from "../../store/features/coreSlice";
import dataVal from "./dataVal.json";

const CelebrityFavourities = ({ celebrityId }) => {
  const dispatch = useDispatch();

  const { celebrityFavouritiesMeta, celebrityFavourities, resStatus, celeFavorAllProducts, limit } =
    useSelector((state) => state.core);

  const [newArr, setNewArr] = useState([]);
  const [data, setData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [pageOptions, setPageOption] = useState([]);

  const [initialValues, setInitialValues] = useState({
    product_id: null,
    sequence: 0,
    celebrity_id: celebrityId,
  });

  const orgData = () => {
    setData([
      {
        type: "Select",
        optionTitle: "arabic_title",
        name: "product_id",
        id: "product_id",
        label: "product_id",
        data: celeFavorAllProducts,
        size: 6,
        title: "Product",
      },
      {
        type: "TextField",
        name: "sequence",
        id: "sequence",
        label: "sequence",
        size: 6,
        title: "Sequence",
      },
    ]);
  };

  const onUpdateHandler = (name, values) => {
    setInitialValues({
      id: values.id,
      product_id: values.product_id,
      sequence: values.sequence,
    });
  };

  const resetForm = () => {
    setInitialValues({
      product_id: null,
      sequence: 0,
      celebrity_id: celebrityId,
    });
    orgData();
  };

  const onDeleteHandler = (name, values) => {
    setOpenDeleteModal(true);
    setDeleteItemId(values.id);
  };

  const prepareTableRows = () => {
    let item = {};
    const Arr = [];
    let keys = [];
    const d = [];
    for (let i = 0; i < PagesCount(+celebrityFavouritiesMeta.filter_count, limit); i += 1) {
      d.push(i);
    }
    setPageOption(d);
    if (celebrityFavourities && celebrityFavourities.length !== 0) {
      celebrityFavourities.forEach((product) => {
        item = {};
        keys = Object.keys(product);
        keys.forEach((element) => {
          item[element] = product[element];
        });

        item.title = product.product_id.arabic_title;
        item.action = (
          <>
            <MDTypography
              style={{ padding: 20, color: "#3b94ee" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onUpdateHandler("celebrityFavourities", product)}
            >
              <Icon fontSize="small">edit</Icon>
            </MDTypography>
            <MDTypography
              style={{ padding: 20, color: "#e9423f" }}
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => onDeleteHandler("celebrityFavourities", product)}
            >
              <Icon fontSize="small">delete</Icon>
            </MDTypography>
          </>
        );
        Arr.push(item);
      });
      setNewArr(Arr);
      console.log(Arr);
    } else {
      setNewArr([]);
    }
  };

  const setPageNumber = (num) => {
    if (num) {
      setPageNum(num);

      const url = `items/celebrity_favourities?fields=id,sequence,product_id.arabic_title,product_id.id,celebrity_id.first_name,celebrity_id.id&filter[celebrity_id]=${celebrityId}&meta=*&limit=${limit}&page=${num}`;
      dispatch(
        fetchDataAction({
          url,
          varName: "celebrityFavourities",
        })
      );
    }
  };

  const setPayload = (values) => {
    const payloadData = {};
    const lastData = values.id ? UpdateRecord(values, initialValues) : values;

    Object.keys(lastData).forEach((key) => {
      if (lastData[key] && typeof lastData[key] === "object") payloadData[key] = lastData[key].id;
      else if (values.id || lastData[key]) payloadData[key] = lastData[key];
    });

    const req = {
      id: values.id,
      file: null,
      origin: {
        data: Object.keys(payloadData).length === 0 ? null : payloadData,
        table: "celebrity_favourities",
      },
      getInfo: {
        url: `items/celebrity_favourities?fields=id,sequence,product_id.arabic_title,product_id.id,celebrity_id.first_name,celebrity_id.id&filter[celebrity_id]=${celebrityId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "celebrityFavourities",
      },
    };
    dispatch(addComplexDataAction(req));
  };

  const handleConfirmDelete = async () => {
    const payload = {
      url: "items/celebrity_favourities",
      data: {
        id: deleteItemId,
        status: "deleted",
      },
      getInfo: {
        url: `items/celebrity_favourities?fields=id,sequence,product_id.arabic_title,product_id.id,celebrity_id.first_name,celebrity_id.id&filter[celebrity_id]=${celebrityId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "celebrityFavourities",
      },
    };
    dispatch(deleteDataAction(payload));
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (resStatus && resStatus === "success") {
      resetForm();
    }
  }, [resStatus]);

  useEffect(() => {
    // get product data
    dispatch(
      fetchDataAction({
        url: `items/celebrity_favourities?fields=id,sequence,product_id.arabic_title,product_id.id,celebrity_id.first_name,celebrity_id.id&filter[celebrity_id]=${celebrityId}&meta=*&limit=${limit}&page=${pageNum}`,
        varName: "celebrityFavourities",
      })
    );

    dispatch(
      fetchDataAction({
        url: "items/products?fields=id,arabic_title&filter[parent_id][null]",
        varName: "celeFavorAllProducts",
      })
    );
  }, [limit]);

  useEffect(
    () => () => {
      setNewArr([]);
      dispatch(reset(null));
    },
    []
  );

  useEffect(() => {
    if (celebrityFavourities && celebrityFavourities.length !== 0) {
      prepareTableRows();
    }
  }, [celebrityFavourities]);

  useEffect(() => {
    if (celeFavorAllProducts) orgData();
  }, [celeFavorAllProducts]);

  if (data.length === 0 || newArr === [] || !celebrityFavouritiesMeta) {
    return null;
  }

  return (
    <Main
      allRowsCount={+celebrityFavouritiesMeta.filter_count}
      pageIndex={pageNum - 1}
      count={pageOptions}
      setPageNum={setPageNumber}
      setOpenDeleteModal={setOpenDeleteModal}
      openDeleteModal={openDeleteModal}
      handleConfirmDelete={handleConfirmDelete}
      name="celebrityFavourities"
      setPayload={setPayload}
      data={data}
      initialValues={initialValues}
      title="Add Library Categories"
      columns={dataVal.columns}
      rows={newArr}
      entriesPerPage={false}
      onUpdateHandler={onUpdateHandler}
      onDeleteHandler={onDeleteHandler}
      yupObj={{
        product_id: yup.mixed("Enter your product").required("product is required").nullable(),
      }}
    />
  );
};
CelebrityFavourities.propTypes = {
  celebrityId: PropTypes.number.isRequired,
};
export default CelebrityFavourities;
